<script lang="ts" setup>
import { computed, nextTick, onBeforeMount, onMounted, ref, watch, withDefaults } from 'vue';
import TemplateService from '@/core/conversations/template/template.service';
import MessageSendIcon from '@/assets/svg/conversations/MessageSendIcon.svg?component';
import type { MessageTemplate } from '@factoryfixinc/ats-interfaces';
import TrackingService from '@/core/shared/tracking/tracking.service';
import PlusCircleSvg from '@/assets/svg/plus-circle-20.svg?component';
import TemplateModal from '@/components/Conversations/Templates/TemplateModal.vue';
import ConversationMessageService from '@/core/conversations/message/conversation-message.service';

const props = withDefaults(
  defineProps<{ proName?: string; disabled?: boolean; applicantId?: number }>(),
  {
    proName: '',
    applicantId: 0,
    disabled: false,
  },
);

const emit = defineEmits(['sendMessage']);
const templateService = new TemplateService();
const conversationMessageService = new ConversationMessageService();

const message = ref();
const textareaRef = ref<HTMLTextAreaElement | null>(null);
const rows = ref(1);
const maxRows = 8;

const messagePlaceholder = computed(() => `Message ${props.proName}`);
const selectedTemplate = computed(() => templateService.selectedTemplate);

const openTemplatesModal = () => {
  templateService.showTemplateModal = true;
};

function sendMessage() {
  if (message.value) {
    emit('sendMessage', { messageContent: message.value });
    message.value = '';
  }
}

function sendTemplateMessage(templateMessage: string) {
  if (templateMessage) {
    emit('sendMessage', { messageContent: templateMessage });
    message.value = '';
  }
}

function applyTemplate(template: MessageTemplate) {
  if (template) {
    message.value = templateService.mapTemplatePlaceholders(template.message);
    templateService.selectedTemplate = null;
    TrackingService.trackAction('Template Inserted', {
      template_id: template?.id,
      type: template?.type,
      content: template?.message,
    });
  }
}

async function adjustTextareaRows() {
  rows.value = 1;
  await nextTick();

  if (textareaRef.value) {
    const lineHeight = parseInt(getComputedStyle(textareaRef.value).lineHeight);
    const paddingTop = parseInt(getComputedStyle(textareaRef.value).paddingTop);
    const paddingBottom = parseInt(getComputedStyle(textareaRef.value).paddingBottom);
    const contentHeight = textareaRef.value.scrollHeight - paddingTop - paddingBottom;
    const rowCount = Math.min(Math.floor(contentHeight / lineHeight), maxRows);
    rows.value = rowCount;
  }
}

function setMessageContent(text: string) {
  message.value = text;
}

function getSessionUnsentMessage() {
  const unsentMessage = conversationMessageService.getSessionUnsentMessageByApplicantId(
    props.applicantId,
  );

  return unsentMessage ?? '';
}

function storeSessionUnsentMessage(message: string) {
  if (props.applicantId) {
    conversationMessageService.setSessionUnsentMessageForApplicantId(props.applicantId, message);
  }
}

function handleApplicantUnsentMessageStorage(message: string) {
  if (props.applicantId) {
    if (message) {
      storeSessionUnsentMessage(message);
    } else {
      conversationMessageService.clearSessionUnsentMessageForApplicantId(props.applicantId);
    }
  }
}

watch(selectedTemplate, (template) => {
  if (template) {
    applyTemplate(template);
  }
});

watch(message, async () => {
  await adjustTextareaRows();
  // Clears any session unsent message for the applicant or stores the new message value
  handleApplicantUnsentMessageStorage(message.value);
});

onBeforeMount(async () => {
  templateService.showTemplateModal = false;
});

onMounted(() => {
  if (templateService.selectedTemplate) {
    applyTemplate(templateService.selectedTemplate);
  } else {
    const unsentMessage = getSessionUnsentMessage();
    if (unsentMessage) {
      setMessageContent(unsentMessage);
    }
  }
});

defineExpose({ setMessageContent });
</script>

<template>
  <div class="flex h-full w-full flex-row items-end gap-2">
    <TemplateModal @send-template-message="sendTemplateMessage" />
    <div
      :class="{
        'py-4': true,
        'cursor-not-allowed text-tint-400': disabled,
        'cursor-pointer text-shade-900': !disabled,
      }"
      title="Choose a template"
      @click="openTemplatesModal"
    >
      <v-tooltip location="top" content-class="project-card-tooltip tooltip-bottom-arrow">
        <template #activator="{ props }">
          <PlusCircleSvg v-bind="props" />
        </template>
        <span class="text-xs text-white">Insert template</span>
      </v-tooltip>
    </div>

    <form @submit.prevent="sendMessage" class="flex-1">
      <div class="relative flex w-full items-end">
        <textarea
          ref="textareaRef"
          name="message-compose"
          class="peer min-h-[52px] w-full resize-none rounded-md border border-tint-100 py-3 pl-4 pr-12 text-shade-900 outline-none transition-colors placeholder:text-shade-800 disabled:bg-tint-80"
          :placeholder="messagePlaceholder"
          :disabled="disabled"
          :rows="rows"
          v-model="message"
          @keydown.enter.exact.prevent="sendMessage"
        />

        <button
          type="submit"
          class="absolute bottom-4 right-0 mr-4 text-shade-600 transition-colors peer-focus:!text-shade-900"
          :class="[disabled ? 'cursor-not-allowed' : 'cursor-pointer']"
        >
          <MessageSendIcon class="text-current h-5 w-5" title="Send message" />
        </button>
      </div>
    </form>
  </div>
</template>

<style>
/*
 * Must not be scoped since the component is moved to the beginning of the v-app
 * https://vuetifyjs.com/en/api/v-tooltip/#props-content-class
 */
.project-card-tooltip {
  border-radius: 6px !important;
  padding: 3px 8px !important;
  background-color: #0f172a !important;
}
</style>
