<script setup lang="ts">
import FilterChip from '@/components/Sourcing/Filters/FilterChip/FilterChip.vue';
import Checkbox from '../Checkbox/Checkbox.vue';
import { onMounted, ref } from 'vue';
import { arrayToRecord } from '@/utils/objects/array-to-record.util';

const props = defineProps<{
  label: string;
  items: { id: number; value: string }[];
  modelValue?: number[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value?: number[]): void;
  (e: 'click:delete'): void;
}>();

const isMenuVisible = ref(false);
const itemsRecord = arrayToRecord(props.items, 'id');

function handleSelectedValue(value: number) {
  isMenuVisible.value = false;
  const currentValues = props.modelValue ?? [];

  if (currentValues.includes(value)) {
    const newValues = currentValues.filter((v) => v !== value);
    emit('update:modelValue', newValues);
    return;
  }

  emit('update:modelValue', [...currentValues, value]);
}

function handleMenuStateChange(value: boolean) {
  isMenuVisible.value = value;

  if (value === false) {
    emit('update:modelValue', props.modelValue);
  }
}

function handleChipRemoval() {
  isMenuVisible.value = false;
  emit('update:modelValue', undefined);
}

function getValueDisplayText(id?: number[]): string {
  if (!id?.length) {
    return '';
  }

  return id.reduce((acc, curr) => {
    const displayText = itemsRecord[curr]?.value ?? '';

    return acc ? `${acc}, ${displayText}` : `${displayText}`;
  }, '');
}

function isItemSelected(id: number): boolean {
  return props.modelValue?.includes(id) ?? false;
}

onMounted(() => {
  // Open the menu on mount if the value is empty
  if (!props.modelValue?.length) {
    isMenuVisible.value = true;
  }
});
</script>

<template>
  <div>
    <v-menu
      :model-value="isMenuVisible"
      :close-on-content-click="false"
      transition="slide-y-transition"
      @update:model-value="handleMenuStateChange"
    >
      <template #activator="{ props }">
        <FilterChip
          v-bind="props"
          :label="label"
          deletable
          :value="getValueDisplayText(modelValue)"
          @click:delete="handleChipRemoval"
        />
      </template>

      <div
        :class="[
          'border-1',
          'flex',
          'flex-col',
          'rounded-md',
          'bg-white',
          'w-[260px]',
          'max-h-[300px]',
          'overflow-y-auto',
          'shadow-menu',
        ]"
      >
        <div class="flex flex-col">
          <div
            v-for="item in items"
            :key="item.id"
            :class="{
              'flex min-h-[32px] cursor-pointer items-center px-3 py-1 hover:bg-highlight-50': true,
              'bg-highlight-50': isItemSelected(item.id),
            }"
            @click="handleSelectedValue(item.id)"
          >
            <Checkbox :model-value="isItemSelected(item.id)" class="mr-2" />
            <span class="text-xs">{{ item.value }}</span>
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>
