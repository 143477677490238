<template>
  <ExpansionPanel
    header-title="Education"
    :header-items-count="education?.length"
    :expanded="expanded"
    @header:click="expanded = !expanded"
  >
    <template #content>
      <div v-if="!education?.length" class="text-shade-600" data-test-id="no-education">
        No Education Added
      </div>
      <template v-else>
        <div
          v-for="({ id, certificateIssuer, certificateTitle, dateIssued }, i) in shownEducationList"
          :key="id"
          class="leading-[18px]"
          :class="{ 'mb-2.5': i !== education.length - 1 }"
          data-test-id="certificate"
        >
          <div class="certificate-title" data-test-id="certificate-title">
            {{ certificateTitle }}
          </div>

          <div class="text-shade-600" data-test-id="certificate-issuer">
            {{ certificateIssuer }}
          </div>

          <div class="text-shade-600" data-test-id="certificate-date-issuer">
            {{ formatUTCdate(dateIssued) }}
          </div>
        </div>

        <div
          v-if="isShowMoreButtonVisible"
          class="mt-2.5 cursor-pointer underline"
          data-test-id="show-more-button"
          @click="toggleListSize"
          @keypress="toggleListSize"
        >
          {{ showMoreButtonText }}
        </div>
        <div v-if="!education?.length" class="text-shade-600" data-test-id="no-certificate">
          No Education Added
        </div>
      </template>
    </template>
  </ExpansionPanel>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import ExpansionPanel from '@/components/Shared/ExpansionPanel/ExpansionPanel.vue';
import type { ProEducationCertification } from '@factoryfixinc/ats-interfaces';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { computed } from 'vue';
dayjs.extend(utc);
const expanded = ref(true);
const props = defineProps<{
  education?: ProEducationCertification[];
}>();
const DEFAULT_LIST_SIZE = 2;
const isListExpanded = ref(false);
const shownEducationList = computed(() => {
  return isListExpanded.value ? props.education : props.education?.slice(0, DEFAULT_LIST_SIZE);
});
const isShowMoreButtonVisible = computed(() => {
  return props.education?.length && props.education?.length > DEFAULT_LIST_SIZE;
});
const showMoreButtonText = computed(() => {
  return isListExpanded.value ? 'Show Less' : 'Show More';
});
function toggleListSize() {
  isListExpanded.value = !isListExpanded.value;
  // if (showMoreButtonText.value === 'Show Less') {
  //   this.$mixpanelAnalytics.pppSeeMoreClicked({
  //     source: 'Education'
  //   })
  // }
}
function formatUTCdate(date: string | undefined, format = 'MMMM YYYY') {
  return date ? dayjs.utc(date).format(format) : '';
}
</script>

<style scoped></style>
