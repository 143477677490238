import { useAtsCandidateDiscoveryApi } from '@/composables/useApi';
import type { Employer, UserProfile } from '@factoryfixinc/ats-interfaces';
import type { GatedUserProfile } from './types/gated-user-profile.type';
import { InternalError } from '@/core/shared/errors/internal.error';

export default class SelectedCandidatePersistence {
  async fetchCandidate(
    employerId: Employer['id'],
    proUserProfileId: UserProfile['id'],
  ): Promise<GatedUserProfile> {
    const url = `search/employer/${employerId}/candidates/${proUserProfileId}`;
    const { data, error, statusCode } = await useAtsCandidateDiscoveryApi(url)
      .get()
      .json<GatedUserProfile>();

    if (error.value) {
      throw new InternalError('Failed to fetch candidate', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, proUserProfileId },
      });
    }

    if (!data.value) {
      throw new InternalError('Empty response received from fetchCandidate', {
        status: statusCode.value,
        data: { employerId, proUserProfileId },
      });
    }

    return data.value;
  }
}
