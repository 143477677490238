<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

import ConversationIndexService from '@/core/conversations/conversation-index/conversation-index.service';

import ConversationIndexStatusFilterArrow from './ConversationIndexStatusFilterArrow.vue';
import ConversationIndexDispositionChip from '@/components/Conversations/ConversationIndex/Filters/ConversationIndexDispositionChip.vue';

import {
  JobApplicantStatus,
  type JobApplicantStatusCount,
  type ProjectWithRelations,
} from '@factoryfixinc/ats-interfaces';
import { useRoute } from 'vue-router';
import ProjectService from '@/core/shared/project/project.service';
import CopilotBubbleSvg from '@/assets/svg/copilot-bubble.svg';
import type { SearchConversationIndex } from '@/core/conversations/conversation-index/conversation-index.type';
import { ErrorService } from '@/core/shared/errors/error.service';
import MeService from '@/core/shared/me/me.service';

// Scroll distance between status filter options
const SCROLL_JUMP = 180;

const props = defineProps<{
  project?: ProjectWithRelations<'candidates'>;
}>();

const emit = defineEmits<{
  (e: 'update:status', payload: string): void;
}>();

const conversationIndexService = new ConversationIndexService();
const projectService = new ProjectService();
const meService = new MeService();

const route = useRoute();

const filterContentWrapper = ref<HTMLElement | null>(null);
const showPreviousButton = ref(false);
const showNextButton = ref(true);
const statusFilterBaseOptions = [
  { text: 'Outreach', value: JobApplicantStatus.CONTACT, icon: CopilotBubbleSvg },
  { text: 'New', value: JobApplicantStatus.NEW },
  { text: 'Engaged', value: JobApplicantStatus.CLIENT },
  { text: 'Review', value: JobApplicantStatus.REVIEW },
  { text: 'Interview', value: JobApplicantStatus.INTERVIEW },
  { text: 'Offer', value: JobApplicantStatus.OFFER },
  { text: 'Hired', value: JobApplicantStatus.HIRED },
  { text: 'Rejected', value: JobApplicantStatus.REJECTED },
];

const currentProjectJobId = computed(() => projectService.currentProject?.jobId);
const statusFilterOptions = computed(() => statusFilterBaseOptions);
const currentProject = computed(() => {
  return props.project;
});

const selectedStatusList = computed({
  get: () => conversationIndexService.selectedStatusList,
  set: (statusList: string[]) => {
    conversationIndexService.selectedStatusList = statusList;
  },
});

const currentUserAndEmployerId = computed(
  () => `${meService.userProfile?.id}-${meService.employer?.id}`,
);

function setConversationIndexStatusSearch(statusList: string[]) {
  conversationIndexService.resetConversationIndexes();
  conversationIndexService.selectedStatusFilters = statusList;
  conversationIndexService.updateConversationIndexSearch(
    {
      applicationStatus: statusList,
      jobIds: currentProjectJobId.value ? [currentProjectJobId.value] : [],
    },
    { resetPagination: true },
  );
}

function isSelected(statusFilterOption: string) {
  return selectedStatusList.value.includes(statusFilterOption);
}

function getStatusCandidatesCount(statusFilterOption: keyof JobApplicantStatusCount) {
  if (projectService.isProjectFromDeepLinkRemoved) {
    return 0;
  }

  if (projectService.areNoProjectsAvailable) {
    // When there are no projects available, the status filter should not be shown
    return 0;
  }

  const candidateCounts = currentProject.value?.candidates;
  let count = 0;

  if (candidateCounts) {
    count = candidateCounts[statusFilterOption] ?? 0;
  }

  return count;
}

/**
 * We remove multi-select functionality when we select a Status Filter but we
 * leave the rest of the code without any changes in case we need to get back
 * multi-select again.
 */
function selectStatus(statusFilterOptionSelected: string) {
  emit('update:status', statusFilterOptionSelected);

  if (isSelected(statusFilterOptionSelected)) {
    selectedStatusList.value = selectedStatusList.value.filter(
      (selectedStatusFilterOption) => selectedStatusFilterOption !== statusFilterOptionSelected,
    );
  } else {
    selectedStatusList.value = [statusFilterOptionSelected];
  }
  setConversationIndexStatusSearch(selectedStatusList.value);
  setLocalStorageFilter();
}

function setSelectedStatusToDefaultIfEmpty() {
  if (selectedStatusList.value.length === 0) {
    selectedStatusList.value = [JobApplicantStatus.CLIENT];
    setConversationIndexStatusSearch(selectedStatusList.value);
  }
}

function setLocalStorageFilter() {
  const conversationIndexSearch: Partial<SearchConversationIndex> = {
    applicationStatus: conversationIndexService.conversationIndexSearch.applicationStatus,
    onlyCurrentUser: conversationIndexService.conversationIndexSearch.onlyCurrentUser,
    onlyUnread: conversationIndexService.conversationIndexSearch.onlyUnread,
    orderBy: conversationIndexService.conversationIndexSearch.orderBy,
  };

  localStorage.setItem(
    `filter-query-${currentUserAndEmployerId.value}`,
    JSON.stringify(conversationIndexSearch),
  );
}

function getLocalStorageFilter(): Partial<SearchConversationIndex> | undefined {
  try {
    const queryLocalStorage = localStorage.getItem(
      `filter-query-${currentUserAndEmployerId.value}`,
    );
    const currentJobIds = currentProjectJobId.value ? [currentProjectJobId.value] : [];
    return queryLocalStorage ? { ...JSON.parse(queryLocalStorage), jobIds: currentJobIds } : null;
  } catch (error) {
    ErrorService.captureException(error);
    return undefined;
  }
}

function onFilterContentScroll() {
  updateArrowButtons(filterContentWrapper.value?.scrollLeft ?? 0);
}

function updateArrowButtons(newScrollLeft: number) {
  const arrowButtonToleranceToHide = 20;

  const filterContentWrapperElement = filterContentWrapper.value as HTMLElement;
  showPreviousButton.value = newScrollLeft > arrowButtonToleranceToHide;

  showNextButton.value =
    newScrollLeft + filterContentWrapperElement.clientWidth + arrowButtonToleranceToHide <
    filterContentWrapperElement.scrollWidth;
}

function navigationFilterHandler(leftScrollBy: number) {
  filterContentWrapper.value?.scrollBy({
    left: leftScrollBy,
  });
}

onMounted(() => {
  const isDeepLink = route.query?.isDeepLink;

  const localStorageFilter = getLocalStorageFilter();

  // HACK ALERT: sometimes this component is mounted before the parent and applicationStatus is not set
  if (!isDeepLink) {
    if (!localStorageFilter?.applicationStatus) {
      setSelectedStatusToDefaultIfEmpty();
    } else {
      selectedStatusList.value = localStorageFilter.applicationStatus;
      setConversationIndexStatusSearch(selectedStatusList.value);
    }
  }

  const filterContentWrapperElement = filterContentWrapper.value as HTMLElement;
  filterContentWrapperElement.addEventListener('wheel', (e) => {
    e.preventDefault();
    const newScrollLeft = filterContentWrapperElement.scrollLeft + e.deltaX;
    filterContentWrapperElement.style.scrollBehavior = 'auto';
    filterContentWrapperElement.scrollLeft = newScrollLeft;
    filterContentWrapperElement.style.scrollBehavior = 'smooth';
  });
});

onBeforeUnmount(() => {
  const filterContentWrapperElement = filterContentWrapper.value as HTMLElement;
  filterContentWrapperElement.removeEventListener('wheel', () => {});
});
</script>

<template>
  <div class="relative">
    <ConversationIndexStatusFilterArrow
      :visible="showPreviousButton"
      id="arrow-button-left"
      data-test-id="arrow-button-left"
      direction="left"
      @click="navigationFilterHandler(SCROLL_JUMP * -1)"
    />
    <div
      class="relative flex scroll-mb-0 space-x-2 overflow-hidden scroll-smooth px-2"
      ref="filterContentWrapper"
      @scroll="onFilterContentScroll"
    >
      <ConversationIndexDispositionChip
        v-for="statusFilterOption in statusFilterOptions"
        :key="statusFilterOption.value"
        :is-selected="isSelected(statusFilterOption.value)"
        :text="statusFilterOption.text"
        :project="currentProject"
        :count="getStatusCandidatesCount(statusFilterOption.value as keyof JobApplicantStatusCount)"
        :icon="statusFilterOption.icon"
        @click="selectStatus(statusFilterOption.value)"
      />
    </div>
    <ConversationIndexStatusFilterArrow
      :visible="showNextButton"
      id="arrow-button-right"
      data-test-id="arrow-button-right"
      direction="right"
      @click="navigationFilterHandler(SCROLL_JUMP)"
    />
  </div>
</template>
