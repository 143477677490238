<script setup lang="ts">
import { computed, ref } from 'vue';
import ExpansionPanel from './ExpansionPanel.vue';
import ExpansionPanelSimpleContent from './ExpansionPanelSimpleContent.vue';
const props = defineProps<{
  panelTitle: string;
  items?: string[];
}>();
const expanded = ref(true);
const totalItems = computed(() => props.items?.length ?? 0);
</script>

<template>
  <ExpansionPanel
    :header-title="props.panelTitle"
    :header-items-count="totalItems"
    :expanded="expanded"
    :bg-color="false"
    @header:click="expanded = !expanded"
  >
    <template #content>
      <ExpansionPanelSimpleContent
        :panel-title="panelTitle"
        :items="items"
      ></ExpansionPanelSimpleContent>
    </template>
  </ExpansionPanel>
</template>
