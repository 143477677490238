<script setup lang="ts">
import type { NoteWithJobHeader } from '@/core/shared/pro-notes/types/pro-note.type';
import NoteCardMenu from './NoteCardMenu.vue';

import { computed } from 'vue';
import { ref } from 'vue';
import sanitizeHtml from 'sanitize-html';
import { getNameFor } from '@/core/sourcing/utils/userProfile';
import { formatUTCDate } from '@/core/sourcing/utils/dates';
import { onMounted } from 'vue';
import ProNotesService from '@/core/shared/pro-notes/pro-notes.service';
import { parseNoteTextToHtml } from '@/core/shared/pro-notes/note-sanitize';

const props = defineProps<{
  note: NoteWithJobHeader;
}>();
const noteCard = ref(null as HTMLElement | null);
const expanded = ref(false);
const showSizeToggle = ref(false);
const parsedNoteText = computed(() => {
  return parseNoteTextToHtml(sanitizeHtml(props.note.description ?? ''));
});
const showIconMenu = computed(() => {
  return expanded.value || !showSizeToggle.value;
});
const showMoreText = computed(() => {
  return expanded.value ? 'Read less' : 'Read more';
});
function getNoteAuthor(note: NoteWithJobHeader) {
  return note.userProfile ? getNameFor(note.userProfile) : 'System';
}
function toggleCard() {
  expanded.value = !expanded.value;
}

const proNotesService = new ProNotesService();
const menuItemsOptions = [
  {
    title: 'Delete note',
    action: () => proNotesService.deleteProNote(props.note.id),
    isDanger: true,
  },
];
const COLLAPSED_MAX_HIGHT = 66;
onMounted(() => {
  showSizeToggle.value =
    noteCard.value && noteCard.value.scrollHeight > COLLAPSED_MAX_HIGHT ? true : false;
});
</script>

<template>
  <article
    ref="noteCard"
    class="relative mb-3 overflow-hidden rounded-md border border-tint-80 p-3 leading-[22px]"
    :class="expanded ? 'h-fit max-h-fit' : 'h-[60px] max-h-[60px]'"
  >
    <div class="note-text" v-html="parsedNoteText"></div>
    <template v-if="note.jobHeader">
      (
      <router-link class="inline-block cursor-pointer text-highlight-600" :to="note.jobHeader.url">
        {{ note.jobHeader.jobTitle }}
      </router-link>
      )
    </template>
    <span
      v-if="showSizeToggle"
      class="absolute bottom-0 right-0 block cursor-pointer pr-3 underline"
      :class="{ 'relative mt-1 block p-0': expanded }"
      @click="toggleCard"
      @keyup.enter="toggleCard"
      >{{ showMoreText }}</span
    >
    <footer class="flex justify-between">
      <div class="text-shade-600">
        {{ getNoteAuthor(note) }} - {{ formatUTCDate(note.createTs) }}
      </div>
      <NoteCardMenu
        v-if="showIconMenu"
        class="note-options"
        :top="false"
        close-on-click
        :data-edit-note-menu="note.id"
        :menu-items="menuItemsOptions"
      />
    </footer>
  </article>
</template>

<style scoped></style>
