<template>
  <div class="flex h-full max-h-full w-full max-w-full justify-center align-middle">
    <div class="flex w-56 flex-col items-center justify-center gap-2 self-center text-center">
      <div class="max-w-fit rounded-full bg-tint-20 p-9">
        <img src="@/assets/svg/conversations/notification-text.svg" class="h-14 w-14" />
      </div>
      <div class="text-sm font-bold text-shade-800">No notes available</div>
      <div class="text-sm text-shade-800">This candidate doesn't have any notes yet.</div>
    </div>
  </div>
</template>
