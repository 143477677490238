<script setup lang="ts">
import FilterChip from '@/components/Sourcing/Filters/FilterChip/FilterChip.vue';
import { arrayToRecord } from '@/utils/objects/array-to-record.util';
import { computed } from 'vue';
import { onMounted, ref } from 'vue';

const props = defineProps<{
  label: string;
  items: { id: number; value: string }[];
  modelValue?: number;
  required?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value?: number): void;
  (e: 'click:delete'): void;
}>();

const isMenuVisible = ref(false);
const itemsRecord = computed(() => arrayToRecord(props.items, 'id'));
const isOptional = computed(() => !props.required);

function handleSelectedValue(value?: number) {
  isMenuVisible.value = false;

  if (isOptional.value || value) {
    emit('update:modelValue', value);
  }
}

function handleMenuStateChange(value: boolean) {
  isMenuVisible.value = value;
}

function handleChipRemoval() {
  isMenuVisible.value = false;

  if (isOptional.value) {
    emit('update:modelValue', undefined);
  }
}

function getValueDisplayText(id?: number): string {
  if (!id || isNaN(Number(id))) {
    return '';
  }

  return itemsRecord.value[id]?.value ?? '';
}

onMounted(() => {
  // Open the menu on mount if the value is empty
  if (!props.modelValue) {
    isMenuVisible.value = true;
  }
});
</script>

<template>
  <div>
    <v-menu
      :model-value="isMenuVisible"
      :close-on-content-click="false"
      transition="slide-y-transition"
      @update:model-value="handleMenuStateChange"
    >
      <template #activator="{ props }">
        <FilterChip
          v-bind="props"
          :label="label"
          :deletable="isOptional"
          :value="getValueDisplayText(modelValue)"
          @click:delete="handleChipRemoval"
        />
      </template>

      <div
        :class="[
          'border-1',
          'flex',
          'flex-col',
          'rounded-md',
          'bg-white',
          'w-[260px]',
          'max-h-[300px]',
          'overflow-y-auto',
          'shadow-menu',
        ]"
      >
        <div class="flex flex-col">
          <div
            v-for="item in items"
            :key="item.id"
            :class="{
              'flex min-h-[32px] cursor-pointer items-center px-3 py-1 hover:bg-highlight-50': true,
              'bg-highlight-50': item.id === modelValue,
            }"
            @click="handleSelectedValue(item.id)"
          >
            <span class="text-xs">{{ item.value }}</span>
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>
