import { useAtsConversationApi } from '@/composables/useApi';

import { useMessageSuggestionStore } from '@/core/conversations/message-suggestion/message-suggestion.store';
import type { MessageSuggestion } from './types/message-suggestion.interface';
import { InternalError } from '@/core/shared/errors/internal.error';

export class MessageSuggestionPersistence {
  private readonly store = useMessageSuggestionStore();

  public async fetchMessageaSuggestions(
    employerId: number,
    proId: number,
  ): Promise<Array<MessageSuggestion>> {
    const url = `/conversation/employer/${employerId}/pro/${proId}/staged-messages`;

    const { data, error, statusCode } = await useAtsConversationApi(url)
      .get()
      .json<Array<MessageSuggestion>>();

    if (error.value) {
      throw new InternalError('Failed to fetch suggested messages', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, proId },
      });
    }

    const messages = data.value || [];
    return messages;
  }
}
