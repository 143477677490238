<script lang="ts" setup>
import {
  type ConversationMessage,
  MessageMetadataType,
} from '@/core/conversations/message/conversation-message.type';
import MessageBubble from './MessageBubble.vue';
import CopilotBubble from './CopilotBubble.vue';
import MessageStatusChange from './MessageStatusChange.vue';
import { type JobApplicant, JobApplicantStatus } from '@factoryfixinc/ats-interfaces';
import { computed } from 'vue';
import DateUtils from '@/utils/date-utils';
import { findLastIndex } from '@/utils/arrays/lastIndex.util';

const props = defineProps<{
  messages: ConversationMessage[];
  currentProId: number | undefined;
  jobApplicant?: JobApplicant;
}>();

const lastRejectedStatusIndex = computed(() => {
  return findLastIndex(props.messages, 'content', JobApplicantStatus.REJECTED);
});

// Since we do not persist the text of the rejection reason in the status history
// this is a way arround to set the last `Rejected with reason` message but not
// the previous REJECTED ones
function isRejectedWithReason(index: number) {
  if (!props.jobApplicant) {
    return false;
  }

  return (
    index === lastRejectedStatusIndex.value &&
    props.jobApplicant.status === JobApplicantStatus.REJECTED &&
    props.jobApplicant.rejectionReason !== ''
  );
}

function showLineDivider(index: number) {
  if (index === 0) {
    return true;
  }

  return (
    props.messages[index].createdAt.getDate() !== props.messages[index - 1].createdAt.getDate()
  );
}

function isToday(date: Date) {
  return DateUtils.isSame(date);
}

function formatedDate(date: Date) {
  return isToday(date) ? 'Today' : DateUtils.formatTimestamp(date, 'dddd, MMMM Do');
}
</script>

<template>
  <div class="flex w-full flex-col flex-wrap justify-end p-2">
    <div v-for="(message, index) in messages" :key="index">
      <!-- Line Divider -->
      <div
        v-if="showLineDivider(index)"
        :class="{
          'line-divider': true,
          today: isToday(message.createdAt),
        }"
      >
        <span>{{ formatedDate(message.createdAt) }} </span>
      </div>

      <!-- Application Status Message -->
      <template v-if="message.metadata?.type === MessageMetadataType.APPLICATION_STATUS">
        <MessageStatusChange
          :message="message"
          :job-applicant="jobApplicant"
          :is-rejected-with-reason="isRejectedWithReason(index)"
        />
      </template>

      <template v-else>
        <!-- Copilot Message -->
        <CopilotBubble
          v-if="message.metadata?.type === MessageMetadataType.SCORE_BLURB"
          :message="message"
        />

        <!-- Employer, Pro and Bot Message -->
        <MessageBubble v-else :message="message" :key="message.id" :current-pro-id="currentProId" />
      </template>
    </div>
  </div>
</template>
<style scoped lang="postcss">
.line-divider {
  @apply border-t border-tint-60 p-2 text-shade-600;
  @apply mb-2 mt-4 flex items-center justify-center;

  span {
    @apply border border-tint-60 bg-white px-3 py-1;
    border-radius: 16px;
    font-size: 10px;
    margin-top: -19px;
    line-height: 10px;
  }
}
.today {
  @apply border-highlight-500 border-highlight-500 font-bold text-highlight-500;

  span {
    @apply border-highlight-500;
    line-height: 10px;
  }
}
</style>
