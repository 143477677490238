import type {
  ProEducationCertification,
  ProHistoryEmployer,
  UserProfile,
} from '@factoryfixinc/ats-interfaces';
import { useSelectedCandidateStore } from './selected-candidate.store';
import type { GatedUserProfile } from './types/gated-user-profile.type';
import SelectedCandidatePersistence from './selected-candidate.persistence';
import MeService from '@/core/shared/me/me.service';
import { getProSortedEducation } from '../utils/userProfile';
import TaxonomyService from '@/core/shared/taxonomy/taxonomy.service';
import ProNotesService from '../../shared/pro-notes/pro-notes.service';
import MessagingPersistence from '../messaging/messaging.persistence';
import { getUserProfileSortedWorkExperiences } from '@/core/shared/user-profile/utils';
import ProjectService from '@/core/shared/project/project.service';

export default class SelectedCandidateService {
  private store;
  private employerId: number | undefined;
  private taxonomyService = new TaxonomyService();
  private proNotesService = new ProNotesService();

  constructor() {
    this.store = useSelectedCandidateStore();
    const meService = new MeService();
    this.employerId = meService.employer?.id;
  }

  get candidate(): GatedUserProfile | undefined {
    return this.store.candidate;
  }

  get pro(): GatedUserProfile['pro'] | undefined {
    return this.candidate?.pro;
  }

  get isFetchingCandidate(): boolean {
    return this.store.isFetchingCandidate;
  }

  set isFetchingCandidate(isFetchingCandidate: boolean) {
    this.store.isFetchingCandidate = isFetchingCandidate;
  }

  get workExperiences(): ProHistoryEmployer[] {
    const experiences = this.store.candidate?.proHistoryEmployers ?? [];
    return getUserProfileSortedWorkExperiences(experiences);
  }

  get education(): ProEducationCertification[] {
    const education = this.store.candidate?.proEducationCertifications ?? [];
    return getProSortedEducation(education);
  }

  get industries(): string[] {
    return (
      this.pro?.proTaxonomyIndustryMaps?.reduce((prev, map) => {
        const industryTitle = this.taxonomyService.industriesRecord[map.taxonomyIndustryId];
        return industryTitle ? [...prev, industryTitle.title] : prev;
      }, [] as string[]) ?? []
    );
  }

  get knowledgeDisciplines(): string[] {
    return (
      this.pro?.proTaxonomyKnowledgeDisciplineMaps?.reduce((prev, map) => {
        const knowledgeDisciplineTitle =
          this.taxonomyService.knowledgeDisciplinesRecord[map.taxonomyKnowledgeDisciplineId];
        return knowledgeDisciplineTitle ? [...prev, knowledgeDisciplineTitle.title] : prev;
      }, [] as string[]) ?? []
    );
  }

  get machinesAndTechnologies(): string[] {
    return (
      this.pro?.proTaxonomyMachineMaps?.reduce((prev, map) => {
        const machineTitle = this.taxonomyService.machinesRecord[map.taxonomyMachineId];
        return machineTitle ? [...prev, machineTitle.title] : prev;
      }, [] as string[]) ?? []
    );
  }

  clearSelectedCandidate(): void {
    this.store.candidate = undefined;
  }

  async refreshCandidate(): Promise<void> {
    if (this.candidate?.id) {
      this.fetchCandidate(this.candidate?.id);
    }
  }

  async fetchCandidate(proUserProfileId: UserProfile['id']): Promise<void> {
    if (!this.employerId) {
      throw new Error('Employer not found');
    }
    const selectedCandidatePersistence = new SelectedCandidatePersistence();

    const gatedUserProfile = await selectedCandidatePersistence.fetchCandidate(
      this.employerId,
      proUserProfileId,
    );
    this.store.candidate = gatedUserProfile;

    if (this.pro?.id) {
      await this.proNotesService.fetchProNotes(this.pro.id);
    }

    const projectService = new ProjectService();
    const jobId = projectService.currentProject?.jobId;

    if (jobId && gatedUserProfile) {
      const messagingPersistence = new MessagingPersistence();
      const conversation = await messagingPersistence.getConversation(
        this.employerId,
        proUserProfileId,
        jobId,
      );

      if (!conversation) {
        return;
      }

      this.store.candidate.conversationId = conversation?.conversationId;
    }
  }
}
