<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import CopilotScoreFeedbackModal from '@/components/Conversations/Copilot/CopilotScoreFeedbackModal.vue';
import ResizeHandle from '@/components/Shared/Divider/ResizeHandle.vue';
import ConversationsTabs from '@/components/Conversations/Tabs/Tabs.vue';
import ConversationIndexFilters from '@/components/Conversations/ConversationIndex/ConversationIndexFilters.vue';
import ProHeader from '@/components/Conversations/ProHeader/ProHeader.vue';
import ProNotesService from '@/core/shared/pro-notes/pro-notes.service';
import ConversationIndexService from '@/core/conversations/conversation-index/conversation-index.service';
import ConversationWatcherService from '@/core/conversations/conversation-watcher/conversation-watcher.service';
import TrackingService from '@/core/shared/tracking/tracking.service';
import ProProfileService from '@/core/conversations/pro-profile/pro-profile.service';
import MeService from '@/core/shared/me/me.service';
import SpinnerLoader from '@/components/Shared/Loaders/SpinnerLoader.vue';
import EmptyConversationIndex from '@/components/Conversations/ConversationIndex/EmptyConversationIndex.vue';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import type { ProScore } from '@/core/conversations/application-score/application-score.type';
import ProjectService from '@/core/shared/project/project.service';
import { DrawerService } from '@/core/shared/drawer/drawer.service';
import { LayoutService } from '@/core/shared/layout/layout.service';

const route = useRoute();

const meService = new MeService();
const proNoteService = new ProNotesService();
const projectService = new ProjectService();
const drawerService = new DrawerService();
const layoutService = new LayoutService();
const conversationIndexService = new ConversationIndexService();
const conversationWatcherService = new ConversationWatcherService();
const conversationProProfileService = new ProProfileService();

const conversationsPanel = ref<HTMLElement | null>(null);

const conversationsPanelWidth = computed({
  get: () => layoutService.conversationsPanelWidth,
  set: (width: number) => {
    layoutService.conversationsPanelWidth = width;
  },
});

const conversationsPanelOffsetWidth = computed(() =>
  drawerService.isProjectsDrawerOpen ? 392 : 56,
);

const tabItems = reactive([
  { title: 'Resume', routeName: 'resume-tab', show: true },
  { title: 'Conversation', routeName: 'conversation-tab', show: true },
  { title: 'Applications', routeName: 'applications-tab', show: true },
  {
    title: 'Notes',
    routeName: 'notes-tab',
    show: true,
    count: computed(() => proNoteService.notesWithJobHeaders.length),
  },
]);

const currentEmployerId = computed(() => meService.employer?.id);
const selectingConversationIndex = computed(
  () => conversationIndexService.selectingConversationIndex,
);
const hasConversations = computed(() => conversationIndexService.conversationIndexes.length > 0);
const currentRouteName = computed(() => route.name);

async function handleProHeaderChangeProScore(proScore: ProScore) {
  const selectedConversationIndex = conversationIndexService.selectedConversationIndex ?? undefined;

  // We use the primary application index to display the pro's score in the conversation index card
  conversationIndexService.modifyConversationIndexPrimaryApplicationIndex(
    selectedConversationIndex,
    {
      applicationId: proScore.jobApplicationId,
      score: proScore.score,
    },
  );
}

watch(currentRouteName, async (newRouteName) => {
  if (typeof newRouteName !== 'string') {
    return;
  }

  if (/-tab$/.test(newRouteName)) {
    drawerService.visitedConversationsTabRouteName = newRouteName;
  }

  const tabTitle = tabItems.find((item) => item.routeName === newRouteName);
  if (tabTitle?.title) {
    TrackingService.trackAction(`${tabTitle.title} Tab Viewed`, {
      pro_id: conversationProProfileService.selectedProProfile?.id,
      conversation_id: conversationIndexService.selectedConversationIndex?.conversationId,
      application_id: conversationProProfileService.selectedProApplication?.id,
    });
  }
});

onMounted(async () => {
  try {
    if (currentEmployerId.value) {
      await conversationWatcherService.fetchConversationWatchers(currentEmployerId.value);
    }
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to fetch available jobs');
    throw error;
  }
});
</script>

<template>
  <div class="flex h-screen w-full">
    <!-- Empty Conversations Panel -->
    <div
      v-if="projectService.areNoProjectsAvailable"
      class="flex h-full w-full items-center justify-center"
    >
      <EmptyConversationIndex />
    </div>

    <!-- Loading Conversations Panel -->
    <div
      v-else-if="!projectService.didLoadProjects"
      class="flex h-full w-full items-center justify-center"
    >
      <SpinnerLoader />
    </div>

    <div v-else class="flex h-full w-full">
      <!-- Middle Conversations Panel  -->
      <section
        ref="conversationsPanel"
        class="overflow-y-auto px-2"
        :style="`width: ${conversationsPanelWidth}px`"
      >
        <ConversationIndexFilters />
      </section>

      <ResizeHandle
        :element="conversationsPanel"
        :min-element-width="240"
        :max-element-width="480"
        :element-offset-width="conversationsPanelOffsetWidth"
        @change:element-width="conversationsPanelWidth = $event"
      />

      <!-- Right Panel with Router View -->
      <section class="h-full w-full flex-1 overflow-hidden">
        <template v-if="selectingConversationIndex">
          <div class="flex h-full items-center justify-center">
            <SpinnerLoader />
          </div>
        </template>

        <template v-else>
          <div v-if="hasConversations" class="flex h-full flex-col overflow-hidden">
            <div class="flex h-[95px] flex-col justify-between border-b border-b-tint-60">
              <ProHeader @change:pro-score="handleProHeaderChangeProScore" />
              <ConversationsTabs :items="tabItems" />
            </div>

            <div class="h-full">
              <RouterView />
            </div>
          </div>
        </template>
      </section>
    </div>

    <CopilotScoreFeedbackModal />
  </div>
</template>
