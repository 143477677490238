import { arrayToRecord } from '@/utils/objects/array-to-record.util';
import { SelectedFilterType } from '@/core/sourcing/search/types/selected-filter-type.type';
import { DEFAULT_AROUND_RADIUS } from '@/core/sourcing/search/consts/default-seach-query.const';
import { useSearchStore } from '@/core/sourcing/search/search.store';
import { ProjectPersistence as SharedProjectPersistence } from '@/core/shared/project/project.persistence';

interface SavedSearchFilter {
  type: SelectedFilterType;
  value: string;
}

export class ProjectPersistence {
  private sharedProjectPersistence = new SharedProjectPersistence();
  private searchStore = useSearchStore();

  public async saveProjectSavedSearch(projectId: number): Promise<void> {
    const filtersRecord = arrayToRecord(this.searchStore.selectedFilters, 'type');
    const aroundRadius =
      filtersRecord[SelectedFilterType.AROUND_RADIUS]?.value || DEFAULT_AROUND_RADIUS;
    const location = filtersRecord[SelectedFilterType.LOCATION]?.value || undefined;
    const query = filtersRecord[SelectedFilterType.KEYWORD]?.value || '';

    const filters = this.searchStore.selectedFilters.filter((f) =>
      [
        SelectedFilterType.BRAND,
        SelectedFilterType.JOB_TITLE,
        SelectedFilterType.KNOWLEDGE_DISCIPLINE,
        SelectedFilterType.LAST_ACTIVE,
        SelectedFilterType.TECHNOLOGY,
        SelectedFilterType.YEARS_OF_EXPERIENCE,
      ].includes(f.type),
    );

    const mappedFilters: SavedSearchFilter[] = filters.reduce((acc, filter) => {
      const toAdd = [];
      // The backend requires a separate filter for each years of experience.
      if (filter.type === SelectedFilterType.YEARS_OF_EXPERIENCE) {
        const filters = filter.value.map((v) => ({ type: filter.type, value: `${v}` }));
        toAdd.push(...filters);
      }

      if (toAdd.length === 0) {
        toAdd.push({
          ...filter,
          value: `${filter.value}`,
        });
      }

      return [...acc, ...toAdd];
    }, [] as SavedSearchFilter[]);

    const savedSearches = [
      {
        id: this.searchStore.selectedFiltersId,
        location,
        aroundRadius,
        query,
        savedSearchFilters: mappedFilters,
      },
    ];

    await this.sharedProjectPersistence.setProjectSavedSearch(projectId, savedSearches);
  }
}
