import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { ConversationMessage } from '@/core/conversations/message/conversation-message.type';
import DateUtils from '@/utils/date-utils';

export const useConversationMessageStore = defineStore(
  'conversationMessage',
  () => {
    const allMessages = ref<ConversationMessage[]>([]);
    const normalizedAllMessages = computed(() => sort(deduplicate(allMessages.value)));
    const textOnlyMessages = computed(() => normalizedAllMessages.value.filter((m) => !m.metadata));
    const messagesPollingIntervalId = ref<number>();
    const cancellableRequests = ref<{ abort: () => void; caller: string }[]>([]);
    const lastMessageMarkedAsRead = ref<number>();
    /**
     * Unsent messages stored throughout an employer session so they can switch
     * between tabs or profiles without losing them.
     */
    const sessionUnsentMessages = ref<{ [key: number]: string }>({});

    function deduplicate(messages: ConversationMessage[]): ConversationMessage[] {
      // return allMessages.value
      const ids = messages.map((message) => message.id);
      const deduplicated = new Set(ids ?? []);
      return (
        (Array.from(deduplicated)?.map((id) =>
          messages.find((m) => m.id === id),
        ) as ConversationMessage[]) ?? []
      );
    }

    function sort(messages: ConversationMessage[]) {
      return messages.sort((a, b) => {
        return a.createdAt?.getTime() > b.createdAt?.getTime() ? 1 : -1;
      });
    }

    function pushMessages(messages: ConversationMessage[]) {
      const messagesParsedDate = messages.map((m) => ({
        ...m,
        createdAt: DateUtils.dateStringToDate(m.createdAt),
      }));

      allMessages.value = [...allMessages.value, ...messagesParsedDate];
    }

    function setMessages(messages: ConversationMessage[]) {
      allMessages.value = messages.map((m) => ({
        ...m,
        createdAt: DateUtils.dateStringToDate(m.createdAt),
      }));
    }

    function clearMessageList() {
      allMessages.value = [];
      return;
    }

    return {
      allMessages: normalizedAllMessages,
      pushMessages,
      setMessages,
      clearMessageList,
      textOnlyMessages,
      messagesPollingIntervalId,
      cancellableRequests,
      lastMessageMarkedAsRead,
      sessionUnsentMessages,
    };
  },
  {
    persist: false,
  },
);
