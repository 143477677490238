<script setup lang="ts">
import MessageCompose from '@/components/Conversations/Message/MessageCompose.vue';
import MessageList from '@/components/Conversations/Message/MessageList.vue';
import MeService from '@/core/shared/me/me.service';
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import ConversationMessageService from '@/core/conversations/message/conversation-message.service';
import ScrollUtils from '@/utils/scroll-utils';
import ConversationIndexService from '@/core/conversations/conversation-index/conversation-index.service';
import OptedOutMessagingPanel from '@/components/Conversations/Message/OptedOutMessagingPanel.vue';
import NoMessageHistoryOptedOut from '@/components/Conversations/Message/NoMessageHistoryOptedOut.vue';
import ProProfileService from '@/core/conversations/pro-profile/pro-profile.service';
import StagedMessagesWrapper from '@/components/Conversations/StagedMessage/StagedMessagesWrapper/StagedMessagesWrapper.vue';

const meService = new MeService();
const messageService = new ConversationMessageService();
const conversationIndexService = new ConversationIndexService();
const proProfileService = new ProProfileService();

const jobApplicant = computed(() => proProfileService.selectedProApplication);

const messagesViewElementRef = ref<HTMLElement>();
const nextPageToFetch = ref(1);
const isMessageListVisible = ref(true);
const messageComposeRef = ref<typeof MessageCompose>();

const userProfileId = computed(() => meService.userProfile?.id);

const currentProFirstName = computed(
  () => conversationIndexService.selectedConversationIndex?.proName?.split(' ')?.[0] ?? '',
);

const currentSelectedConversationId = computed(
  () => conversationIndexService.selectedConversationIndex?.conversationId,
);
const currentProId = computed(() => conversationIndexService.selectedConversationIndex?.proId);
const currentJobApplicantId = computed(() => jobApplicant.value?.id);
const currentProMessageStatus = computed(() => conversationIndexService.selectedProMessageStatus);
const currentEmployerId = computed(() => meService.getSelectedEmployerId());

const canReceiveMessage = computed(
  () => !currentProMessageStatus.value || currentProMessageStatus.value.canReceiveMessage,
);

const showNoMessageHistoryOptedOutComponent = ref(false);

const configureShowNoMessageHistoryOptedOutComponent = () => {
  showNoMessageHistoryOptedOutComponent.value =
    (!messageService.messageList || messageService.messageList.length === 0) &&
    !canReceiveMessage.value;
};

async function fetchInitialMessages(conversationId: number) {
  nextPageToFetch.value = 1;

  await messageService.fetchConversationMessages(conversationId, {
    page: nextPageToFetch.value,
    clearMessages: true,
  });

  nextPageToFetch.value++;
}

async function fetchExistingMessages(conversationId: number) {
  await messageService.fetchConversationMessages(conversationId, { page: 1 });
}

async function fetchMoreMessages(conversationId: number) {
  const newMessages = await messageService.fetchConversationMessages(conversationId, {
    page: nextPageToFetch.value,
  });

  if (newMessages.length > 0) {
    nextPageToFetch.value++;
  }
}

const sendMessage = async ({ messageContent }: { messageContent: string }) => {
  if (currentSelectedConversationId.value) {
    await messageService.sendMessage(currentSelectedConversationId.value, messageContent);
    ScrollUtils.scrollToBottom(messagesViewElementRef.value, 'smooth');
  }
};

const handleMessageListScroll = async (ev: UIEvent) => {
  if (!currentSelectedConversationId.value) {
    return;
  }

  const isStartOfScroll = ScrollUtils.isStartOfScroll(ev.target);

  if (isStartOfScroll) {
    await fetchMoreMessages(currentSelectedConversationId.value);
  }
};

function clearPollingInterval() {
  if (messageService.messagesPollingIntervalId) {
    window.clearInterval(messageService.messagesPollingIntervalId);
  }
}

const setPollingInterval = (currentlySelectedConversationId: number) => {
  clearPollingInterval();

  messageService.messagesPollingIntervalId = window.setInterval(async () => {
    if (!currentlySelectedConversationId) {
      return;
    }

    await fetchExistingMessages(currentlySelectedConversationId);
    const isEndOfScroll = ScrollUtils.isEndOfScroll(messagesViewElementRef.value);

    if (isEndOfScroll) {
      ScrollUtils.scrollToBottom(messagesViewElementRef.value, 'smooth');
    }
  }, 5000); // polling on messages every 5 seconds
};

function handleInsertStagedMessage(message: string) {
  if (messageComposeRef.value) {
    messageComposeRef.value.setMessageContent(message);
  }
}

watch(
  () => currentSelectedConversationId.value,
  async (newConversationId, oldConversationId) => {
    try {
      isMessageListVisible.value = false;

      if (newConversationId && newConversationId !== oldConversationId) {
        clearPollingInterval();
        await proProfileService.getApplicantStatusHistory();
        await fetchInitialMessages(newConversationId);

        // This small timeout will solve an issue when there are too many
        // messages sometimes the scrollToBottom will get "stuck" trying to
        // scroll to the bottom
        setTimeout(() => {
          ScrollUtils.scrollToBottom(messagesViewElementRef.value, 'instant');
        }, 100);

        setPollingInterval(newConversationId);
        configureShowNoMessageHistoryOptedOutComponent();
      }
    } finally {
      isMessageListVisible.value = true;
    }
  },
  { immediate: true },
);

onBeforeUnmount(() => {
  clearPollingInterval();
});
</script>

<template>
  <div
    class="flex h-full flex-col items-center justify-center text-center"
    v-if="showNoMessageHistoryOptedOutComponent"
  >
    <NoMessageHistoryOptedOut />
  </div>

  <template v-else>
    <div class="flex h-full flex-col">
      <div
        ref="messagesViewElementRef"
        :class="{
          'h-0 flex-grow overflow-y-auto transition-opacity': true,
          'opacity-0': !isMessageListVisible,
          'opacity-100': isMessageListVisible,
        }"
        @scroll="handleMessageListScroll"
      >
        <MessageList
          v-if="userProfileId"
          :messages="messageService.messageList"
          :current-pro-id="currentProId"
          :job-applicant="jobApplicant"
          :user-profile-id="userProfileId"
        />
      </div>

      <div class="w-full px-4 pb-2.5">
        <StagedMessagesWrapper
          :pro-id="currentProId"
          :employer-id="currentEmployerId"
          @click:insert="handleInsertStagedMessage"
        />
      </div>
      <div class="w-full px-4 pb-4">
        <MessageCompose
          v-if="canReceiveMessage"
          ref="messageComposeRef"
          :applicant-id="currentJobApplicantId"
          :pro-name="currentProFirstName"
          @send-message="sendMessage"
        />

        <OptedOutMessagingPanel v-else />
      </div>
    </div>
  </template>
</template>
