import { useTemplate } from './template.store';
import { TemplatePersistence } from './template.persistence';

import { type MessageTemplate } from './template.type';
import MeService from '@/core/shared/me/me.service';
import ConversationIndexService from '../conversation-index/conversation-index.service';

export const PLACEHOLDER_REGEX = /(\[['A-Za-z\s]*\])/g;

export default class TemplateService {
  private store = useTemplate();
  private persistence = new TemplatePersistence();

  private meService = new MeService();
  private conversationIndexService = new ConversationIndexService();

  public get templates(): MessageTemplate[] {
    return this.store.templates;
  }

  public set templates(templates: MessageTemplate[]) {
    this.store.templates = templates;
  }

  public get selectedTemplate(): MessageTemplate | null {
    return this.store.selectedTemplate;
  }

  public set selectedTemplate(selectedTemplate: MessageTemplate | null) {
    this.store.selectedTemplate = selectedTemplate;
  }

  public get showTemplateModal(): boolean {
    return this.store.showTemplateModal;
  }

  public set showTemplateModal(showTemplateModal: boolean) {
    this.store.showTemplateModal = showTemplateModal;
  }

  public replaceTemplatePlaceholders(template: string): string {
    return template.replace(PLACEHOLDER_REGEX, (match) => match);
  }

  public mapTemplatePlaceholders(template: string, addHtmlTags?: boolean): string {
    const proFullName = this.conversationIndexService.selectedConversationIndex?.proName || '';
    let placeHolderMappings = [
      {
        regex: /\[(Company Name)\]/gi,
        text: '[Company Name]',
        id: this.meService.employer?.name || '',
      },
      {
        regex: /\[(Pro FullName)\]/gi,
        text: '[Pro FullName]',
        id: proFullName,
      },
      {
        regex: /\[(My Name)\]/gi,
        text: '[My Name]',
        id: `${this.meService.userProfile?.nameFirst} ${this.meService.userProfile?.nameLast}`,
      },
      {
        regex: /\[(Pro FirstName)\]/gi,
        text: '[Pro FirstName]',
        id: proFullName.split(' ')[0],
      },
    ];
    if (addHtmlTags) {
      placeHolderMappings = placeHolderMappings.map((mapping) => {
        return {
          ...mapping,
          id: `<span class="bg-inform-100 text-inform-800 py-0.5 px-px">${mapping.id}</span>`,
        };
      });
    }
    return placeHolderMappings.reduce((acc, mapping) => {
      return acc.replace(mapping.regex, mapping.id);
    }, template);
  }

  public async fetchTemplates(): Promise<void> {
    const userProfileId = this.meService.userProfile?.id;
    if (userProfileId) {
      this.templates = await this.persistence.getTemplates(userProfileId.toString());
    }
  }

  public async createTemplate(template: Pick<MessageTemplate, 'title' | 'message'>): Promise<void> {
    await this.persistence.createTemplate(template);
    await this.fetchTemplates();
  }

  public async updateTemplate(
    template: Pick<MessageTemplate, 'id' | 'title' | 'message'>,
  ): Promise<void> {
    await this.persistence.updateTemplate(template);
    await this.fetchTemplates();
  }

  public async deleteTemplate(templateId: number): Promise<void> {
    await this.persistence.deleteTemplate(templateId);
    await this.fetchTemplates();
  }

  public async restoreTemplate(templateId: number): Promise<void> {
    await this.persistence.restoreTemplate(templateId);
    await this.fetchTemplates();
  }
}
