import type { CriteriaAssessment } from '@/core/conversations/application-score/application-score.type';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useDialogStore = defineStore('dialog', () => {
  const isCreateProjectDialogOpen = ref(false);

  // Project Description Dialog
  const projectDescriptionOverlayProps = ref<{
    isOpen: boolean;
    projectId?: number;
    jobId?: number;
  }>({
    isOpen: false,
    projectId: undefined,
    jobId: undefined,
  });

  const copilotScoreFeedbackModalProps = ref<CopilotScoreFeedbackModalProps>({
    isOpen: false,
  });

  return {
    isCreateProjectDialogOpen,
    projectDescriptionOverlayProps,
    copilotScoreFeedbackModalProps,
  };
});

export type CopilotScoreFeedbackModalProps =
  | { isOpen: false }
  | {
      isOpen: true;
      jobApplicantId: number;
      copilotScore: number;
      copilotBlurb: string;
      appContext?: string;
      copilotChips?: {
        title: string;
        text: string;
        color: CriteriaAssessment['relevantExperience']['color'];
      }[];
      copilotChipsAsDictionary?: Record<string, string>;
    };
