<script lang="ts" setup>
import { computed, getCurrentInstance, ref, watch } from 'vue';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import ApplicationScoreService from '@/core/conversations/application-score/application-score.service';
import ThumbsUpDownFeedback from '@/components/Shared/Feedback/ThumbsUpDownFeedback.vue';
import TrackingService from '@/core/shared/tracking/tracking.service';
import type { CriteriaAssessment } from '@/core/conversations/application-score/application-score.type';
import { DialogService } from '@/core/shared/dialog/dialog.service';
import { CopilotScoreFeedbackSubmittedSelection } from '@/core/shared/tracking/enums';

const props = withDefaults(
  defineProps<{
    alignment?: 'left' | 'right';
    appContext?: string;
    jobApplicantId: number;
    copilotScore: number;
    copilotBlurb: string;
    copilotChips?: {
      title: string;
      text: string;
      color: CriteriaAssessment['relevantExperience']['color'];
    }[];
  }>(),
  {
    alignment: 'left',
  },
);

const instance = getCurrentInstance();
const dialogService = new DialogService();
const appContext = computed(() => props.appContext ?? instance?.parent?.type.__name);
const feedbackGiven = ref(false);

const isCopilotScoreFeedbackModalOpen = computed(
  () => dialogService.copilotScoreFeedbackModalProps.isOpen,
);

const chipsAsDictionary = computed<Record<string, string>>(() => {
  return Object.entries(ApplicationScoreService.getChipsToDisplayDictionary()).reduce(
    (prev, [label, criteria]) => {
      if (!props.copilotChips) {
        return prev;
      }

      const chip = props.copilotChips.find((chip) => chip.title === label);

      if (chip) {
        prev[criteria] = chip.text;
        prev[`${criteria}Color`] = chip.color;
      }

      return prev;
    },
    {} as Record<string, string>,
  );
});

function sendFeedback(feedback: CopilotScoreFeedbackSubmittedSelection) {
  if (feedback === CopilotScoreFeedbackSubmittedSelection.THUMBSUP) {
    feedbackGiven.value = true;

    TrackingService.trackAction(TrackingActionName.COPILOT_SCORE_FEEDBACK_SUBMITTED, {
      jobApplicantId: props.jobApplicantId,
      copilotScore: props.copilotScore,
      copilotBlurb: props.copilotBlurb,
      feedback,
      appContext: appContext.value,
      ...chipsAsDictionary.value,
    });

    SnackbarService.showSnackbar({
      text: 'Thanks for your feedback!',
      horizontalPosition: 'center',
      prependIcon: 'success',
      prependIconCss: 'text-tint-0',
    });
  } else {
    dialogService.copilotScoreFeedbackModalProps = {
      isOpen: true,
      appContext: appContext.value,
      jobApplicantId: props.jobApplicantId,
      copilotScore: props.copilotScore,
      copilotBlurb: props.copilotBlurb,
      copilotChips: props.copilotChips,
      copilotChipsAsDictionary: chipsAsDictionary.value,
    };
  }
}

watch(isCopilotScoreFeedbackModalOpen, (state) => {
  if (state === false) {
    feedbackGiven.value = true;
  }
});
</script>

<template>
  <div class="flex min-h-7 min-w-[128px] items-center">
    <ThumbsUpDownFeedback
      v-model:feedbackGiven="feedbackGiven"
      :alignment="alignment"
      @thumbs-up="sendFeedback(CopilotScoreFeedbackSubmittedSelection.THUMBSUP)"
      @thumbs-down="sendFeedback(CopilotScoreFeedbackSubmittedSelection.THUMBSDOWN)"
    />
  </div>
</template>
