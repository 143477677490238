<template>
  <div class="flex h-full w-full flex-col pt-4">
    <div class="flex h-8 items-center gap-3 pl-4">
      <div>
        <img src="@/assets/svg/layout-left-black.svg" />
      </div>
      <h2 class="font-serif text-base font-extrabold text-shade-900">Candidates</h2>
    </div>

    <div class="flex h-full w-full flex-col items-center justify-center gap-y-2">
      <div class="flex h-32 w-32 items-center justify-center rounded-full bg-tint-40">
        <img src="@/assets/svg/conversations/users-01.svg" alt="Users Icon" class="h-16 w-16" />
      </div>
      <p class="mt-4 text-sm font-bold text-shade-800">No candidates... yet!</p>
      <p class="max-w-[204px] text-center text-sm text-shade-800">
        Create a job and let Copilot work around the clock to connect you with qualified candidates.
      </p>
    </div>
  </div>
</template>
