<script setup lang="ts">
import {
  type ConversationMessage,
  MessageMetadataType,
} from '@/core/conversations/message/conversation-message.type';
import { onMounted } from 'vue';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import CopilotAvatar from '@/components/Conversations/Avatar/CopilotAvatar.vue';
import CopilotScoreBlurb from '../Copilot/CopilotScoreBlurb.vue';

defineProps<{
  message: ConversationMessage;
}>();

onMounted(() => {
  TrackingService.trackAction(TrackingActionName.SCORE_SUMMARY_VIEWED, {
    source: 'Conversation Tab',
  });
});
</script>

<template>
  <div
    v-if="message.metadata?.type === MessageMetadataType.SCORE_BLURB && !!message.metadata.jobTitle"
    class="my-2 ml-9 flex items-end justify-end gap-1"
  >
    <!-- Copilot Score Blurb -->
    <CopilotScoreBlurb
      :show-visible-only-to-you="true"
      :show-copilot-chip="true"
      :score="message.metadata?.score"
      :content="message.content"
      :chips="message.metadata?.chips"
      :application-id="message.metadata?.applicationId"
      :score-version="message.metadata?.scoreVersion"
      :job-title="message.metadata?.jobTitle"
    />

    <!-- Copilot User Bubble -->
    <CopilotAvatar />
  </div>
</template>
