import DateUtils from '@/utils/date-utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const formatUTCDate = (
  date: string | Date,
  format = 'MM/DD/YYYY',
  defaultVal = '',
): string => {
  const momentDate = dayjs(date);
  if (!momentDate.isValid()) return defaultVal;
  return momentDate.utc().format(format);
};

export function isProRecentlyActive(lastActiveDateString?: string): boolean {
  if (!lastActiveDateString) {
    return false;
  }
  return DateUtils.age(DateUtils.dateStringToDate(lastActiveDateString)).asDays() <= 90;
}
