import { useDrawerStore } from './drawer.store';

export class DrawerService {
  private store = useDrawerStore();

  get projectsDrawer(): boolean {
    return this.store.projectsDrawer;
  }

  set projectsDrawer(value: boolean) {
    this.store.projectsDrawer = value;
  }
}
