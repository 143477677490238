import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useDrawerStore = defineStore('sourcing-drawer', () => {
  const projectsDrawer = ref<boolean>(true);

  return {
    projectsDrawer,
  };
});
