<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  loading: boolean;
  variant: 'Send message' | 'View conversation' | 'Unlock' | 'Sync to ATS';
  overrideClass?: Record<string, boolean>;
}>();

const variantClass = computed(() => {
  switch (props.variant) {
    case 'Send message':
      return 'bg-highlight-600 text-white';
    case 'View conversation':
      return 'text-black !border-2 !border-black';
    case 'Unlock':
      return 'bg-black text-white';
    case 'Sync to ATS':
      return '!border-2 !border-highlight-500 !text-highlight-500 !py-2';
    default:
      return '';
  }
});

const btnClass = computed(() => {
  return {
    [variantClass.value]: true,
    ...props.overrideClass,
  };
});
</script>

<template>
  <div class="w-full">
    <button
      class="relative box-border h-10 w-full rounded-md px-3 py-2.5 text-sm font-bold leading-5"
      :class="btnClass"
      :disabled="loading"
    >
      <span
        class="inline-block transition-all duration-150 ease-linear"
        :class="{
          'scale-0 opacity-0': loading,
        }"
      >
        {{ variant }}
      </span>
      <span
        class="absolute left-[calc(50%-10px)] top-2 inline-block transition-all duration-150 ease-linear"
        :class="{
          'scale-0 opacity-0': !loading,
          'scale-100 opacity-100': loading,
        }"
      >
        <div
          :class="[
            'w-6',
            'h-6',
            'border-4',
            'border-t-highlight-1000',
            'border-solid',
            'rounded-full',
            'animate-spin',
            'border-highlight-500',
          ]"
        ></div>
      </span>
    </button>
  </div>
</template>
