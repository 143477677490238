<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    verticalPosition: 'end' | 'center';
  }>(),
  {
    verticalPosition: 'end',
  },
);

function getVerticalPositionClass() {
  return {
    'justify-end': props.verticalPosition === 'end',
    'justify-center': props.verticalPosition === 'center',
  };
}
</script>

<template>
  <div class="flex flex-col" :class="getVerticalPositionClass()">
    <div class="flex h-8 w-8 items-center justify-center rounded-full bg-copilot-50">
      <img src="@/assets/svg/conversations/copilot_sparks.svg" alt="Sparks" />
    </div>
  </div>
</template>
