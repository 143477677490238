import type { Note } from './types/pro-note.type';
import { linkify } from '../../sourcing/utils/filters';

const TAGS_REGEX = /\[([^\]]+)\]\(([^)]+)\)/g;
const LINE_BREAK_REGEX = /\n|\\n/g;
const HTML_BR_TAG = '<br />';

export const parseNoteTextToHtml = (noteDescription: Note['description']): string | undefined => {
  const descriptionSanitized = noteDescription.replace(LINE_BREAK_REGEX, HTML_BR_TAG);

  const descriptionSanitizedWithTags = descriptionSanitized.replace(TAGS_REGEX, function () {
    // eslint-disable-next-line prefer-rest-params
    return `<span class="span-tag">${arguments[1]}</span>`;
  });
  return descriptionSanitizedWithTags;
  return linkify(descriptionSanitizedWithTags);
};

export const extractParseNoteTextParts = (
  noteDescription: Note['description'],
): { htmlWithoutTags: string; htmlNoteParsed: string; tags: Array<string> } => {
  const htmlNoteParsed = parseNoteTextToHtml(noteDescription) || '';
  const [htmlWithoutTags] = htmlNoteParsed?.split('tags:') || [];
  const [, tagsText] = noteDescription.split(TAGS_REGEX);
  const tags = Array.from(new Set(tagsText?.split(/\s/gi) || []))?.filter((tag) => !!tag) || [];
  return {
    htmlNoteParsed,
    htmlWithoutTags: htmlWithoutTags,
    tags,
  };
};
