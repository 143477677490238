<script setup lang="ts">
import FilterChip from '@/components/Sourcing/Filters/FilterChip/FilterChip.vue';
import { arrayToRecord } from '@/utils/objects/array-to-record.util';
import { onMounted, ref } from 'vue';
import { computed } from 'vue';

type Item = {
  id: number;
  title: string;
};

interface Props {
  label: string;
  placeholder: string;
  items: Item[];
  itemIdKey?: keyof Item;
  itemTitleKey?: keyof Item;
  modelValue?: number | string;
}

const props = withDefaults(defineProps<Props>(), {
  itemIdKey: 'id',
  itemTitleKey: 'title',
});

const emit = defineEmits<{
  (e: 'update:modelValue', value?: number | string): void;
  (e: 'click:delete'): void;
}>();

const isMenuVisible = ref(false);
const inputValue = ref<string>();
const isLoading = ref(false);
const itemsRecord = computed(() => arrayToRecord(props.items, 'id'));

const searchedItems = computed(() => {
  return props.items.filter((item) => {
    if (!inputValue.value || typeof inputValue.value !== 'string') {
      return true;
    }

    return item.title.toLowerCase().includes(inputValue.value.toLowerCase());
  });
});

function handleSelectedValue(item?: Item) {
  isMenuVisible.value = false;

  if (!item) {
    return;
  }

  emit('update:modelValue', item[props.itemIdKey]);
}

function handleMenuStateChange(value: boolean) {
  isMenuVisible.value = value;

  if (value === false) {
    emit('update:modelValue', props.modelValue);
  }
}

function handleChipRemoval() {
  isMenuVisible.value = false;
  emit('update:modelValue', undefined);
}

function getValueDisplayText(modelValue?: string | number): string {
  if (!modelValue) {
    return '';
  }

  if (props.itemIdKey === 'id') {
    return itemsRecord.value[modelValue]?.title ?? '';
  }

  return `${modelValue}`;
}

onMounted(() => {
  // Open the menu on mount if the value is empty
  if (!props.modelValue) {
    isMenuVisible.value = true;
  }
});
</script>

<template>
  <div>
    <v-menu
      :model-value="isMenuVisible"
      :close-on-content-click="false"
      transition="slide-y-transition"
      @update:model-value="handleMenuStateChange"
    >
      <template #activator="{ props }">
        <FilterChip
          v-bind="props"
          :label="label"
          deletable
          :value="getValueDisplayText(modelValue)"
          @click:delete="handleChipRemoval"
        />
      </template>

      <div
        :class="[
          'border-1',
          'flex',
          'flex-col',
          'rounded-md',
          'bg-white',
          'w-[260px]',
          'max-h-[300px]',
          'overflow-y-auto',
          'shadow-menu',
        ]"
      >
        <div class="p-3">
          <v-text-field
            v-model="inputValue"
            variant="outlined"
            density="compact"
            hide-details
            autofocus
            data-testid="searchable-list-chip-search"
            :placeholder="placeholder"
            :loading="isLoading"
            @click.stop
          />
        </div>

        <div class="flex flex-col">
          <div
            v-for="item in searchedItems"
            :key="item.id"
            :class="{
              'flex min-h-[32px] cursor-pointer items-center px-3 py-1 hover:bg-highlight-50': true,
              'bg-highlight-50': item[itemIdKey] === modelValue,
            }"
            data-testid="searchable-list-chip-item"
            @click="handleSelectedValue(item)"
          >
            <span class="text-xs">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>
