/* eslint-disable max-len */
import type { ProUnlockSource } from '@/core/sourcing/pro-unlock/types/pro-unlock-source.type';
import { useMessagingStore } from '@/core/sourcing/messaging/messaging.store';
import { MessagingSource } from '@/core/sourcing/messaging/types/messaging-source.type';
import { type SelectedGatedUserProfile } from '@/core/sourcing/messaging/types/selected-gated-user-profile.type';
import { getNameForCandidate } from '../search/utils/get-name-for-candidate.util';
import MeService from '@/core/shared/me/me.service';
import type { Job } from '@factoryfixinc/ats-interfaces';
import type { SearchGatedUserProfile } from '../search/types/search-gated-user-profile.type';
import MessagingPersistence from './messaging.persistence';
import SelectedCandidateService from '../selected-candidate/selected-candidate.service';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import type { CreatedMessage } from '@/core/conversations/message/conversation-message.type';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import {
  USER_OPTED_OUT_ERROR_MESSAGE,
  USER_OPTED_OUT_ERROR_MESSAGE_KEYWORD,
} from './shared/constants/message-constants';

export class MessagingService {
  private store = useMessagingStore();
  private persistence = new MessagingPersistence();
  private meService = new MeService();

  get selectedProUserProfiles(): SelectedGatedUserProfile[] {
    return this.store.selectedProUserProfiles;
  }

  set selectedProUserProfiles(value: SelectedGatedUserProfile[]) {
    this.store.selectedProUserProfiles = value;
  }

  get selectedPPCUserProfiles(): SelectedGatedUserProfile[] {
    return this.store.selectedProUserProfiles.filter(
      (profile) => profile.source === MessagingSource.PPC,
    );
  }

  get selectedUserProfilesToUnlock(): SelectedGatedUserProfile[] {
    return this.selectedProUserProfiles.filter((profile) => !profile.unlocked);
  }

  get selectedUserProfilesToUnlockCount(): number {
    return this.selectedUserProfilesToUnlock.length;
  }

  public removeSelectedProUserProfiles(): void {
    this.store.selectedProUserProfiles = [];
  }

  public markSelectedUserProfilesAsUnlocked(userProfileIds: number[]): void {
    this.store.selectedProUserProfiles = this.store.selectedProUserProfiles.map((profile) => {
      if (userProfileIds.includes(profile.id)) {
        profile.unlocked = true;
      }
      return profile;
    });
  }

  public removeProFromSelectedUserProfiles(userProfileId: number): void {
    this.store.selectedProUserProfiles = this.store.selectedProUserProfiles.filter(
      (profile) => profile.id !== userProfileId,
    );
  }

  public removeNonPPCProsFromSelectedUserProfiles(): void {
    this.store.selectedProUserProfiles = this.store.selectedProUserProfiles.filter(
      (profile) => profile.source === MessagingSource.PPC,
    );
  }

  public async addProUserProfileToSelectedList(
    proUserProfile: Pick<
      SearchGatedUserProfile,
      'profileId' | 'nameFirst' | 'nameLast' | 'unlocked' | 'lastActiveTs'
    > & {
      source: MessagingSource;
    },
  ): Promise<void> {
    const proUserProfileId = Number(proUserProfile.profileId);
    const fullName = getNameForCandidate(proUserProfile);
    const existingProfile = this.store.selectedProUserProfiles.find(
      (profile) => profile.id === proUserProfileId,
    );

    if (!existingProfile) {
      this.store.selectedProUserProfiles.push({
        id: proUserProfileId,
        fullName,
        unlocked: proUserProfile.unlocked,
        source: proUserProfile.source,
        lastActiveTs: proUserProfile.lastActiveTs,
      });
    }
  }

  public setIsTrialUpgradeModalVisible(params: {
    visible: boolean;
    source: ProUnlockSource;
  }): void {
    this.store.isUpgradeModalVisible = params.visible;
    this.store.proUnlockSource = params.source;
  }

  public async sendMessage(message: string, jobId: Job['id']): Promise<void> {
    const employerId = this.meService.employer?.id;

    if (!employerId) throw new Error('Could not send message');

    const promises = this.selectedProUserProfiles.map((profile) => {
      return this.persistence.createMessage(employerId, profile, jobId, message);
    });

    const results = await Promise.allSettled(promises);

    TrackingService.trackAction(TrackingActionName.MESSAGE_SENT, {
      source: this.selectedProUserProfiles?.at(0)?.source,
      num_pros: this.selectedProUserProfiles?.length,
      pro_id: this.selectedProUserProfiles.map((profile) => profile.id),
      conversation_id: (results as PromiseFulfilledResult<CreatedMessage>[]).map(
        (r) => r.value?.conversationId,
      ),
      message_content: message,
    });

    const failedMessages = results
      .filter((result) => result.status === 'rejected')
      .map((result) => result as PromiseRejectedResult);

    this.showSnackbarForFailedMessages(failedMessages, promises.length);

    const selectedCandidateService = new SelectedCandidateService();
    selectedCandidateService.refreshCandidate();

    this.store.selectedProUserProfiles = [];
  }

  private showSnackbarForFailedMessages(
    failedResults: PromiseRejectedResult[],
    totalMessages: number,
  ): void {
    if (failedResults.length === 0) return;

    const namesForOptedOutMessage: string[] = [];
    const namesForOtherErrosMessage: string[] = [];

    for (const result of failedResults) {
      const userName = result.reason.message.split(USER_OPTED_OUT_ERROR_MESSAGE_KEYWORD)[1];
      if (result.reason.message.includes(USER_OPTED_OUT_ERROR_MESSAGE)) {
        namesForOptedOutMessage.push(userName);
        continue;
      }
      namesForOtherErrosMessage.push(userName);
    }

    const isBulk = totalMessages > 1;
    let snackBarMessage = '';

    if (isBulk) {
      snackBarMessage = this.createBulkErrorMessage(
        failedResults,
        totalMessages,
        namesForOptedOutMessage,
        namesForOtherErrosMessage,
      );
    } else {
      snackBarMessage = this.createSingleErrorMessage(
        namesForOptedOutMessage,
        namesForOtherErrosMessage,
      );
    }

    SnackbarService.showSnackbar({
      text: snackBarMessage,
      appendIcon: 'close',
      horizontalPosition: 'center',
      prependIcon: 'inform',
    });
  }
  private createBulkErrorMessage(
    failedResults: unknown[],
    totalMessages: number,
    namesForOptedOutMessage: string[],
    namesForOtherErrosMessage: string[],
  ): string {
    let errorMessage = `<div class="flex flex-col p-3 w-full space-y-4"><p class="font-bold">${failedResults.length}/${totalMessages} messages not sent: `;

    const optOutMessage = `${namesForOptedOutMessage.join(', ')} opted out of messaging.`;
    const technicalIssueMessage = `Message to ${namesForOtherErrosMessage.join(
      ', ',
    )} failed due to technical issues. You can try sending a message from Conversations.`;

    if (namesForOptedOutMessage.length && namesForOtherErrosMessage.length) {
      errorMessage += `</p><p>${optOutMessage}</p><p>${technicalIssueMessage}</p></div>`;
      return errorMessage;
    }

    errorMessage += namesForOptedOutMessage.length ? optOutMessage : technicalIssueMessage;
    return errorMessage + '</p></div>';
  }

  private createSingleErrorMessage(
    namesForOptedOutMessage: string[],
    namesForOtherErrosMessage: string[],
  ): string {
    const message = '<span class="font-bold">Message not sent:</span>';

    if (namesForOptedOutMessage.length) {
      return `${message} ${namesForOptedOutMessage.join(', ')} opted out of messaging.`;
    }
    return `${message} Message to ${namesForOtherErrosMessage.join(
      ', ',
    )} failed due to technical issues. You can try sending a message from Conversations.`;
  }
}
