<script setup lang="ts">
import { type DefineComponent, computed, nextTick, onMounted, reactive, ref } from 'vue';

import { TEMPLATE_CHIPS } from '@/core/conversations/template/template.utils';
import { type MessageTemplate } from '@/core/conversations/template/template.type';

import TemplateChip from './TemplateChip.vue';
import TextArea from '@/components/Shared/Input/TextInputs/TextArea.vue';
import TextField from '@/components/Shared/Input/TextInputs/TextField.vue';
import { isRequired, minimumLength } from '@/utils/forms/';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import ExclamationIcon from '@/assets/svg/exclamation-circle-16.svg?component';

const TEMPLATE_MAX_LENGTH = 500;

const props = defineProps<{
  id: number;
  title: string;
  templateText: string;
}>();

const emits = defineEmits<{
  (e: 'cancel-edition'): void;
  (e: 'update-template', template: Pick<MessageTemplate, 'id' | 'title' | 'message'>): void;
}>();

const templateData = reactive({
  id: NaN,
  title: '',
  message: '',
});

const templatetextarea = ref<DefineComponent | null>(null);
const textAreaEl = ref<HTMLTextAreaElement | null>(null);

const insertChip = (chipText: string) => {
  if (!chipText.length || !isAbleToInsertChip.value) return;

  const sentence = textAreaEl.value?.value || '';
  const len = sentence.length;

  // Will not allow to insert chip if it exceeds the max length
  if (len + chipText.length > TEMPLATE_MAX_LENGTH) {
    textAreaEl.value?.focus();
    SnackbarService.caution(
      'The message is too long. Please remove some text before adding a chip.',
    );
    return;
  }

  const startSelection = textAreaEl.value?.selectionStart || 0;
  const endSelection = textAreaEl.value?.selectionEnd || startSelection;

  const textBeforeChip = sentence.substr(0, startSelection);
  const textAfterChip = sentence.substr(endSelection, len);

  templateData.message = textBeforeChip + chipText + textAfterChip;

  nextTick().then(() => {
    if (textAreaEl.value !== null && textAreaEl.value !== undefined) {
      textAreaEl.value.selectionStart = textAreaEl.value.selectionEnd =
        startSelection + chipText.length;
      textAreaEl.value.focus();
    }
  });
};

onMounted(() => {
  templateData.id = props.id;
  templateData.title = props.title;
  templateData.message = props.templateText;

  if (templatetextarea.value !== null) {
    textAreaEl.value = templatetextarea.value.$el.querySelector('.v-field__input');
  }
});

const loading = ref<boolean>(false);
const form = ref<boolean>(false); /** is Form valid?*/
const onSubmit = () => {
  if (!form.value) return;
  loading.value = true;
  emits('update-template', templateData);
};
const isAbleToInsertChip = computed(() => {
  return templateData.message.length < TEMPLATE_MAX_LENGTH;
});
</script>

<template>
  <v-form v-model="form" @submit.prevent="onSubmit">
    <div class="mb-1 flex flex-col">
      <label for="template-title" class="text-sm font-medium text-shade-880"
        >Template name (required)</label
      >
      <text-field
        v-model="templateData.title"
        id="template-title"
        name="template-title"
        maxlength="100"
        counter
        :rules="[isRequired, minimumLength]"
      />
    </div>
    <div class="flex flex-col">
      <label for="template-message" class="text-sm font-medium text-shade-880"
        >Template message (required)</label
      >
      <text-area
        ref="templatetextarea"
        v-model="templateData.message"
        :maxlength="TEMPLATE_MAX_LENGTH"
        rows="11"
        counter
        :rules="[isRequired, minimumLength]"
      />
    </div>
    <div class="relative mt-4 flex flex-col">
      <h3 class="text-sm leading-5 text-shade-800">Add personalization:</h3>
      <div class="mt-2 flex flex-wrap gap-2">
        <TemplateChip
          v-for="(chip, index) in TEMPLATE_CHIPS"
          :key="index"
          :text="chip"
          class="transition-opacity duration-300"
          :class="{ 'cursor-default': !isAbleToInsertChip, 'opacity-50': !isAbleToInsertChip }"
          @insert-chip="insertChip"
        />
      </div>
      <div class="mt-2">
        <v-slide-y-transition>
          <span v-show="!isAbleToInsertChip" class="absolute flex text-xs text-critical-500">
            <ExclamationIcon />
            <span class="ml-2 place-self-center">Character limit has been reached</span>
          </span>
        </v-slide-y-transition>
      </div>
    </div>
    <div class="mt-6 flex justify-end gap-2">
      <v-btn
        :ripple="false"
        class="modal-button-secondary"
        variant="flat"
        @click="$emit('cancel-edition')"
        >Cancel</v-btn
      >
      <v-btn
        :ripple="false"
        :disabled="!form"
        :loading="loading"
        class="modal-button-primary ml-4"
        type="submit"
        variant="flat"
        >Save and close</v-btn
      >
    </div>
  </v-form>
</template>
