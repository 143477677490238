<template>
  <div
    :class="{
      'flex gap-1 pb-2 pl-0 pr-0 pt-2 font-sans': true,
      'flex-row': isProMessage,
      'flex-row-reverse': !isProMessage,
    }"
    v-if="message && currentProId"
  >
    <template v-if="isBotMessage">
      <CopilotAvatar />
    </template>
    <template v-else>
      <UserAvatar
        :isProMessage="isProMessage"
        :isEmployerMessage="isEmployerMessage"
        :isBotMessage="isBotMessage"
        :name="getSenderName()"
      />
    </template>
    <div
      :class="{
        'flex max-w-2xl flex-col gap-y-2 rounded-xl border-[1px] border-solid px-4 py-3': true,
        'rounded-bl border-tint-60 bg-tint-40': isProMessage,
        'rounded-br': !isProMessage,
        'border-primary-100 bg-primary-50': isEmployerMessage,
        'border-copilot-50 bg-copilot-0': isBotMessage,
      }"
    >
      <div
        class="flex flex-row gap-1 text-sm"
        :class="[isBotMessage ? 'items-center' : 'items-baseline']"
      >
        <template v-if="isBotMessage">
          <CopilotChip />
        </template>
        <template v-else>
          <div class="font-bold text-shade-840">{{ getSenderName() }}</div>
        </template>
        <div class="text-xs text-shade-820">
          {{ DateUtils.formatTimeAndDateIfNotToday(message.createdAt) }}
        </div>
      </div>
      <div
        :class="{
          'whitespace-pre-wrap break-words text-sm': true,
          'text-shade-900': !isProMessage,
          'text-shade-880': isProMessage,
        }"
      >
        {{ message.content }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import UserAvatar from '@/components/Conversations/Avatar/UserAvatar.vue';
import CopilotAvatar from '@/components/Conversations/Avatar/CopilotAvatar.vue';
import CopilotChip from '@/components/Conversations/Message/CopilotChip.vue';
import type { ConversationMessage } from '@/core/conversations/message/conversation-message.type';
import { ConversationMessageType } from '@/core/conversations/message/conversation-message.type';
import DateUtils from '@/utils/date-utils';

import { computed } from 'vue';

const props = defineProps<{
  message: ConversationMessage;
  currentProId: number | undefined;
}>();

function getSenderName() {
  const { sender } = props.message;
  if (senderType.value === ConversationMessageType.SENT_BY_BOT) {
    return 'FactoryFix';
  }
  return sender?.name || 'Unknown';
}

const senderType = computed(() => {
  const { sender } = props.message;
  if (props.currentProId && props.currentProId === sender?.id) {
    return ConversationMessageType.SENT_BY_PRO;
  } else if (!sender?.id) {
    return ConversationMessageType.SENT_BY_BOT;
  } else {
    return ConversationMessageType.SENT_BY_EMPLOYER;
  }
});

const isProMessage = computed(() => senderType.value === ConversationMessageType.SENT_BY_PRO);
const isBotMessage = computed(() => senderType.value === ConversationMessageType.SENT_BY_BOT);
const isEmployerMessage = computed(
  () => senderType.value === ConversationMessageType.SENT_BY_EMPLOYER,
);
</script>
