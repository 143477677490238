<template>
  <div class="shadow-ppc rounded-xl bg-white">
    <div class="flex justify-between">
      <div class="relative box-border w-full rounded-lg p-4">
        <!-- First line -->
        <div class="linear-loading-highlight linear-loading-tint mb-2 h-2 w-1/6 rounded-full"></div>
        <!-- Second line -->
        <div class="linear-loading-tint mb-4 h-2 w-2/6 rounded-full"></div>
        <!-- Third Line -->
        <div v-for="i in 5" :key="i" class="list-items">
          <div class="avatar"></div>
          <div class="line"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="postcss">
.list-items {
  @apply mb-1.5 flex h-4 w-full items-center;

  .avatar {
    @apply linear-loading-highlight linear-loading-tint mr-2 h-4 w-4 rounded;
  }
  .line {
    @apply linear-loading-highlight linear-loading-tint mr-2 h-2 w-6/12 rounded-full;
  }
  &:nth-child(4) {
    .line {
      @apply w-10/12;
    }
  }
  &:nth-child(5) {
    .line {
      @apply w-11/12;
    }
  }
  &:nth-child(7) {
    .line {
      @apply w-1/12;
    }
  }
}

.shadow-ppc {
  box-shadow:
    0 4px 6px -2px rgba(13, 10, 44, 0.03),
    0 12px 16px -4px rgba(13, 10, 44, 0.03),
    0 1px 2px -1px rgba(13, 10, 44, 0.1);
}
</style>
