<script setup lang="ts">
import { getCurrentInstance, ref } from 'vue';

interface Props {
  menuItems: Array<{
    title: string;
    action: () => void;
    isDanger?: boolean;
    adminOnly?: boolean;
  }>;
}
defineProps<Props>();
const instance = getCurrentInstance();
const uid = ref(instance?.uid);
</script>

<template>
  <div :id="`menu-wrapper-${uid}`">
    <v-menu close-on-click min-width="180px" :attach="`#menu-wrapper-${uid}`">
      <template #activator="{ props }">
        <button class="px-3" v-bind="props">
          <img src="@/assets/svg/dots-vertical-gray.svg" alt="" srcset="" />
        </button>
      </template>
      <v-list density="compact">
        <v-list-item
          v-for="({ adminOnly, isDanger, action, title }, index) in menuItems"
          v-show="!adminOnly"
          :key="index"
          class="text-xs"
          height="20px"
          :class="isDanger ? 'text-critical-500' : ''"
          data-list-item
          @click.stop="action"
        >
          <span>
            {{ title }}
          </span>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
