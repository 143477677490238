import { defineStore } from 'pinia';
import { ref } from 'vue';

import { type MessageTemplate } from './template.type';

export const useTemplate = defineStore(
  'template',
  () => {
    const showTemplateModal = ref(false);
    const selectedTemplate = ref<MessageTemplate | null>(null);
    const templates = ref<MessageTemplate[]>([]);

    return {
      templates,
      selectedTemplate,
      showTemplateModal,
    };
  },
  {
    persist: true,
  },
);
