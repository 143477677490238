<template>
  <div
    class="w-full rounded-xl bg-[url(/png/conversations/copilot_banner.png)] bg-cover bg-no-repeat p-4 text-tint-0"
  >
    <h2 class="font-serif text-base font-black">This application wasn't sourced by Copilot.</h2>
    <p class="font-sans text-xs">
      Effortlessly reclaim your time—flip the Copilot switch for future applications.
    </p>
  </div>
</template>
