<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  index: number;
  totalCount: number;
  message: string;
}>();

const emits = defineEmits<{
  (e: 'click:insert', index: number): void;
}>();

const suggestionCountText = computed(() => {
  return `Suggestion ${props.index} of ${props.totalCount}`;
});

const messageText = computed(() => {
  return props.message;
});

const messageIndex = computed(() => {
  return props.index;
});

function insertMessage() {
  emits('click:insert', messageIndex.value);
}
</script>

<template>
  <div class="staged-message-card absolute h-[163px] rounded-md p-[1px]">
    <div class="h-full rounded-[5px] bg-white p-3 pb-12">
      <p class="mb-3 text-xs font-normal italic text-shade-700">
        {{ suggestionCountText }}
      </p>
      <p class="text-sm font-normal text-shade-900">
        {{ messageText }}
      </p>
      <button
        type="button"
        class="absolute bottom-3 left-3 rounded-md border-2 border-solid border-shade-900 px-3 py-1.5 text-sm font-semibold"
        @click="insertMessage"
      >
        Insert
      </button>
    </div>
  </div>
</template>

<style scoped>
.staged-message-card {
  background: linear-gradient(95.61deg, rgba(174, 53, 247, 0.1) 0%, rgba(108, 64, 229, 0.1) 100%);
}
</style>
