<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  label: string;
  value?: string | number;
  info?: string;
  deletable?: boolean;
}>();

const hasInfo = computed(() => !!props.info);
const hasValue = computed(() => !!props.value);

defineEmits<{
  (e: 'click:delete'): void;
  (e: 'click:info'): void;
}>();
</script>

<template>
  <div
    v-bind="$attrs"
    class="inline-block cursor-pointer rounded-3xl border border-caution-200 bg-caution-50 px-2 py-1"
  >
    <div class="flex items-center text-caution-800">
      <v-tooltip v-if="hasInfo" location="top" max-width="256px" content-class="bg-black">
        <template #activator="{ props }">
          <img
            v-bind="props"
            src="@/assets/svg/sourcing/help-circle.svg"
            class="mr-1 h-4 w-4 cursor-pointer"
            @click="$emit('click:info')"
          />
        </template>

        {{ info }}
      </v-tooltip>

      <span class="text-xs font-bold uppercase leading-4">{{ label }}: </span>
      <span v-show="hasValue" class="ml-1 text-xs leading-4">{{ value }}</span>

      <img
        v-if="deletable"
        src="@/assets/svg/sourcing/x-close.svg"
        class="ml-1 h-4 w-4 cursor-pointer"
        @click="$emit('click:delete')"
      />
    </div>
  </div>
</template>
