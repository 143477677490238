<script setup lang="ts">
import lottie from 'lottie-web';
import MessageSentAnimationData from '@/assets/json/message-sent-animation.json';
import type { VueElement } from 'vue';
import { onMounted, ref } from 'vue';

const emit = defineEmits<{
  (e: 'animation:complete', value: boolean): void;
}>();

const animationRef = ref<VueElement | null>(null);

onMounted(() => {
  const loader = animationRef.value as VueElement;
  loadAnimation(loader);
});

const loadAnimation = (loader: VueElement) => {
  const animation = lottie.loadAnimation({
    container: loader,
    renderer: 'svg',
    loop: false,
    autoplay: true,
    animationData: MessageSentAnimationData,
  });
  animation.addEventListener('complete', () => {
    emit('animation:complete', true);
  });
};
</script>

<template>
  <div ref="animationRef" class="h-[320px] w-full overflow-hidden bg-white"></div>
</template>
