<script setup lang="ts">
import { computed, ref } from 'vue';
import NoteCard from './NoteCard.vue';
import type { NoteWithJobHeader } from '@/core/shared/pro-notes/types/pro-note.type';

const props = defineProps<{
  notes: NoteWithJobHeader[];
}>();
const NUMBER_OF_NOTES_TO_SHOW_BY_DEFAULT = 2;
const numberOfNotesToShow = ref(NUMBER_OF_NOTES_TO_SHOW_BY_DEFAULT);

const showMoreNotes = computed(() => {
  return props.notes.length > numberOfNotesToShow.value;
});
const notesToShow = computed(() => {
  return props.notes.slice(0, numberOfNotesToShow.value);
});
function toggleNotesToShow() {
  numberOfNotesToShow.value === NUMBER_OF_NOTES_TO_SHOW_BY_DEFAULT
    ? (numberOfNotesToShow.value = props.notes.length)
    : (numberOfNotesToShow.value = NUMBER_OF_NOTES_TO_SHOW_BY_DEFAULT);
}
</script>

<template>
  <div class="notes-list">
    <NoteCard v-for="note in notesToShow" :key="note.id" :note="note" />

    <a
      v-if="notes.length > 2"
      class="mt-2.5 cursor-pointer underline"
      data-test-id="show-more"
      @click="toggleNotesToShow"
      @keyup.enter="toggleNotesToShow"
    >
      {{ showMoreNotes ? 'Show More' : 'Show Less' }}
    </a>
  </div>
</template>

<style scoped></style>
