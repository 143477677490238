<script lang="ts" setup>
import ThumbsUpSvg from '@/assets/svg/conversations/thumbs-up-16.svg?component';
import ThumbsDownSvg from '@/assets/svg/conversations/thumbs-down-16.svg?component';

withDefaults(
  defineProps<{
    alignment?: 'left' | 'right';
    feedbackSubmittedText?: string;
    feedbackPendingText?: string;
    feedbackGiven?: boolean;
  }>(),
  {
    alignment: 'left',
    feedbackSubmittedText: 'Thank you!',
    feedbackPendingText: 'How did I do?',
    feedbackGiven: false,
  },
);

const emit = defineEmits<{
  (e: 'thumbs-up'): void;
  (e: 'thumbs-down'): void;
  (e: 'update:feedbackGiven', value: boolean): void;
}>();

function handleAnimationEnd() {
  emit('update:feedbackGiven', false);
}

function givePositiveFeedback() {
  emit('thumbs-up');
}

function giveNegativeFeedback() {
  emit('thumbs-down');
}
</script>

<template>
  <div class="flex h-4 w-full items-center">
    <!-- Feedback given -->
    <template v-if="feedbackGiven">
      <span
        :class="{
          'thank-you-message w-full text-xs italic leading-[10px] text-shade-800': true,
          'text-left': alignment === 'left',
          'text-right': alignment === 'right',
        }"
        @animationend="handleAnimationEnd"
        >{{ feedbackSubmittedText }}</span
      >
    </template>

    <!-- Pending feedback -->
    <template v-else>
      <span class="text-xs italic leading-[10px] text-shade-800">{{ feedbackPendingText }}</span>

      <div class="ml-2 flex gap-1">
        <!-- Thumbs up -->
        <button
          class="text-shade-840 [&>svg]:hover:fill-shade-840 [&>svg]:hover:stroke-shade-800"
          @click="givePositiveFeedback"
        >
          <ThumbsUpSvg />
        </button>

        <!-- Thumbs down -->
        <button
          class="text-shade-840 [&>svg]:hover:fill-shade-840 [&>svg]:hover:stroke-shade-800"
          @click="giveNegativeFeedback"
        >
          <ThumbsDownSvg />
        </button>
      </div>
    </template>
  </div>
</template>

<style scoped>
.thank-you-message {
  animation: fade-out-thanks 0.5s 3s forwards;
}

@keyframes fade-out-thanks {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
