<script lang="ts" setup>
import { ref } from 'vue';

const open = ref(true);
defineProps<{ header?: string; body?: string }>();

function toggleOpen() {
  open.value = !open.value;
}
</script>

<template>
  <div class="w-full gap-2 rounded-xl border-2 border-tint-40 bg-tint-20 p-4">
    <div class="mb-3 flex flex-row gap-1">
      <div class="bold flex-1">
        <slot name="header">
          <span class="bold font-bold">
            {{ header }}
          </span>
        </slot>
      </div>
      <div class="cursor-pointer" @click="toggleOpen">
        <img
          src="@/assets/svg/chevron-up.svg"
          :class="{ 'w-6 transition-all': true, 'rotate-180': !open }"
        />
      </div>
    </div>
    <div :class="{ 'transition-all': true, 'h-0 max-h-0 overflow-hidden': !open }">
      <slot name="body">
        <span class="font-normal">
          {{ body }}
        </span>
      </slot>
    </div>
  </div>
</template>
