<script setup lang="ts">
import { computed } from 'vue';
import PlusIcon from '@/assets/svg/sourcing/plus.svg';
import PlusDisabledIcon from '@/assets/svg/sourcing/plus-disabled.svg';

const props = withDefaults(
  defineProps<{
    prependIcon?: 'plus';
    size?: 'small' | 'normal';
    accent?: boolean;
    disabled?: boolean;
  }>(),
  {
    size: 'normal',
  },
);

const emit = defineEmits<{
  (e: 'click', value: MouseEvent): void;
}>();

const enabled = computed(() => !props.disabled);
</script>

<template>
  <div
    :class="{
      'flex items-center py-2': true,
      'cursor-pointer': enabled,
    }"
    @click="enabled ? emit('click', $event) : undefined"
  >
    <img v-if="prependIcon === 'plus'" :src="enabled ? PlusIcon : PlusDisabledIcon" class="mr-1" />

    <div
      :class="{
        'font-bold leading-4': true,
        'text-shade-880': enabled && !accent,
        'text-highlight-600': enabled && accent,
        'text-shade-600': disabled,
        'text-xs': size === 'small',
        'text-sm': size === 'normal',
      }"
    >
      <slot />
    </div>
  </div>
</template>
