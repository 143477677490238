<script setup lang="ts">
import ListChip from '@/components/Shared/Input/ListChip/ListChip.vue';

defineProps<{
  modelValue?: number;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value?: number): void;
}>();

const items = [
  { id: 10, value: '10 miles' },
  { id: 20, value: '20 miles' },
  { id: 30, value: '30 miles' },
  { id: 40, value: '40 miles' },
  { id: 50, value: '50 miles' },
  { id: 60, value: '60 miles' },
  { id: 70, value: '70 miles' },
  { id: 80, value: '80 miles' },
  { id: 90, value: '90 miles' },
  { id: 100, value: '100+ miles' },
];
</script>

<template>
  <ListChip
    label="Within"
    :model-value="modelValue"
    :items="items"
    required
    @update:model-value="emit('update:modelValue', $event)"
    @click:delete="emit('update:modelValue', undefined)"
  />
</template>
