import { defineStore } from 'pinia';
import type { ProPreviewStats } from './types/pro-preview-stats.type';
import { ref } from 'vue';

export const useProPreviewStatsStore = defineStore('sourcing-pro-preview-stats', () => {
  const statsRequestStatus = ref<'idle' | 'loading' | 'loaded'>('idle');
  const statsByProId = ref<Record<string, ProPreviewStats>>({});

  return {
    statsRequestStatus,
    statsByProId,
  };
});
