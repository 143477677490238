<script setup lang="ts">
import { ref } from 'vue';
import DotsHorizontalIcon from '@/assets/svg/conversations/DotsHorizontalIcon.svg?component';
import PenIcon from '@/assets/svg/conversations/PenIcon.svg?component';
import TrashIcon from '@/assets/svg/conversations/TrashIcon.svg?component';
import type { NoteWithJobHeader } from '@/core/shared/pro-notes/types/pro-note.type';

defineProps<{
  note: NoteWithJobHeader;
}>();

const emits = defineEmits(['delete-note', 'edit-note']);

const menuOpened = ref<boolean>(false);

const editNote = () => {
  emits('edit-note');
  menuOpened.value = false;
};

const deleteNote = () => {
  emits('delete-note');
  menuOpened.value = false;
};
</script>

<template>
  <div>
    <v-menu v-model="menuOpened" location="bottom">
      <template v-slot:activator="{ props }">
        <div
          class="grid h-8 w-8 cursor-pointer place-content-center rounded-md transition-colors active:bg-tint-40"
          :class="{ 'bg-tint-40': menuOpened }"
          v-bind="props"
        >
          <DotsHorizontalIcon class="h-5 w-5 text-shade-800" />
        </div>
      </template>
      <ul
        class="space-y-2 overflow-hidden rounded-md bg-white px-3 py-2 text-xs text-shade-900 drop-shadow-4"
      >
        <li class="flex h-8 cursor-pointer items-center gap-2" @click="editNote">
          <PenIcon class="h-4 w-4 text-shade-900" />
          <span>Edit note</span>
        </li>
        <li class="flex h-8 cursor-pointer items-center gap-2" @click="deleteNote">
          <TrashIcon class="h-4 w-4 text-critical-600" />
          <span>Delete note</span>
        </li>
      </ul>
    </v-menu>
  </div>
</template>
