<script setup lang="ts">
import { computed, ref } from 'vue';

import CopilotBubbleExpandedSvg from '@/assets/svg/copilot-bubble-expanded.svg';
import StagedMessageCarrousel from '@/components/Conversations/StagedMessage/StagedMessagesCarrousel/StagedMessagesCarrousel.vue';

import MessageSuggestionService from '@/core/conversations/message-suggestion/message-suggestion.service';
import { onMounted } from 'vue';

const props = defineProps<{
  employerId?: number;
  proId?: number;
}>();

const emits = defineEmits<{
  (e: 'click:insert', index: string): void;
}>();

const messageSuggestionService = new MessageSuggestionService();

const isSuggestionsCarrouselOpen = ref(false);
const shouldShowSuggestionPill = ref(false);

const messages = computed(() => messageSuggestionService.suggestedMessages);
const currentEmployerId = computed(() => props.employerId);
const currentProId = computed(() => props.proId);
const isShowingSuggestionPill = computed(
  () => shouldShowSuggestionPill.value && !isSuggestionsCarrouselOpen.value,
);

onMounted(async () => {
  await fetchSuggestedMessages();
});

async function fetchSuggestedMessages() {
  const parsedEmployerId = Number(currentEmployerId.value);
  const parsedProId = Number(currentProId.value);

  if (isNaN(parsedEmployerId) || isNaN(parsedProId)) {
    return;
  }

  try {
    const messages = await messageSuggestionService.fetchSuggestedMessages(
      parsedEmployerId,
      parsedProId,
    );

    if (!messages.length) {
      // If there are no messages, close the carrousel
      hideSuggestionPill();
      closeSuggestionsCarrousel();
    } else {
      showSuggestionPill();
      if (messageSuggestionService.shouldDisplayMessageSuggestionsCarrouselImmediately) {
        openSuggestionsCarrousel();
      }
    }
  } catch (error) {
    hideSuggestionPill();
    closeSuggestionsCarrousel();
  }
}

function hideSuggestionPill() {
  shouldShowSuggestionPill.value = false;
}

function showSuggestionPill() {
  shouldShowSuggestionPill.value = true;
}

function openSuggestionsCarrousel() {
  isSuggestionsCarrouselOpen.value = true;
  messageSuggestionService.shouldDisplayMessageSuggestionsCarrouselImmediately = true;
}

function closeSuggestionsCarrousel(
  shouldDisplayMessageSuggestionsCarrouselImmediately: boolean = false,
) {
  isSuggestionsCarrouselOpen.value = false;
  messageSuggestionService.shouldDisplayMessageSuggestionsCarrouselImmediately =
    shouldDisplayMessageSuggestionsCarrouselImmediately;
}

function handleInsertMessage(message: string) {
  emits('click:insert', message);
  // Sending a message should not change the default behaviour of showing the carrousel
  closeSuggestionsCarrousel(true);
}
</script>

<template>
  <div>
    <!-- Open Pill -->
    <div
      v-if="isShowingSuggestionPill"
      class="suggestion-title-pill flex w-[170px] cursor-pointer items-center rounded-2xl rounded-bl-sm py-0.5 pl-0.5 pr-0.5"
      @click="openSuggestionsCarrousel"
    >
      <img :src="CopilotBubbleExpandedSvg" />
      <span class="ml-1 font-sans text-xs font-bold text-highlight-600"> Message Suggestions </span>
    </div>

    <StagedMessageCarrousel
      v-if="isSuggestionsCarrouselOpen"
      :messages="messages"
      @click:hide="closeSuggestionsCarrousel"
      @click:insert="handleInsertMessage"
    />
  </div>
</template>

<style scoped>
.suggestion-title-pill {
  background: radial-gradient(
    100.46% 973.3% at 0% 50%,
    rgba(95, 48, 209, 0.1) 0%,
    rgba(179, 54, 255, 0.1) 100%
  );
}
</style>
