<script setup lang="ts">
import ContactInfo from '@/components/Sourcing/ProPreviewPanel/ContactInfo.vue';
import { computed, ref, watch } from 'vue';
import WorkExperiencePanel from './ProPreviewPanel/WorkExperiencePanel.vue';
import EducationPanel from './ProPreviewPanel/EducationPanel.vue';
import type { GatedUserProfile } from '@/core/sourcing/selected-candidate/types/gated-user-profile.type';
import SelectedCandidateService from '@/core/sourcing/selected-candidate/selected-candidate.service';
import PanelDivider from './ProPreviewPanel/PanelDivider.vue';
import SimpleExpansionPanel from '@/components/Shared/ExpansionPanel/SimpleExpansionPanel.vue';
import NotesPanel from './ProPreviewPanel/NotesPanel.vue';
import ProNotesService from '@/core/shared/pro-notes/pro-notes.service';

import { ProUnlockSource } from '@/core/sourcing/pro-unlock/types/pro-unlock-source.type';
import SubscriptionService from '@/core/shared/subscription/subscription.service';
import { MessagingService } from '@/core/sourcing/messaging/messaging.service';
import { MessagingSource } from '@/core/sourcing/messaging/types/messaging-source.type';
import PanelActionButton from './ProPreviewPanel/PanelActionButton.vue';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { useRouter } from 'vue-router';
import ResumeDialog from '@/components/Shared/DIalogs/ResumeDialog.vue';
import TrackingService from '@/core/shared/tracking/tracking.service';
import AtsConnectService from '@/core/shared/ats-connect/ats-connect.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { isProRecentlyActive } from '@/core/sourcing/utils/dates';
import ProUnlockService from '@/core/sourcing/pro-unlock/pro-unlock.service';
import type { Project } from '@factoryfixinc/ats-interfaces';
import { ErrorService } from '@/core/shared/errors/error.service';

const router = useRouter();

const props = defineProps<{
  project?: Project;
}>();

const emit = defineEmits<{
  (e: 'open:messaging', value: boolean): void;
}>();

const selectedCandidateService = new SelectedCandidateService();
const atsSyncService = new AtsConnectService();
const proNotesService = new ProNotesService();
const proUnlockService = new ProUnlockService();
const messagingService = new MessagingService();
const subscriptionService = new SubscriptionService();

const isResumeDialogOpened = ref(false);
const isSyncingApplication = ref(false);
const isFetchingSyncApplication = ref(false);
const hasAlreadySynced = ref(false);

const isFetchingCandidate = computed(() => selectedCandidateService.isFetchingCandidate);
const candidate = computed<GatedUserProfile | undefined>(() => selectedCandidateService.candidate);
const isUnlocked = computed(() => candidate.value?.unlocked);
const conversationId = computed(() => candidate.value?.conversationId);
const hasPPCSelection = computed(() => messagingService.selectedPPCUserProfiles.length > 0);
const workExperience = computed(() => selectedCandidateService.workExperiences);
const education = computed(() => selectedCandidateService.education);
const industries = computed(() => selectedCandidateService.industries);
const knowledgeDisciplines = computed(() => selectedCandidateService.knowledgeDisciplines);
const machinesAndTechnologies = computed(() => selectedCandidateService.machinesAndTechnologies);
const notesWithJobHeaders = computed(() => proNotesService.notesWithJobHeaders);
const currentProject = computed(() => props.project);
const currentProjectJobId = computed(() => currentProject.value?.jobId);
const isAtsSyncEnabled = computed(() => currentProject.value?.remoteJobId !== null);
const showsAtsSyncBtn = computed(() =>
  Boolean(
    isUnlocked.value && !hasPPCSelection.value && isAtsSyncEnabled.value && !hasAlreadySynced.value,
  ),
);
const showCreditsOverusageWarning = computed(() =>
  Boolean(
    !isUnlocked.value &&
      subscriptionService.usedTalentSearchCredits >= subscriptionService.talentSearchCredits,
  ),
);

const redirectToConversations = async () => {
  TrackingService.instance.trackActionEvent(TrackingActionName.SOURCING_CONVERSATION_VIEWED, {
    source: 'PPP',
  });
  router.push({
    name: 'conversation-tab',
    query: {
      conversationId: conversationId.value,
      isDeepLink: 1,
      isComingFromSearch: 1,
    },
  });
};
const unlockProUserProfile = async () => {
  try {
    selectedCandidateService.isFetchingCandidate = true;
    const userProfileIdToUnlock = candidate.value?.id;
    if (!userProfileIdToUnlock) return;

    const subscriptionService = new SubscriptionService();
    const shouldDisplayTrialModal = subscriptionService.shouldDisplayTrialModal({
      unlockCount: 1,
    });
    if (shouldDisplayTrialModal) {
      messagingService.setIsTrialUpgradeModalVisible({
        visible: true,
        source: ProUnlockSource.PPP,
      });
      return;
    }
    await proUnlockService.unlockProUserProfiles([userProfileIdToUnlock], ProUnlockSource.PPP);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Could not unlock user profile');
  } finally {
    selectedCandidateService.isFetchingCandidate = false;
    trackUnlock();
  }
};

function trackUnlock() {
  TrackingService.instance.trackActionEvent(TrackingActionName.UNLOCK, {
    recently_active: isProRecentlyActive(selectedCandidateService.candidate?.lastActiveTs),
  });
}
const openMessaging = async () => {
  try {
    const userProfileIdToUnlock = candidate.value?.id;
    if (!userProfileIdToUnlock) return;

    messagingService.selectedProUserProfiles = [];
    await messagingService.addProUserProfileToSelectedList({
      profileId: userProfileIdToUnlock.toString(),
      nameFirst: candidate.value?.nameFirst ?? '',
      nameLast: candidate.value?.nameLast ?? '',
      unlocked: candidate.value?.unlocked,
      lastActiveTs: candidate.value?.lastActiveTs ?? '',
      source: MessagingSource.PPP,
    });
    emit('open:messaging', true);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Could not unlock user profile');
  }
};

function openResumeDialog() {
  isResumeDialogOpened.value = true;
}

function handleResumeError() {
  isResumeDialogOpened.value = false;
  SnackbarService.critical('Error loading resume. Please try again later.');
}

async function syncJobApplicant() {
  try {
    isSyncingApplication.value = true;
    const selectedCandidate = candidate.value;
    const candidateUserProfileId = Number(selectedCandidate?.pro.userProfileId);
    const jobId = Number(currentProjectJobId.value);
    if (
      !isNaN(candidateUserProfileId) &&
      candidateUserProfileId > 0 &&
      !isNaN(jobId) &&
      jobId > 0
    ) {
      await atsSyncService.syncJobApplicant(candidateUserProfileId, jobId);
      // Show loader for a few seconds to give the user feedback
      await new Promise((resolve) => setTimeout(resolve, 2000));
      hasAlreadySynced.value = true;
    }
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Could not sync with ats. Please try again later.');
  } finally {
    isSyncingApplication.value = false;
  }
}

watch(candidate, async (newVal) => {
  if (newVal) {
    try {
      isFetchingSyncApplication.value = true;
      const selectedCandidateProfileId = Number(newVal.pro.userProfileId);
      const applications = await atsSyncService.searchSyncedJobApplicants({
        ffUserProfileIds: [selectedCandidateProfileId],
        ffJobId: Number(currentProjectJobId.value),
      });

      // If the candidate has already synced with the job, we should not show the sync button
      hasAlreadySynced.value = applications.results.length > 0;
    } catch (error) {
      hasAlreadySynced.value = false;
      ErrorService.captureException(error);
      SnackbarService.critical('Failed to fetch candidate sync application');
    } finally {
      isFetchingSyncApplication.value = false;
    }
  }
});
</script>

<template>
  <div
    class="flex flex-col overflow-auto px-3 py-5"
    :class="!candidate ? 'h-full items-center justify-center' : ''"
  >
    <!-- Loading candidate -->
    <template v-if="isFetchingCandidate">
      <div class="pa-4 w-full">
        <v-skeleton-loader type="heading" class="my-4 mb-10" />
        <v-skeleton-loader v-for="i in 7" :key="i" type="text, list-item-two-line" class="my-4" />
      </div>
    </template>

    <!-- No candidate selected -->
    <template v-else-if="!candidate">
      <p class="text-shade-600">Select a candidate to view details</p>
    </template>

    <!-- Candidate info -->
    <template v-else>
      <ContactInfo
        :candidate="candidate"
        :is-unlocked="isUnlocked"
        @click:view-resume="openResumeDialog"
      />
      <ResumeDialog
        v-model="isResumeDialogOpened"
        :user-profile="candidate"
        @rendering-failed="handleResumeError"
        @loading-failed="handleResumeError"
      />
      <PanelDivider />
      <WorkExperiencePanel :work-experience="workExperience" />
      <PanelDivider />
      <EducationPanel :education="education" />
      <PanelDivider />
      <SimpleExpansionPanel panel-title="Industries" :items="industries" />
      <PanelDivider />
      <SimpleExpansionPanel panel-title="Knowledge Disciplines" :items="knowledgeDisciplines" />
      <PanelDivider />
      <SimpleExpansionPanel
        panel-title="Machines / Technologies"
        :items="machinesAndTechnologies"
      />
      <PanelDivider />
      <NotesPanel
        :notes="notesWithJobHeaders"
        :class="[showCreditsOverusageWarning ? 'mb-[85px]' : 'mb-[65px]']"
      />

      <div
        class="absolute bottom-0 left-0 flex w-full items-center justify-center border-t border-tint-40 bg-white px-5 py-3"
      >
        <template v-if="isUnlocked">
          <!-- ATS Sync btn -->
          <div
            class="relative"
            :class="{
              'w-1/2 pr-1': showsAtsSyncBtn,
              hidden: !showsAtsSyncBtn,
            }"
          >
            <PanelActionButton
              v-if="showsAtsSyncBtn"
              variant="Sync to ATS"
              :loading="isSyncingApplication"
              @click="syncJobApplicant"
            />
          </div>

          <!-- Message Btns -->
          <div
            class="relative"
            :class="{
              'w-1/2 pl-1': showsAtsSyncBtn,
              'w-full': !showsAtsSyncBtn,
            }"
          >
            <PanelActionButton
              v-if="conversationId"
              variant="View conversation"
              :loading="isFetchingCandidate"
              :override-class="{
                '!text-xs': showsAtsSyncBtn,
              }"
              @click="redirectToConversations"
            />
            <PanelActionButton
              v-else-if="!hasPPCSelection"
              variant="Send message"
              :loading="isFetchingCandidate"
              @click="openMessaging"
            />
          </div>
        </template>

        <template v-else>
          <div class="flex w-full flex-col gap-2">
            <PanelActionButton
              variant="Unlock"
              :loading="isFetchingCandidate"
              @click="unlockProUserProfile"
            />
            <div class="text-center text-xs text-caution-600" v-if="showCreditsOverusageWarning">
              <p class="font-bold">You've reached your unlock limit for the billing period.</p>
              <p>You'll be charged to message this candidate.</p>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<style scoped></style>
