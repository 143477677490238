<script setup lang="ts">
import ListChip from '@/components/Shared/Input/ListChip/ListChip.vue';

defineProps<{
  modelValue?: number;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value?: number): void;
}>();

const items = [
  { id: 7, value: '7 days' },
  { id: 30, value: '30 days' },
  { id: 60, value: '60 days' },
  { id: 90, value: '90 days' },
  { id: 365, value: '1 year' },
];
</script>

<template>
  <ListChip
    label="Last Active"
    :model-value="modelValue"
    :items="items"
    @update:model-value="emit('update:modelValue', $event)"
    @click:delete="emit('update:modelValue', undefined)"
  />
</template>
