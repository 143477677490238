<script setup lang="ts">
import TaxonomyService from '@/core/shared/taxonomy/taxonomy.service';
import SearchableListChip from '@/components/Shared/Input/SearchableListChip/SearchableListChip.vue';

defineProps<{
  modelValue?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value?: number | string): void;
}>();

const taxonomyService = new TaxonomyService();
</script>

<template>
  <SearchableListChip
    label="Skills"
    placeholder="Search skill.."
    item-id-key="title"
    item-title-key="title"
    :model-value="modelValue"
    :items="taxonomyService.knowledgeDisciplines"
    @update:model-value="emit('update:modelValue', $event)"
    @click:delete="emit('update:modelValue', undefined)"
  />
</template>
