<script setup lang="ts">
import rocketWhite from '@/assets/svg/sourcing/rocket-white.svg';
import { computed } from 'vue';

const props = defineProps({
  mini: {
    type: Boolean,
    default: false,
  },
});

const isMiniVersion = computed(() => props.mini);
</script>

<template>
  <div
    :class="`flex items-center justify-center rounded bg-gradient-to-r from-highlight-600 to-[#AE35F7] p-1 text-[10px] font-bold leading-[10px] text-white`"
  >
    <span v-show="!isMiniVersion" class="font-sans"> COPILOT </span>
    <img
      v-show="isMiniVersion"
      :src="rocketWhite"
      class="inline-block"
      :class="{
        'ml-1': !isMiniVersion,
      }"
      height="16"
      width="16"
    />
  </div>
</template>
