export function findLastIndex<T>(
  array: Array<T>,
  searchKey: keyof T,
  searchValue: string | number,
): number {
  const index = array
    .slice()
    .reverse()
    .findIndex((x) => x[searchKey] === searchValue);
  const count = array.length - 1;
  const finalIndex = index >= 0 ? count - index : index;
  return finalIndex;
}
