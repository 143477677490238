<script setup lang="ts">
import Btn from '@/components/Sourcing/Btn/Btn.vue';
import FilterChip from '@/components/Sourcing/Filters/FilterChip/FilterChip.vue';
import { watchEffect } from 'vue';
import { onMounted, ref } from 'vue';

const props = defineProps<{
  modelValue?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value?: string): void;
}>();

const isMenuVisible = ref(false);
const inputValue = ref<string>();

function handleApply() {
  isMenuVisible.value = false;
  emit('update:modelValue', inputValue.value);
}

function handleMenuStateChange(value: boolean) {
  isMenuVisible.value = value;

  if (value === false) {
    emit('update:modelValue', inputValue.value);
  }
}

watchEffect(() => {
  // Set the input value to the model value when the menu is opened
  if (isMenuVisible.value === true) {
    inputValue.value = props.modelValue;
  }
});

onMounted(() => {
  // Open the menu on mount if the value is empty
  if (!props.modelValue) {
    isMenuVisible.value = true;
  }
});
</script>

<template>
  <v-menu
    :model-value="isMenuVisible"
    transition="slide-y-transition"
    :close-on-content-click="false"
    @update:model-value="handleMenuStateChange"
  >
    <template #activator="{ props }">
      <FilterChip
        v-bind="props"
        label="Keyword"
        :value="modelValue"
        deletable
        @click:delete="emit('update:modelValue', undefined)"
        info="You can run a Boolean search on FactoryFix by combining keywords with
          operators like AND, NOT, and OR during your search."
      />
    </template>

    <div
      :class="[
        'border-1',
        'flex',
        'flex-col',
        'rounded-md',
        'bg-white',
        'w-[260px]',
        'shadow-menu',
      ]"
    >
      <div class="p-2">
        <v-text-field
          v-model="inputValue"
          placeholder="Search profile for keyword(s)"
          variant="outlined"
          density="compact"
          hide-details
          autofocus
          @keyup.enter="handleApply"
        />
      </div>

      <div class="flex items-center justify-center px-4 py-2">
        <Btn size="normal" @click="handleApply">Apply</Btn>
      </div>
    </div>
  </v-menu>
</template>
