import { defineStore } from 'pinia';
import type { SelectedGatedUserProfile } from '@/core/sourcing/messaging/types/selected-gated-user-profile.type';
import type { ProUnlockSource } from '@/core/sourcing/pro-unlock/types/pro-unlock-source.type';
import { ref } from 'vue';

export const useMessagingStore = defineStore('sourcing-messaging', () => {
  const selectedProUserProfiles = ref<SelectedGatedUserProfile[]>([]);
  const isUpgradeModalVisible = ref<boolean>(false);
  const proUnlockSource = ref<ProUnlockSource>();

  return {
    selectedProUserProfiles,
    isUpgradeModalVisible,
    proUnlockSource,
  };
});
