<script setup lang="ts">
import MultiselectListChip from '@/components/Shared/Input/MultiselectListChip/MultiselectListChip.vue';

defineProps<{
  modelValue?: number[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value?: number[]): void;
}>();

const items = [
  { id: 1, value: 'Under 1 year' },
  { id: 2, value: '1-2 years' },
  { id: 3, value: '3-5 years' },
  { id: 4, value: '6-10 years' },
  { id: 5, value: '+10 years' },
];
</script>

<template>
  <MultiselectListChip
    label="Years of Experience"
    :model-value="modelValue"
    :items="items"
    @update:model-value="emit('update:modelValue', $event)"
    @click:delete="emit('update:modelValue', undefined)"
  />
</template>
