<script setup lang="ts">
import { computed, onMounted } from 'vue';

import ProNotesService from '@/core/shared/pro-notes/pro-notes.service';
import Note from '@/components/Conversations/Notes/Note.vue';
import EmptyNote from '@/components/Conversations/Notes/EmptyNote.vue';
import CreateNote from '@/components/Conversations/Notes/CreateNote.vue';
import ProProfileService from '@/core/conversations/pro-profile/pro-profile.service';

const proNoteService = new ProNotesService();
const proProfileService = new ProProfileService();

const proId = computed(() => proProfileService.selectedProProfile?.pro.id);

onMounted(async () => {
  if (proId.value) {
    await proNoteService.fetchProNotes(proId.value);
  }
});
</script>

<template>
  <div class="flex h-full max-h-full w-full max-w-full flex-col overflow-auto px-4 pt-6">
    <CreateNote :pro-id="proId" />
    <template v-if="proNoteService.notesWithJobHeaders.length > 0">
      <div class="mt-6 space-y-6">
        <div v-for="note of proNoteService.notesWithJobHeaders" :key="note.id">
          <Note :note="note" />
        </div>
      </div>
    </template>
    <template v-else>
      <EmptyNote />
    </template>
  </div>
</template>
