<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  light?: boolean;
}>();

const isLight = computed(() => props.light);
const bgColor = computed(() => (isLight.value ? 'bg-tint-40' : 'bg-shade-600'));
const textColor = computed(() => (isLight.value ? 'text-shade-800' : 'text-shade-860'));
</script>

<template>
  <div :class="`rounded ${bgColor} ${textColor} p-1 text-[10px] font-bold leading-[10px]`">
    ARCHIVED
  </div>
</template>
