<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

import TemplateCard from '@/components/Conversations/Templates/TemplateCard.vue';
import TemplateService from '@/core/conversations/template/template.service';
import CreateTemplateButton from '@/components/Conversations/Templates/CreateTemplateButton.vue';
import CreateTemplate from '@/components/Conversations/Templates/CreateTemplate.vue';
import EditTemplate from '@/components/Conversations/Templates/EditTemplate.vue';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';

const emit = defineEmits(['sendTemplateMessage']);
const templateService = new TemplateService();

const creationMode = ref(false);
const editMode = ref(false);
const templateToEdit = ref({ id: 0, title: '', message: '' });
const isScreenSmall = ref(window.innerWidth < 960);

const shouldShowTemplatesModal = computed(() => templateService.showTemplateModal);
const templates = computed(() => templateService.templates);

async function closeTemplatesModal() {
  creationMode.value = false;
  editMode.value = false;
  templateService.showTemplateModal = false;
}

const selectTemplate = (templateId: number) => {
  const templateSelected = templateService.templates.find((template) => template.id === templateId);
  if (templateSelected) {
    templateService.selectedTemplate = templateSelected;
    templateService.showTemplateModal = false;
  }
};

const sendTemplate = async (templateId: number) => {
  const templateSelected = templateService.templates.find((template) => template.id === templateId);
  if (templateSelected) {
    const formattedMessage = templateService.mapTemplatePlaceholders(templateSelected.message);
    emit('sendTemplateMessage', formattedMessage);
    templateService.showTemplateModal = false;
  }
};

const saveTemplate = async (template: { title: string; message: string }) => {
  await templateService.createTemplate(template);
  setCreationMode(false);
};

const editTemplate = async (templateId: number) => {
  const templateSelected = templateService.templates.find((template) => template.id === templateId);

  if (templateSelected) {
    templateToEdit.value = {
      id: templateSelected.id,
      title: templateSelected.title,
      message: templateSelected.message,
    };
    editMode.value = true;
  }
};

const updateTemplate = async (template: { id: number; title: string; message: string }) => {
  await templateService.updateTemplate(template);
  SnackbarService.showSnackbar({
    text: 'Template was edited',
    appendIcon: 'close',
    prependIcon: 'pen',
    showUndo: true,
    horizontalPosition: 'center',
    undoAction: () => restoreEditedTemplate(),
  });
  editMode.value = false;
};

const restoreEditedTemplate = async () => {
  const oldTemplate = {
    id: templateToEdit.value.id,
    title: templateToEdit.value.title,
    message: templateToEdit.value.message,
  };
  await templateService.updateTemplate(oldTemplate);
};

const restoreTemplate = async (templateId: number) => {
  await templateService.restoreTemplate(templateId);
};

const deleteTemplate = async (templateId: number) => {
  await templateService.deleteTemplate(templateId);
  SnackbarService.showSnackbar({
    text: 'Template deleted',
    appendIcon: 'close',
    prependIcon: 'trash',
    showUndo: true,
    horizontalPosition: 'center',
    undoAction: () => restoreTemplate(templateId),
  });
};

const setCreationMode = (mode: boolean) => {
  creationMode.value = mode;
};

const setEditMode = (mode: boolean) => {
  editMode.value = mode;
};

const handleWindowSizeChange = () => {
  isScreenSmall.value = window.innerWidth < 960;
};

onMounted(() => {
  window.addEventListener('resize', handleWindowSizeChange);
  templateService.fetchTemplates();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleWindowSizeChange);
});
</script>

<template>
  <v-dialog
    :class="[isScreenSmall ? 'w-[600px]' : 'w-[960px]']"
    :model-value="shouldShowTemplatesModal"
    persistent
  >
    <div class="modal-content" :class="[isScreenSmall ? 'w-[600px]' : 'w-[960px]']">
      <section class="relative flex-grow overflow-y-auto pl-8 pt-6">
        <img
          src="@/assets/svg/close-black.svg"
          class="absolute right-0 top-0 mr-6 mt-8 h-6 w-6 cursor-pointer"
          alt="Close"
          @click="closeTemplatesModal"
        />
        <h2 class="font-serif text-2xl font-black text-copilot">Use a template</h2>
        <p class="mt-2 text-base text-shade-800">
          Quickly message candidates using pre-made templates or create your own.
        </p>
        <template v-if="creationMode">
          <CreateTemplate
            @cancel-creation="setCreationMode(false)"
            class="mt-8 pr-8"
            @save-template="saveTemplate"
          />
        </template>
        <template v-if="editMode">
          <EditTemplate
            :id="templateToEdit.id"
            :title="templateToEdit.title"
            :templateText="templateToEdit.message"
            @cancel-edition="setEditMode(false)"
            class="mt-8 pr-8"
            @update-template="updateTemplate"
          />
        </template>
        <template v-if="!creationMode && !editMode">
          <CreateTemplateButton class="mt-4" @create-template="setCreationMode(true)" />
          <div class="templates-scrollbar">
            <template-card
              v-for="template in templates"
              :key="template.id"
              :id="template.id"
              :title="template.title"
              :custom="template.type !== 'FactoryFix'"
              :templateText="template.message"
              @insert-template="selectTemplate"
              @send-template="sendTemplate"
              @edit-template="editTemplate"
              @delete-template="deleteTemplate"
              @restore-template="restoreTemplate"
            />
          </div>
        </template>
      </section>
    </div>
  </v-dialog>
</template>

<style>
.modal-content {
  @apply flex h-[674px] flex-col rounded-xl bg-white pb-2.5 shadow-menu;
}
</style>

<style scoped>
.templates-scrollbar {
  @apply mr-2 mt-8 grid h-[464px] grid-cols-2 gap-6 overflow-auto pr-4;
}

/** Scrollbar width */
.templates-scrollbar::-webkit-scrollbar {
  width: 8px;
}

/** Scrollbar color and border radius */
.templates-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-tint-80;
  border-radius: 8px;
}
</style>
