<script setup lang="ts">
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ProPreviewStatsService } from '@/core/sourcing/pro-preview-stats/pro-preview-stats.service';
import { computed } from 'vue';
import type { SearchGatedUserProfile } from '@/core/sourcing/search/types/search-gated-user-profile.type';

dayjs.extend(relativeTime);

const props = defineProps<{
  pro: SearchGatedUserProfile;
  viewedOn?: number;
}>();

const proPreviewStatsService = new ProPreviewStatsService();

const hasLoadedStats = computed(() => proPreviewStatsService.statsRequestStatus === 'loaded');
const viewedOnLabel = computed(() => getViewedOnStatus(props.viewedOn));
const isUnlocked = computed(() => props.pro.unlocked);

const engagementLabel = computed(() => {
  if (!hasLoadedStats.value || !isUnlocked.value) {
    return;
  }

  const stats = proPreviewStatsService.statsByProId[props.pro.id];

  if (!stats?.engagementStats?.lastOccurredAt) {
    return;
  }

  return `Engaged ${dayjs(stats.engagementStats.lastOccurredAt).fromNow()}`;
});

const notesLabel = computed(() => {
  if (!hasLoadedStats.value) {
    return;
  }

  const stats = proPreviewStatsService.statsByProId[props.pro.id];

  if (!stats?.noteStats?.count) {
    return;
  }

  return `${stats.noteStats.count} note${stats.noteStats.count === 1 ? '' : 's'}`;
});

const labels = computed(() => {
  const labels: string[] = [];

  for (const label of [viewedOnLabel.value, engagementLabel.value, notesLabel.value]) {
    if (label) {
      if (labels[labels.length - 1]) {
        labels.push('•');
      }
      labels.push(label);
    }
  }

  return labels.join(' ');
});

function getViewedOnStatus(viewedOn?: number): string | undefined {
  if (!viewedOn) {
    return;
  }
  const now = dayjs();
  const viewedOnDate = dayjs(viewedOn);
  let epoch = '';
  const diffSeconds = now.diff(viewedOnDate, 'second');
  if (diffSeconds < 60) {
    epoch = `${diffSeconds} second${getEpochPluralize(diffSeconds)} ago`;
  } else if (diffSeconds < 3600) {
    epoch = `${Math.floor(diffSeconds / 60)} minute${getEpochPluralize(
      Math.floor(diffSeconds / 60),
    )} ago`;
  } else if (diffSeconds < 86400) {
    epoch = `${Math.floor(diffSeconds / 3600)} hour${getEpochPluralize(
      Math.floor(diffSeconds / 3600),
    )} ago`;
  } else {
    epoch = `on ${viewedOnDate.format('MM/DD/YYYY')}`;
  }
  return 'Viewed ' + epoch;
}

function getEpochPluralize(count: number): string {
  return count === 1 ? '' : 's';
}
</script>

<template>
  <div class="text-xs leading-4 text-shade-800" data-test-id="pro-preview-stats">
    <span>{{ labels }}</span>
  </div>
</template>
