<script lang="ts" setup>
import ProProfileService from '@/core/conversations/pro-profile/pro-profile.service';
import { formatPhoneNumber } from '@/utils/strings/phone-number.util';
import { computed } from 'vue';

const props = withDefaults(defineProps<{ fullWindow: boolean }>(), {
  fullWindow: false,
});

const proProfileService = new ProProfileService();

const userProfile = computed(() => proProfileService.selectedProProfile);
const phoneNumber = computed(() => formatPhoneNumber(userProfile.value?.phone));
</script>

<template>
  <p class="text-sm text-shade-820">
    Candidate has opted out of messaging. <br v-if="props.fullWindow" />
    You can reach out to them at
    <template v-if="phoneNumber"
      ><span class="contactInfo"> {{ phoneNumber }} </span> or
    </template>
    <span class="contactInfo">{{ userProfile?.email }}</span>
  </p>
</template>

<style lang="postcss" scoped>
.contactInfo {
  @apply text-highlight-600;
}
</style>
