<script setup lang="ts">
import MarkerPinSvg from '@/assets/svg/marker-pin-20.svg?component';
import FileSearchSvg from '@/assets/svg/file-search-20.svg?component';
import ToolSvg from '@/assets/svg/tool-20.svg?component';
import WorkHistorySvg from '@/assets/svg/work-history-20.svg?component';
import BriefcaseSvg from '@/assets/svg/briefcase-20.svg?component';
import WarmCandidateSvg from '@/assets/svg/warm-candidate-20.svg?component';
import LockUnlockedSvg from '@/assets/svg/lock-unlocked-20.svg?component';
import ProPreviewStats from '@/components/Sourcing/ProPreviewCard/ProPreviewStats.vue';
import Checkbox from '@/components/Shared/Input/Checkbox/Checkbox.vue';
import SubscriptionService from '@/core/shared/subscription/subscription.service';
import type { SearchGatedUserProfile } from '@/core/sourcing/search/types/search-gated-user-profile.type';
import { computed } from 'vue';
import { MessagingService } from '@/core/sourcing/messaging/messaging.service';
import { MessagingSource } from '@/core/sourcing/messaging/types/messaging-source.type';
import { ProUnlockSource } from '@/core/sourcing/pro-unlock/types/pro-unlock-source.type';
import { ViewedCandidateService } from '@/core/sourcing/viewed-candidates/viewed-candidate.service';
import { isProRecentlyActive } from '@/core/sourcing/utils/dates';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { getNameForCandidate } from '@/core/sourcing/search/utils/get-name-for-candidate.util';

const props = defineProps<{
  pro: SearchGatedUserProfile;
  selectedProId?: number;
}>();

const subscriptionService = new SubscriptionService();
const messagingService = new MessagingService();
const viewedCandidateService = new ViewedCandidateService();
const isUnlocked = computed(() => props.pro.unlocked);

const isSelectedCandidate = computed(() => {
  return Number(props.pro.profileId) === props.selectedProId;
});

const isUnlockedIconVisible = computed(
  () => isUnlocked.value && subscriptionService.isGatedSubscription,
);

const viewedOn = computed(
  () => viewedCandidateService.viewedCandidatesRecord?.[Number(props.pro.id)],
);

const isWarm = computed(() => {
  const lastActiveTs = props.pro.lastActiveTs;

  if (!lastActiveTs) {
    return false;
  }

  return isProRecentlyActive(lastActiveTs);
});
const highlightedSkills = computed(() => {
  if (!props.pro?._highlight?.skills) {
    return '';
  }

  return props.pro._highlight?.skills?.map((skill) => skill.value).join(', ');
});

const highlightedWorkExperience = computed(() => {
  if (!props.pro?._highlight?.workExperience) {
    return [];
  }
  return props.pro._highlight?.workExperience.filter((experience) => !!experience.jobTitle?.value);
});

const highlightedWorkExperienceList = computed(() => {
  if (!highlightedWorkExperience.value.length) {
    return '';
  }

  /**
   * We are displaying the first element in the work experience as the last
   * employment, the remainder elements will be displayed as a comma separated
   * list of job titles
   */
  const workExperienceList = highlightedWorkExperience.value.slice(1);

  return workExperienceList
    .map((experience) => experience.jobTitle?.value)
    .filter(Boolean)
    .join(', ');
});

const highlightedName = computed(() => {
  const nameFirst = props.pro?._highlight?.nameFirst?.value ?? '';
  const nameLast = props.pro?._highlight?.nameLast?.value ?? '';
  const highlightedName = [nameFirst, nameLast].filter(Boolean).join(' ').trim();

  return highlightedName || getNameForCandidate(props.pro);
});

const highlightedCurrentPosition = computed(() => {
  let highlightedCurrentPosition = '';

  if (!highlightedWorkExperience.value.length) {
    return highlightedCurrentPosition;
  }

  /**
   * We are displaying the first element in the work experience as the
   * last employment information.
   */
  const lastWorkExperience = highlightedWorkExperience.value[0];
  const historyJobTitle = lastWorkExperience.jobTitle?.value;
  const historyEmployerName = lastWorkExperience.employer?.value;

  if (historyJobTitle) {
    highlightedCurrentPosition += `${historyJobTitle}`;

    if (historyEmployerName) {
      highlightedCurrentPosition += ` at ${historyEmployerName}`;
    }
  }

  return highlightedCurrentPosition;
});

const highlightedResumeText = computed(() => {
  let resumeText = '';

  resumeText = props.pro?._highlight?.resumeText?.snippet ?? '';

  if (!resumeText) {
    return '';
  }

  const parsedResumeText = resumeText.replace(/●/g, `<span class="text-3xs">●</span>`);

  return `...${parsedResumeText}...`;
});

const isSelectedForBulkMessaging = computed(() => {
  return messagingService.selectedPPCUserProfiles.some(
    (userProfile) => userProfile.id === Number(props.pro.profileId),
  );
});

const isAVectorMatch = computed<boolean>(() => {
  return !!props.pro.vector_distance && props.pro.vector_distance < 0.5;
});

const candidatePostalCode = computed(() => getPostalCode(props.pro));

const handleBulkMessagingSelection = async () => {
  try {
    if (isSelectedForBulkMessaging.value) {
      messagingService.removeProFromSelectedUserProfiles(Number(props.pro.profileId));
      return;
    }

    const prosToUnlockCount = messagingService.selectedUserProfilesToUnlockCount;
    const proToUnlock = isUnlocked.value ? 0 : 1;

    const shouldDisplayTrialModal = await subscriptionService.shouldDisplayTrialModal({
      unlockCount: prosToUnlockCount + proToUnlock,
    });

    if (shouldDisplayTrialModal) {
      messagingService.setIsTrialUpgradeModalVisible({
        visible: true,
        source: ProUnlockSource.BULK_MESSAGING,
      });
      return;
    }

    await messagingService.addProUserProfileToSelectedList({
      profileId: props.pro.profileId,
      nameFirst: props.pro.nameFirst,
      nameLast: props.pro.nameLast,
      unlocked: props.pro.unlocked,
      lastActiveTs: props.pro.lastActiveTs,
      source: MessagingSource.PPC,
    });
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to add candidate to bulk messaging');
  }
};

function getPostalCode(pro: SearchGatedUserProfile): string | boolean {
  if (pro.address) {
    return pro.address.postalCode;
  }
  return false;
}
</script>

<template>
  <div
    :id="`pro-preview-card-${pro.profileId}`"
    :class="{
      'shadow-ppc cursor-pointer rounded-xl bg-white p-5': true,
      '-m-0.5 border-2 border-highlight-400': isSelectedCandidate,
      '-m-0.5 border-2 border-highlight-100': isAVectorMatch,
    }"
  >
    <div class="flex justify-between">
      <div class="flex items-center">
        <!-- Multiselect Checkbox -->
        <Checkbox
          :model-value="isSelectedForBulkMessaging"
          @click.stop="handleBulkMessagingSelection"
        />

        <!-- Candidate Name -->
        <span
          data-test-id="pro-name"
          class="ppc_highlighted_text ml-2 text-sm font-bold leading-5"
          v-html="highlightedName"
        />

        <!-- Last Active Indicator -->
        <v-tooltip v-if="isWarm" data-test-id="pro-is-warm" bottom>
          <template #activator>
            <WarmCandidateSvg class="ml-2 text-tint-200" />
          </template>
          <span>Recently active</span>
        </v-tooltip>
      </div>

      <!-- Candidate Unlocked Icon -->
      <div v-if="isUnlockedIconVisible" data-test-id="pro-unlocked-icon">
        <LockUnlockedSvg class="text-tint-200" />
      </div>
    </div>

    <!-- Candidate Stats -->
    <div class="mt-2">
      <ProPreviewStats :pro="pro" :viewed-on="viewedOn" />
    </div>

    <!-- Candidate Details -->
    <div class="mt-6">
      <!-- Current Position -->
      <div v-if="highlightedCurrentPosition" class="flex items-center">
        <aside>
          <BriefcaseSvg class="text-tint-200" />
        </aside>
        <div>
          <div
            class="ppc_highlighted_text ml-4 text-xs font-normal leading-4.5"
            data-test-id="pro-history-job"
            v-html="highlightedCurrentPosition"
          />
        </div>
      </div>

      <!-- Work Experience -->
      <div v-if="highlightedWorkExperienceList" class="mt-3 flex items-center">
        <aside>
          <WorkHistorySvg class="text-tint-200" />
        </aside>
        <div>
          <div
            class="ppc_highlighted_text ml-4 text-xs font-normal leading-4.5"
            data-test-id="pro-work-experience"
            v-html="highlightedWorkExperienceList"
          />
        </div>
      </div>

      <!-- Skills -->
      <div v-if="highlightedSkills" class="mt-3 flex items-center">
        <aside>
          <ToolSvg class="text-tint-200" />
        </aside>
        <div>
          <div
            class="ppc_highlighted_text ml-4 text-xs font-normal leading-4.5"
            data-test-id="pro-skills"
            v-html="highlightedSkills"
          />
        </div>
      </div>

      <!-- Resume Text Snippet -->
      <div v-if="highlightedResumeText" class="mt-3 flex items-center">
        <aside>
          <FileSearchSvg class="text-tint-200" />
        </aside>
        <div>
          <div
            class="ppc_highlighted_text ml-4 text-xs font-normal leading-4.5"
            data-test-id="pro-resume-text"
            v-html="highlightedResumeText"
          />
        </div>
      </div>

      <!-- Location (Postal Code) -->
      <div v-if="candidatePostalCode" class="mt-3 flex items-center">
        <aside>
          <MarkerPinSvg class="text-tint-200" />
        </aside>
        <div>
          <div class="ml-3 text-xs font-normal leading-4.5" data-test-id="pro-location">
            {{ candidatePostalCode }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.shadow-ppc {
  box-shadow:
    0 4px 6px -2px rgba(13, 10, 44, 0.03),
    0 12px 16px -4px rgba(13, 10, 44, 0.03),
    0 1px 2px -1px rgba(13, 10, 44, 0.1);
}
</style>
