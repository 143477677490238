<script setup lang="ts">
import { SearchService } from '@/core/sourcing/search/search.service';
import AddFilterBtn from '@/components/Sourcing/Filters/AddFilterBtn/AddFilterBtn.vue';
import KeywordChip from '../KeywordChip/KeywordChip.vue';
import LocationChip from '../LocationChip/LocationChip.vue';
import DistanceChip from '../DistanceChip/DistanceChip.vue';
import JobTitleChip from '../JobTitleChip/JobTitleChip.vue';
import BrandChip from '../BrandChip/BrandChip.vue';
import SkillChip from '../SkillChip/SkillChip.vue';
import MachineChip from '../MachineChip/MachineChip.vue';
import YearsOfExperienceChip from '../YearsOfExperienceChip/YearsOfExperienceChip.vue';
import LastActiveChip from '../LastActiveChip/LastActiveChip.vue';
import { SelectedFilterType } from '@/core/sourcing/search/types/selected-filter-type.type';
import type { SelectedFilter } from '@/core/sourcing/search/types/selected-filter.type';
import { clone } from '@/utils/objects/clone.util';

const emit = defineEmits<{
  (e: 'change:filter'): void;
}>();

const searchService = new SearchService();

function handleFilterValueChange(filter: SelectedFilter, value?: SelectedFilter['value']) {
  // Ensure the filter is removed if the value is empty
  if (!value || (Array.isArray(value) && !value.length)) {
    searchService.removeFilter(filter.type);

    // If the filter is job title, remove years of experience filter as well
    if (filter.type === SelectedFilterType.JOB_TITLE) {
      searchService.removeFilter(SelectedFilterType.YEARS_OF_EXPERIENCE);
    }
    emit('change:filter');
    return;
  }

  const filterToUpdate = clone(filter);
  filterToUpdate.value = value;
  searchService.updateFilter(filterToUpdate);
  emit('change:filter');
}
</script>

<template>
  <div class="flex flex-wrap items-center">
    <div v-for="filter in searchService.selectedFilters" :key="filter.type" class="my-1 mr-2">
      <KeywordChip
        v-if="filter.type === SelectedFilterType.KEYWORD"
        :model-value="filter.value"
        @update:model-value="handleFilterValueChange(filter, $event)"
      />

      <LocationChip
        v-else-if="filter.type === SelectedFilterType.LOCATION"
        :model-value="filter.value"
        @update:model-value="handleFilterValueChange(filter, $event)"
      />

      <DistanceChip
        v-else-if="filter.type === SelectedFilterType.AROUND_RADIUS"
        :model-value="filter.value"
        @update:model-value="handleFilterValueChange(filter, $event)"
      />

      <JobTitleChip
        v-else-if="filter.type === SelectedFilterType.JOB_TITLE"
        :model-value="filter.value"
        @update:model-value="handleFilterValueChange(filter, $event)"
      />

      <YearsOfExperienceChip
        v-else-if="filter.type === SelectedFilterType.YEARS_OF_EXPERIENCE"
        :model-value="filter.value"
        @update:model-value="handleFilterValueChange(filter, $event)"
      />

      <SkillChip
        v-else-if="filter.type === SelectedFilterType.KNOWLEDGE_DISCIPLINE"
        :model-value="filter.value"
        @update:model-value="handleFilterValueChange(filter, $event)"
      />

      <MachineChip
        v-else-if="filter.type === SelectedFilterType.TECHNOLOGY"
        :model-value="filter.value"
        @update:model-value="handleFilterValueChange(filter, $event)"
      />

      <BrandChip
        v-else-if="filter.type === SelectedFilterType.BRAND"
        :model-value="filter.value"
        @update:model-value="handleFilterValueChange(filter, $event)"
      />

      <LastActiveChip
        v-else-if="filter.type === SelectedFilterType.LAST_ACTIVE"
        :model-value="filter.value"
        @update:model-value="handleFilterValueChange(filter, $event)"
      />
    </div>

    <div class="my-1">
      <AddFilterBtn />
    </div>
  </div>
</template>
