<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import MessageSentAnimation from '@/components/Sourcing/MessageSentAnimation.vue';
import { nextTick } from 'vue';
import { MessagingService } from '@/core/sourcing/messaging/messaging.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import ProjectService from '@/core/shared/project/project.service';

const messagingService = new MessagingService();
const projectService = new ProjectService();

const emit = defineEmits<{
  (e: 'close', value: { messageSent: boolean }): void;
}>();
const props = defineProps<{
  open: boolean;
}>();

const message = ref('');
const messageSentFlag = ref(false);
const showMore = ref(false);
const sendingMessage = ref(false);

const computedOpen = computed(() => props.open);
watch(computedOpen, (open) => {
  if (open) {
    if (recipientsCount.value === 1) showMore.value = true;
  }
});
const selectedPros = computed(() => messagingService.selectedProUserProfiles);
const recipientsCount = computed(() => selectedPros.value.length);
const recipientsCountLabel = computed(() => {
  return `${recipientsCount.value} ` + (recipientsCount.value > 1 ? 'recipients' : 'recipient');
});
const disableSendButton = computed(() => {
  return !message.value || !recipientsCount.value;
});

const sendMessage = async () => {
  try {
    sendingMessage.value = true;
    // this.$mixpanelAnalytics.messageSent({
    //   messengerType: 'talent',
    //   numberOfPros: this.recipientsCount,
    // });
    const jobId = projectService.currentProject?.jobId;
    if (jobId) {
      await messagingService.sendMessage(message.value, jobId);
      messageSentFlag.value = true;
    } else {
      throw new Error('Job ID is not defined');
    }
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Something went wrong. Please try again.');
  } finally {
    sendingMessage.value = false;
  }
};

const close = async ({ messageSent }: { messageSent: boolean }) => {
  emit('close', { messageSent });
  await nextTick();
  message.value = '';
  messagingService.removeNonPPCProsFromSelectedUserProfiles();
  // Wait for close animation to finish
  setTimeout(() => {
    messageSentFlag.value = false;
  }, 500);
};
</script>

<template>
  <v-dialog v-model="computedOpen" width="600" persistent>
    <MessageSentAnimation
      v-if="messageSentFlag"
      @animation:complete="close({ messageSent: true })"
    />
    <div v-else class="rounded bg-white p-5">
      <!-- Title -->
      <div class="mb-5">
        <img
          src="@/assets/svg/close-black.svg"
          class="float-right cursor-pointer"
          @click="close({ messageSent: false })"
        />
        <p class="text-xl">Create Message</p>
      </div>
      <!-- Recipients -->
      <div
        class="flex min-h-[42px] flex-wrap items-center gap-2 rounded border border-[#e2e8f0] bg-tint-20 px-5 py-2 text-sm"
      >
        <span class="mr-2 text-[#9E9E9E]">To</span>
        <template v-if="showMore">
          <span
            v-for="{ fullName, id } in selectedPros"
            :key="id"
            class="inline-flex h-6 items-center rounded-full bg-[#ECEAFD] px-3 py-1"
          >
            {{ fullName }}
            <img
              src="@/assets/svg/close-black.svg"
              class="ml-2 h-4 w-4 cursor-pointer"
              @click="messagingService.removeProFromSelectedUserProfiles(id)"
            />
          </span>
        </template>
        <span v-else class="">{{ recipientsCountLabel }}</span>
        <span
          class="ml-1 cursor-pointer text-[#5f30d1]"
          @keyup.enter="showMore = !showMore"
          @click.stop="showMore = !showMore"
          >{{ showMore ? 'View Less' : 'View All' }}</span
        >
      </div>
      <!-- Textarea -->
      <div class="mt-5">
        <textarea
          v-model="message"
          class="text-area w-full rounded bg-tint-20 px-5 py-2"
          rows="5"
          placeholder="Type in your message"
          :disabled="sendingMessage"
        ></textarea>
      </div>
      <!-- Actions -->
      <div class="mt-3 flex justify-end">
        <button
          class="w-[160px] rounded-md bg-highlight-600 px-3 py-2 text-sm font-bold leading-5 text-white"
          :loading="sendingMessage"
          :disabled="disableSendButton"
          @click="sendMessage"
        >
          Send text message
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<style scoped>
.text-area {
  border: 1px solid #e2e8f0;
}
</style>
