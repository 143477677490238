<script setup lang="ts">
import { type PropType, computed, ref } from 'vue';

import StagedMessageCard from '../StagedMessageCard/StagedMessageCard.vue';
import GradientChevronSvg from '@/assets/svg/conversations/gradient-chevron.svg';
import ChevronIcon from '@/assets/svg/conversations/ChevronIcon.svg?component';
import CopilotBubbleSvg from '@/assets/svg/copilot-bubble.svg';

const props = defineProps({
  messages: Array as PropType<Array<{ content: string }>>,
});

const emits = defineEmits<{
  (e: 'click:hide'): void;
  (e: 'click:insert', message: string): void;
}>();

const viewingMessageIndex = ref(1);

const displayingMessages = computed(() => {
  const messages = [];

  if (Array.isArray(props.messages) && props.messages.length > 0) {
    let messageIndex = 1;
    for (const message of props.messages) {
      messages.push({
        ...message,
        index: messageIndex,
        isSelected: messageIndex === viewingMessageIndex.value,
        isBeforeSelected: messageIndex < viewingMessageIndex.value,
        isAfterSelected: messageIndex > viewingMessageIndex.value,
        isFirst: messageIndex === 1,
        isLast: messageIndex === props.messages.length,
        isHidden:
          messageIndex < viewingMessageIndex.value - 1 ||
          messageIndex > viewingMessageIndex.value + 1,
      });

      messageIndex += 1;
    }
  }

  return messages;
});

const messageCount = computed(() => displayingMessages.value.length);

const hasNextMessage = computed(() => {
  return viewingMessageIndex.value < messageCount.value;
});
const hasPreviousMessage = computed(() => viewingMessageIndex.value > 1);

function hideCarrousel() {
  emits('click:hide');
}

function selectPreviousMessage() {
  if (hasPreviousMessage.value) {
    viewingMessageIndex.value -= 1;
  }
}

function selectNextMessage() {
  if (hasNextMessage.value) {
    viewingMessageIndex.value += 1;
  }
}

function handleInsertMessage(index: number) {
  const message = displayingMessages.value.find((m) => m.index === index);
  emits('click:insert', `${message?.content}`);
}
</script>

<template>
  <div>
    <div
      class="relative h-[220px] overflow-hidden rounded-md border border-solid border-highlight-50 bg-highlight-0"
    >
      <!-- Suggestions Title -->
      <div
        class="suggestion-title-pill absolute left-3 top-3 flex items-center rounded-2xl py-0.5 pl-0.5 pr-2"
      >
        <img :src="CopilotBubbleSvg" />
        <span class="ml-1 font-sans text-xs font-bold text-highlight-600">
          Message Suggestions
        </span>
      </div>

      <!-- Actions -->
      <div class="absolute right-3 top-3">
        <div class="flex h-6 items-center">
          <p
            class="mb-0 cursor-pointer text-[10px] font-normal leading-4 text-shade-820"
            @click="hideCarrousel"
          >
            Hide suggestions
          </p>
          <!-- Previous Message Icon -->
          <img
            v-if="hasPreviousMessage"
            :src="GradientChevronSvg"
            class="ml-2 h-6 w-6 rotate-90 cursor-pointer"
            @click="selectPreviousMessage"
          />
          <ChevronIcon v-if="!hasPreviousMessage" class="ml-2 rotate-90 text-tint-80" />
          <!-- Next Message Icon -->
          <img
            v-if="hasNextMessage"
            :src="GradientChevronSvg"
            class="ml-2 h-6 w-6 rotate-[270deg] cursor-pointer"
            @click="selectNextMessage"
          />
          <ChevronIcon v-if="!hasNextMessage" class="ml-2 rotate-[270deg] text-tint-80" />
        </div>
      </div>

      <!-- Messages -->
      <StagedMessageCard
        v-for="message in displayingMessages"
        :key="message.index"
        :message="message.content"
        :index="message.index"
        :total-count="messageCount"
        class="absolute bottom-2 w-full transition-all delay-75 ease-in-out"
        :class="{
          'left-[calc(100px-100%)]': message.isBeforeSelected,
          'left-[108px] !w-[calc(100%-216px)]':
            message.isSelected && !message.isFirst && !message.isLast,
          'left-2 !w-[calc(100%-116px)]': message.isSelected && message.isFirst,
          'left-[108px] !w-[calc(100%-116px)]': message.isSelected && message.isLast,
          'left-[calc(100%-100px)]': message.isAfterSelected,
          hidden: message.isHidden,
        }"
        @click:insert="handleInsertMessage"
      />
    </div>
  </div>
</template>

<style scoped>
.suggestion-title-pill {
  background: radial-gradient(
    100.46% 973.3% at 0% 50%,
    rgba(95, 48, 209, 0.1) 0%,
    rgba(179, 54, 255, 0.1) 100%
  );
}
</style>
