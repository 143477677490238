<script setup lang="ts">
import Btn from '@/components/Sourcing/Btn/Btn.vue';
import { debounce } from 'radash';
import FilterChip from '@/components/Sourcing/Filters/FilterChip/FilterChip.vue';
import { SearchService } from '@/core/sourcing/search/search.service';
import type { PlacePrediction } from '@/core/sourcing/search/types/place-prediction.type';
import { watch, watchEffect } from 'vue';
import { onMounted, ref } from 'vue';

const rules = {
  required: (value: string) => !!value,
};

const props = defineProps<{
  modelValue: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const searchService = new SearchService();
const isMenuVisible = ref(false);
const inputValue = ref<string>(props.modelValue);
const isLoading = ref(false);
const items = ref<PlacePrediction[]>([]);
const populateAddressPredictions = debounce({ delay: 300 }, _populateAddressPredictions);

function handleSelectedValue(value: string) {
  isMenuVisible.value = false;
  if (value) {
    emit('update:modelValue', value);
  }
}

function handleMenuStateChange(value: boolean) {
  isMenuVisible.value = value;
}

async function _populateAddressPredictions(address: string) {
  isLoading.value = true;
  items.value = await searchService.getAddressPredictions(address);
  isLoading.value = false;
}

watchEffect(() => {
  // Set the input value to the model value when the menu is opened
  if (isMenuVisible.value === true) {
    inputValue.value = props.modelValue;
  }
});

watch(inputValue, async (value) => {
  if (!value || typeof value !== 'string') {
    return;
  }

  await populateAddressPredictions(value);
});

onMounted(() => {
  // Open the menu on mount if the value is empty
  if (!props.modelValue) {
    isMenuVisible.value = true;
  }
});
</script>

<template>
  <v-menu
    :model-value="isMenuVisible"
    :close-on-content-click="false"
    transition="slide-y-transition"
    @update:model-value="handleMenuStateChange"
  >
    <template #activator="{ props }">
      <FilterChip v-bind="props" label="Located" :value="modelValue" />
    </template>

    <div
      :class="[
        'border-1',
        'flex',
        'flex-col',
        'rounded-md',
        'bg-white',
        'w-[260px]',
        'max-h-[300px]',
        'overflow-y-auto',
        'shadow-menu',
      ]"
    >
      <div class="p-3">
        <v-text-field
          v-model="inputValue"
          placeholder="City, state, zip code"
          variant="outlined"
          density="compact"
          hide-details
          :rules="[rules.required]"
          autofocus
          :loading="isLoading"
          @keyup.enter="handleSelectedValue(inputValue)"
        />
      </div>

      <div class="flex flex-col">
        <div
          v-for="item in items"
          :key="item.id"
          :class="{
            'flex min-h-[32px] cursor-pointer items-center px-3 py-1 hover:bg-highlight-50': true,
            'bg-highlight-50': item.value === modelValue,
          }"
          @click="handleSelectedValue(item.value)"
        >
          <span class="text-xs">{{ item.value }}</span>
        </div>
      </div>

      <div class="flex items-center justify-center px-4 py-2">
        <Btn size="normal" @click="handleSelectedValue(inputValue)">Apply</Btn>
      </div>
    </div>
  </v-menu>
</template>
