<script setup lang="ts">
import { type DefineComponent, computed, nextTick, onMounted, reactive, ref } from 'vue';

import { TEMPLATE_CHIPS } from '@/core/conversations/template/template.utils';
import { type MessageTemplate } from '@/core/conversations/template/template.type';

import TemplateChip from './TemplateChip.vue';

import TextArea from '@/components/Shared/Input/TextInputs/TextArea.vue';
import TextField from '@/components/Shared/Input/TextInputs/TextField.vue';
import { isRequired, minimumLength } from '@/utils/forms/';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import ExclamationIcon from '@/assets/svg/exclamation-circle-16.svg?component';

const TEMPLATE_MAX_LENGTH = 500;

const emits = defineEmits<{
  (e: 'cancel-creation'): void;
  (e: 'save-template', template: Pick<MessageTemplate, 'title' | 'message'>): void;
}>();

// TODO: Chip insertion moved to Nice to have

// const editableArea = ref<HTMLElement | null>(null);
// const renderContent = shallowRef<
//   { type: string | typeof TemplateChip; text?: string; props?: Record<string, unknown> }[]
// >([]);

const templateData = reactive({
  title: '',
  message: '',
});

const templatetextarea = ref<DefineComponent | null>(null);
const textAreaEl = ref<HTMLTextAreaElement | null>(null);

onMounted(() => {
  if (templatetextarea.value !== null) {
    textAreaEl.value = templatetextarea.value.$el.querySelector('.v-field__input');
  }
});

const insertChip = (chipText: string) => {
  if (!chipText.length || !isAbleToInsertChip.value) return;

  const sentence = textAreaEl.value?.value || '';
  const len = sentence.length;

  // Will not allow to insert chip if it exceeds the max length
  if (len + chipText.length > TEMPLATE_MAX_LENGTH) {
    textAreaEl.value?.focus();
    SnackbarService.caution(
      'The message is too long. Please remove some text before adding a chip.',
    );
    return;
  }

  const startSelection = textAreaEl.value?.selectionStart || 0;
  const endSelection = textAreaEl.value?.selectionEnd || startSelection;

  const textBeforeChip = sentence.substr(0, startSelection);
  const textAfterChip = sentence.substr(endSelection, len);

  templateData.message = textBeforeChip + chipText + textAfterChip;

  nextTick().then(() => {
    if (textAreaEl.value !== null && textAreaEl.value !== undefined) {
      textAreaEl.value.selectionStart = textAreaEl.value.selectionEnd =
        startSelection + chipText.length;
      textAreaEl.value.focus();
    }
  });
};

// const updateRenderedContent = () => {
//   const chips = templateData.message.split(/(\[.*?\])/);
//   renderContent.value = chips.map((chip: string) => {
//     if (TEMPLATE_CHIPS.includes(chip)) {
//       return { type: TemplateChip, props: { editMode: true, text: chip } };
//     }
//     return { type: 'text', text: chip };
//   });
// };

// const handleInput = () => {
//   if (editableArea.value) {
//     templateData.message = editableArea.value.innerHTML;
//     updateRenderedContent();
//   }
// };

// const insertChip = (chipText: string) => {
//   if (editableArea.value) {
//     const selection = window.getSelection();
//     const range = selection?.getRangeAt(0);
//     const cursorPosition = range?.startOffset || 0;

//     // :: Inserts chip
//     const contentBeforeChip = templateData.message.substring(0, cursorPosition);
//     const contentAfterChip = templateData.message.substring(cursorPosition);
//     templateData.message = `${contentBeforeChip}${chipText}${contentAfterChip}`;

//     updateRenderedContent();

//     // :: Moves cursor
//     const newCursorPosition = cursorPosition + chipText.length;
//     const newRange = document.createRange();
//     newRange.setStart(editableArea.value.childNodes[0], newCursorPosition);
//     newRange.collapse(true);

//     selection?.removeAllRanges();
//     selection?.addRange(newRange);
//   }
// };

// const handleKeyDown = (event: KeyboardEvent) => {
//   const selection = window.getSelection();
//   const range = selection?.getRangeAt(0);
//   const startOffset = range?.startOffset ?? 0;
//   const endOffset = range?.endOffset;

//   if (event.key === 'Backspace' && startOffset === endOffset && startOffset > 0) {
//     // :: Deletes chip
//     const precedingText = templateData.message.substring(0, startOffset);
//     const lastWordStart = precedingText.lastIndexOf('[');
//     const lastWordEnd = precedingText.lastIndexOf(']');
//     if (lastWordStart > lastWordEnd && lastWordEnd < startOffset) {
//       const chipToDelete = precedingText.substring(lastWordStart, startOffset);
//       templateData.message = templateData.message.replace(chipToDelete, '');
//       updateRenderedContent();
//     }
//   }
// };

const loading = ref<boolean>(false);
const form = ref<boolean>(false); /** is Form valid?*/

const onSubmit = () => {
  if (!form.value) return;
  loading.value = true;
  emits('save-template', templateData);
};

const isAbleToInsertChip = computed(() => {
  return templateData.message.length < TEMPLATE_MAX_LENGTH;
});
</script>

<template>
  <v-form v-model="form" @submit.prevent="onSubmit">
    <div class="mb-1 flex flex-col">
      <label for="template-title" class="text-sm font-medium text-shade-880"
        >Template name (required)</label
      >
      <text-field
        v-model="templateData.title"
        id="template-title"
        name="template-title"
        maxlength="100"
        counter
        :rules="[isRequired, minimumLength]"
      />
    </div>
    <div class="flex flex-col">
      <label for="template-message" class="text-sm font-medium text-shade-880"
        >Template message (required)</label
      >
      <text-area
        ref="templatetextarea"
        v-model="templateData.message"
        :maxlength="TEMPLATE_MAX_LENGTH"
        rows="11"
        counter
        :rules="[isRequired, minimumLength]"
      />
      <!-- <div class="relative h-full w-full rounded-md border border-tint-80">
          <div
            id="template-message"
            name="template-message"
            class="!bg-transparent absolute left-0 top-0 z-20 w-full whitespace-pre-wrap rounded-md px-3 py-2.5 caret-black outline-none"
            style="color: transparent"
            contenteditable
            ref="editableArea"
            @input="handleInput"
            @keydown="handleKeyDown"
          ></div>
          <div class="absolute left-0 top-0 z-10 h-full w-full px-3 py-2.5">
            <div class="flex flex-wrap gap-1">
              <div v-for="(item, index) in renderContent" :key="index">
                <component :is="item.type" v-if="item.type !== 'text'" v-bind="item.props">
                </component>
                <div class="text-sm leading-6 text-shade-880" v-html="item.text"></div>
              </div>
            </div>
          </div>
        </div> -->
    </div>
    <div class="relative mt-4 flex flex-col">
      <h3 class="text-sm leading-5 text-shade-800">Add personalization:</h3>
      <div class="mt-2 flex flex-wrap gap-2">
        <TemplateChip
          v-for="(chip, index) in TEMPLATE_CHIPS"
          :key="index"
          :text="chip"
          class="transition-opacity duration-300"
          :class="{ 'cursor-default': !isAbleToInsertChip, 'opacity-50': !isAbleToInsertChip }"
          @insert-chip="insertChip"
        />
      </div>
      <div class="mt-2">
        <v-slide-y-transition>
          <span v-show="!isAbleToInsertChip" class="absolute flex text-xs text-critical-500">
            <ExclamationIcon />
            <span class="ml-2 place-self-center">Character limit has been reached</span>
          </span>
        </v-slide-y-transition>
      </div>
    </div>
    <div class="mt-6 flex justify-end gap-2">
      <v-btn
        :ripple="false"
        class="modal-button-secondary"
        variant="flat"
        @click="$emit('cancel-creation')"
        >Cancel</v-btn
      >
      <v-btn
        :ripple="false"
        :disabled="!form"
        :loading="loading"
        class="modal-button-primary ml-4"
        type="submit"
        variant="flat"
        >Save and close</v-btn
      >
    </div>
  </v-form>
</template>
