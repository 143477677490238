<template>
  <div ref="root" class="flex flex-col">
    <div ref="menuContainer" class="menu-container">
      <vue-tribute ref="tribute" :options="tributeOptions">
        <p
          ref="textContent"
          contenteditable="true"
          data-placeholder="Create a new note..."
          @input="handleInput"
          class="note-panel-input min-h-10 w-full rounded-md border border-tint-80 px-3 py-2 text-sm leading-6 focus:border-highlight-500 focus:outline-none"
        />
      </vue-tribute>
    </div>
    <button
      v-if="actionFunction"
      class="bg-blue-500 ml-2 mt-2 rounded px-4 py-2 text-white"
      :disabled="!inputText || isLoading"
      data-action-btn
      @click="callActionFunction"
    >
      Send
    </button>
    <template v-if="hasOptionalBooleanValue">
      <input v-model="optionalKeyValue" type="checkbox" class="mt-2" />
      <label class="mt-0">
        {{ alternateTaggingArrayOptions.text }}
      </label>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue';
import defaultAvatarUrl from '@/assets/svg/talent-search-white.svg';
import sanitize from 'sanitize-html';
import VueTribute from 'vue-tribute';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { ErrorService } from '@/core/shared/errors/error.service';

const emit = defineEmits(['update:modelValue', 'functionCalled']);
const props = defineProps({
  taggingArray: {
    type: Array<{ avatarUrl: string; id: number; name: string }>,
    required: true,
    validator: (users) => {
      if (!Array.isArray(users)) {
        return false;
      }
      return users.every((user) => {
        if (typeof user !== 'object') {
          return false;
        }
        const requiredKeys = ['avatarUrl', 'id', 'name'];

        return requiredKeys.every((key) => user.hasOwnProperty(key));
      });
    },
  },
  actionFunction: {
    type: Function,
    default: null,
  },
  alternateTaggingArrayOptions: {
    type: Object,
    required: false,
    default: null,
    validator: (option) => {
      if (!option) {
        return true;
      }
      if (typeof option !== 'object') {
        return false;
      }
      const requiredKeys = ['text', 'taggingArray', 'key', 'value'];
      return requiredKeys.every((key) => option.hasOwnProperty(key));
    },
    value: {
      type: String,
      default: '',
    },
  },
  startText: {
    type: String,
    required: false,
    default: '',
  },
});

const inputText = ref('');
const textContent = ref<HTMLElement | null>(null);
const menuContainer = ref<HTMLElement | null>(null);
const tribute = ref(null);
const isLoading = ref(false);
const optionalKeyValue = ref(false);
const taggableUsers = ref([] as Array<{ avatarUrl: string; id: number; name: string }>);
const tributeOptions = ref({
  trigger: '@',
  iframe: null,
  values: [],
  lookup: 'name',
  fillAttr: 'name',
  positionMenu: false,
  menuContainer: document.body,
  autocompleteMode: false,
  allowSpaces: true,
  containerClass: 'tribute-container',
  selectTemplate: function (
    item:
      | {
          original: {
            [x: string]: string;
            id: string;
            name: string;
          };
        }
      | undefined,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const that = this as any;
    if (typeof item === 'undefined') return null;
    if (that.range.isContentEditable(that.current.element)) {
      return `<span contenteditable="false" class="tribute-mention" data-id="${item.original.id}">${item.original.name}</span>`;
    }
    return '@' + item.original.value;
  },
  menuItemTemplate: function (item: { original: { avatarUrl: string; value: string } }) {
    return `<img src="${item.original.avatarUrl || defaultAvatarUrl}">
          <span class="item-string">${item.original.value}</span>`;
  },
});
const root = ref<HTMLElement | null>(null);
const hasOptionalBooleanValue = computed(() => Boolean(props.alternateTaggingArrayOptions));
onMounted(async () => {
  if (textContent.value) {
    textContent.value.innerHTML = props.startText;
  }
  if (menuContainer.value) {
    tributeOptions.value.menuContainer = menuContainer.value;
  }
  await nextTick();

  let sanitizedText = sanitize(props.startText, {
    allowedTags: ['br', 'span', 'div'],
  });
  sanitizedText = sanitizedText.replace(/<div>/g, '\n').replace(/<\/div>/, '');
  sanitizedText = sanitizedText.replace(/<br ?\/?>/g, '\n');
  inputText.value = sanitizedText;

  if (hasOptionalBooleanValue.value) {
    optionalKeyValue.value = props.alternateTaggingArrayOptions.value;

    if (!props.alternateTaggingArrayOptions.value) {
      setTaggingArray(props.taggingArray);
    }
  } else {
    setTaggingArray(props.taggingArray);
  }
});
const setTaggingArray = (array: Array<{ avatarUrl: string; id: number; name: string }>) => {
  const sortedArray: Array<{ avatarUrl: string; id: number; name: string }> = JSON.parse(
    JSON.stringify(array),
  );
  sortedArray.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
  taggableUsers.value = sortedArray;
};
const callActionFunction = async () => {
  isLoading.value = true;
  try {
    parseInputTextToMarkdown();
    if (props.alternateTaggingArrayOptions.value) {
      const option: Record<
        string,
        string | number | boolean | Record<string, string | number | boolean>
      > = {};
      option[`${props.alternateTaggingArrayOptions.key}`] = optionalKeyValue.value;
      await props.actionFunction(inputText.value, option);
    } else {
      await props.actionFunction(inputText.value);
    }
    emit('functionCalled');
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to handle note action');
  } finally {
    isLoading.value = false;
    inputText.value = '';
    if (textContent.value) {
      textContent.value.innerHTML = '';
    }
  }
};
const parseInputTextToMarkdown = () => {
  if (root.value) {
    const htmlTags = root.value.querySelectorAll('.tribute-mention') || [];
    htmlTags.forEach((tag) => {
      const t = tag as unknown as { dataset: { id: string } };
      const userProfileId = t.dataset.id;
      const userProfile = getUserForIdFromTaggingArray(userProfileId);
      if (userProfile) {
        inputText.value = inputText.value.replace('<span>', '').replace('</span>', '');
      }
    });
  }
};
const handleInput = (event: Event) => {
  const target = event.target as HTMLElement;
  const e = event as InputEvent;
  removeBrTagOnEmptyInnerHTML(target, e.inputType);
  if (e.inputType !== 'deleteContentBackward') {
    renderTagsIfFound(event);
  }
  let sanitizedText = sanitize(target.innerHTML, {
    allowedTags: ['br', 'span', 'div'],
  });
  sanitizedText = sanitizedText.replace(/<div>/g, '\n').replace(/<\/div>/g, '');
  sanitizedText = sanitizedText.replace(/<br ?\/?>/g, '\n');
  inputText.value = sanitizedText;
  emit('update:modelValue', sanitizedText);
};
const removeBrTagOnEmptyInnerHTML = (target: HTMLElement, inputType: string) => {
  if (
    inputType === 'deleteContentBackward' &&
    (target.innerHTML === '<br>' || target.innerHTML === '<div><br></div>') &&
    target.firstChild !== null
  ) {
    target.removeChild(target.firstChild);
  }
};
const renderTagsIfFound = (event: Event) => {
  const target = event.target as HTMLElement;
  const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
  let matches;
  while ((matches = regex.exec(target.innerHTML)) !== null) {
    if (validateTag(matches[0])) {
      let names = matches[1].split(' ');
      names = names.map((name) => name.charAt(0).toUpperCase() + name.substring(1).toLowerCase());
      const fullName = names.join(' ');
      target.innerHTML = target.innerHTML.replace(
        matches[0],
        `<span contenteditable="false" class="tribute-mention" data-id="${matches[2]}">${fullName}</span> `,
      );
      setCursor();
    }
  }
};

const setCursor = () => {
  if (textContent.value) {
    const range = document.createRange();
    range.selectNodeContents(textContent.value);
    range.collapse(false);
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
    textContent.value.focus();
    range.detach();
  }
};
const validateTag = (tag: string) => {
  const { fullName, id } = getNameAndIdFromTag(tag);
  const user = getUserForIdFromTaggingArray(id);
  if (user) {
    const userFullName = user.name;
    return userFullName.toLowerCase() === fullName.toLowerCase();
  }
  return false;
};
const getNameAndIdFromTag = (tag: string) => {
  const parts = tag.split(']');
  const fullName = parts[0].replace('[', '');
  const id = parts[1].replace('(', '').replace(')', '');
  return { fullName, id };
};
const getUserForIdFromTaggingArray = (id: string | number) => {
  let checkingId = id;
  if (typeof id === 'string') {
    checkingId = Number(id);
  }
  if (hasOptionalBooleanValue.value && optionalKeyValue.value) {
    return props.alternateTaggingArrayOptions.taggingArray.find(
      (user: { id: number }) => user.id === checkingId,
    );
  }
  return props.taggingArray.find((user) => user.id === checkingId);
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const clearInput = () => {
  inputText.value = '';
  if (textContent.value) {
    textContent.value.innerHTML = '';
  }
};
defineExpose({
  clearInput,
});
</script>

<style scoped>
.note-panel-input:empty:before {
  @apply text-sm text-shade-800;
  content: attr(data-placeholder);
}
</style>
