<script lang="ts" setup>
import UserAvatar from '@/components/Conversations/Avatar/UserAvatar.vue';
import CopilotAvatar from '@/components/Conversations/Avatar/CopilotAvatar.vue';
import DateUtils from '@/utils/date-utils';
import type { JobApplicant } from '@factoryfixinc/ats-interfaces';
import {
  REJECTED_WITH_REASON,
  findJobApplicationStatusTitle,
} from '@/core/conversations/pro-profile/utils/application-statuses';
import { computed } from 'vue';
import type { ConversationMessage } from '@/core/conversations/message/conversation-message.type';

const props = defineProps<{
  jobApplicant?: JobApplicant;
  message: ConversationMessage;
  isRejectedWithReason: boolean;
}>();

const status = computed(() => props.message.content);
const updateTs = computed(() => props.message.createdAt);
const sender = computed(() => props.message.sender?.name || 'Copilot');
const isCopilotMessage = computed(() => sender.value === 'Copilot');

const rejectionMessage = computed(() => {
  if (!props.jobApplicant) {
    return REJECTED_WITH_REASON;
  }

  return REJECTED_WITH_REASON + ': ' + props.jobApplicant.rejectionReason;
});
</script>
<template>
  <div class="mb-3 mt-3 flex flex-row-reverse gap-1 pl-0 pr-0 font-sans">
    <CopilotAvatar v-if="isCopilotMessage" :vertical-position="'center'" />
    <UserAvatar v-else :isEmployerMessage="true" :name="sender" vertical-position="center" />

    <div class="mr-4">
      <div class="mb-1 whitespace-pre-wrap break-words text-sm text-shade-900">
        Status changed to
        <span v-if="isRejectedWithReason === true" class="font-bold">{{ rejectionMessage }}</span>
        <span v-else class="font-bold">{{ findJobApplicationStatusTitle(status) }}</span>
      </div>

      <div class="text-right text-sm text-shade-840">
        <span class="font-bold">{{ sender }}</span>
        <span class="ml-1 text-shade-800">{{
          DateUtils.formatTimeAndDateIfNotToday(updateTs)
        }}</span>
      </div>
    </div>
  </div>
</template>
