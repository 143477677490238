import { useFFRestApi } from '@/composables/useApi';
import { type MessageTemplate, MessageTemplateStatus } from './template.type';

export class TemplatePersistence {
  public async getTemplates(userProfileId: string): Promise<MessageTemplate[]> {
    const filter = encodeURIComponent(
      JSON.stringify({
        order: ['isFavorite DESC', 'updateTs DESC'],
        limit: 100,
        skip: 0,
        where: {
          and: [
            {
              status: MessageTemplateStatus.LIVE,
            },
            {
              or: [{ userProfileId: null }, { userProfileId }],
            },
          ],
        },
      }),
    );
    const url = `/message-template?filter=${filter}`;
    const { data, error } = await useFFRestApi(url).get().json<{ messages: MessageTemplate[] }>();

    if (error?.value) {
      throw new Error(error.value);
    }

    return data?.value?.messages || [];
  }

  public async createTemplate(template: Pick<MessageTemplate, 'title' | 'message'>): Promise<void> {
    const url = '/message-template';
    const { error } = await useFFRestApi(url).post(template);

    if (error?.value) {
      throw new Error(error.value);
    }
  }

  public async updateTemplate(
    template: Pick<MessageTemplate, 'id' | 'title' | 'message'>,
  ): Promise<void> {
    const { id, ...templateWithoutId } = template;

    const url = `/message-template/${id}`;
    const { error } = await useFFRestApi(url).patch(templateWithoutId);

    if (error?.value) {
      throw new Error(error.value);
    }
  }

  public async deleteTemplate(templateId: number): Promise<void> {
    const url = `/message-template/${templateId}/status`;
    const { error } = await useFFRestApi(url).put({ status: 'removed' });

    if (error?.value) {
      throw new Error(error.value);
    }
  }

  public async restoreTemplate(templateId: number): Promise<void> {
    const url = `/message-template/${templateId}/status`;

    const { error } = await useFFRestApi(url).put({ status: 'live' });

    if (error?.value) {
      throw new Error(error.value);
    }
  }
}
