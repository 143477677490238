<script setup lang="ts">
import type { Project, ProjectWithRelations } from '@factoryfixinc/ats-interfaces';
import type { AssigneeWatcherAvatarData } from '@/core/shared/project/types/assignee-watcher-avatar-data.type';
import {
  getAssigneeAvatarData,
  getWatcherAvatarData,
} from '@/core/shared/project/utils/get-watcher-assignee-avatar-data.util';
import ProjectCardTeamMenu from '@/components/Shared/Projects/ProjectCardTeamMenu/ProjectCardTeamMenu.vue';
import EmptyAvatarIcon from '@/assets/svg/avatar-empty.svg?component';

import { computed, ref, watch } from 'vue';

const props = defineProps<{
  assignee: Project['assignee'];
  watchers: Project['watchers'];
  project: ProjectWithRelations<'candidates'>;
  tooltips?: boolean;
}>();

const isHoveringAssignee = ref(false);
const isAssigneeTeamMenuOpen = ref(false);
const isHoveringWatcher = ref(false);
const isWatcherTeamMenuOpen = ref(false);

const assigneeData = computed<AssigneeWatcherAvatarData>((): AssigneeWatcherAvatarData => {
  return getAssigneeAvatarData(props.assignee);
});
const hasAssignee = computed(() => Boolean(assigneeData.value.id));

const watchersData = computed<AssigneeWatcherAvatarData[]>((): AssigneeWatcherAvatarData[] => {
  return getWatcherAvatarData(props.watchers).filter(
    (watcher) => watcher.id !== assigneeData.value.id,
  );
});
const hasWatchers = computed(() => watchersData.value.length > 0);

const watcher = computed(() => watchersData.value[0]);

const hasAssigneeOrWatchers = computed(() => hasAssignee.value || hasWatchers.value);

// Assignee Team Menu
const displayingAssigneeText = computed(() => {
  let text = '';

  if (
    (!isAssigneeTeamMenuOpen.value && !isHoveringAssignee.value) ||
    isAssigneeTeamMenuOpen.value
  ) {
    text = assigneeData.value.avatarString;
  }

  return text;
});

const isDisplayingAssigneeTeamMenuActivator = computed(() => {
  return isHoveringAssignee.value && !isAssigneeTeamMenuOpen.value;
});

// Watcher Team Menu
const displayingWatcherText = computed(() => {
  let text = '';

  if ((!isWatcherTeamMenuOpen.value && !isHoveringWatcher.value) || isWatcherTeamMenuOpen.value) {
    text = watcher.value.avatarString;
  }

  return text;
});
const isDisplayingWatcherTeamMenuActivator = computed(() => {
  return isHoveringWatcher.value && !isWatcherTeamMenuOpen.value;
});

function updateIsHoveringAssignee(value: boolean) {
  isHoveringAssignee.value = value;
}

function updateIsHoveringWatcher(value: boolean) {
  isHoveringWatcher.value = value;
}

watch(hasAssigneeOrWatchers, (newValue) => {
  /**
   * When there are no assignee or watchers and we assign a new team member,
   * that team member gets assigned as the owner. We need to close both menus as
   * the sudden template change `<template v-if="hasAssigneeOrWatchers">` will
   * stop the model update from the new assignment and when another team member
   * is selected both assignee and watcher menus will be open.
   */
  if (newValue) {
    isAssigneeTeamMenuOpen.value = false;
    isWatcherTeamMenuOpen.value = false;
  }
});
</script>

<template>
  <div class="flex h-[24px] leading-[14px]">
    <!-- As per design proposal, we need to preserve the height even if its empty -->
    <template v-if="hasAssigneeOrWatchers">
      <!-- Assignee -->
      <v-tooltip location="top" content-class="bg-shade-880 rounded-tooltip tooltip-bottom-arrow">
        <template v-slot:activator="{ props }">
          <div
            v-if="hasAssignee"
            v-bind="props"
            :class="[
              'inline-flex h-[24px] w-[24px] rounded-full',
              'bg-primary-100 text-primary-800',
              'text-[10px] font-bold leading-[18px]',
              'relative mr-[4px] items-center justify-center',
            ]"
            @mouseenter="updateIsHoveringAssignee(true)"
            @mouseleave="updateIsHoveringAssignee(false)"
          >
            <span>
              {{ displayingAssigneeText }}
            </span>
            <ProjectCardTeamMenu
              v-model="isAssigneeTeamMenuOpen"
              :project="project"
              :display-activator="isDisplayingAssigneeTeamMenuActivator"
            />
          </div>
        </template>

        <!-- Tooltip text -->
        <span class="text-xs">{{ assigneeData.fullName }}</span>
      </v-tooltip>

      <!-- Watchers -->
      <div v-if="hasWatchers" class="inline-flex">
        <v-tooltip location="top" content-class="bg-shade-880 rounded-tooltip tooltip-bottom-arrow">
          <template v-slot:activator="{ props }">
            <div
              v-bind="props"
              :class="[
                'inline-flex h-[24px] w-[24px] rounded-full',
                'bg-inform-100 text-inform-800',
                'text-[10px] font-bold leading-[18px]',
                'relative mr-[4px] items-center justify-center',
              ]"
              @mouseenter="updateIsHoveringWatcher(true)"
              @mouseleave="updateIsHoveringWatcher(false)"
            >
              <span>
                {{ displayingWatcherText }}
              </span>
              <ProjectCardTeamMenu
                v-model="isWatcherTeamMenuOpen"
                :project="project"
                :display-activator="isDisplayingWatcherTeamMenuActivator"
              />
            </div>
          </template>
          <!-- Tooltip text -->
          <span class="text-xs">{{ watcher.fullName }}</span>
        </v-tooltip>
        <div
          :class="[
            'inline-flex',
            'bg-red text-tint-80',
            'font-regular text-[12px] leading-[18px]',
            'items-center justify-center',
          ]"
        >
          <template v-if="watchersData.length > 1">
            <v-tooltip
              location="bottom"
              content-class="bg-shade-880 text-center rounded-tooltip tooltip-top-arrow"
            >
              <template v-slot:activator="{ props }"
                ><div
                  v-bind="props"
                  :class="[
                    'inline-flex h-[24px] w-[24px] rounded-full',
                    'text-[12px] font-normal leading-[18px] text-tint-80',
                    'justify-left mr-[4px] items-center',
                  ]"
                >
                  +{{ watchersData.length - 1 }}
                </div></template
              >
              <!-- Tooltip text -->
              <span
                class="text-xs"
                v-for="watcher in watchersData.slice(1)"
                v-bind:key="watcher.id"
              >
                {{ watcher.fullName }}<br
              /></span>
            </v-tooltip>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <v-tooltip location="top" content-class="bg-shade-880 rounded-tooltip tooltip-bottom-arrow">
        <template v-slot:activator="{ props }">
          <div
            v-bind="props"
            :class="[
              'inline-flex h-[24px] w-[24px] rounded-full',
              'bg-shade-800 text-primary-800',
              'text-[10px] font-bold leading-[18px]',
              'relative mr-[4px] items-center justify-center',
            ]"
            @mouseenter="updateIsHoveringWatcher(true)"
            @mouseleave="updateIsHoveringWatcher(false)"
          >
            <span><EmptyAvatarIcon v-if="!isHoveringWatcher" class="text-tint-100" /></span>
            <ProjectCardTeamMenu
              v-model="isWatcherTeamMenuOpen"
              :project="project"
              :display-activator="isDisplayingWatcherTeamMenuActivator"
              :is-empty-avatar="true"
            />
          </div>
        </template>

        <!-- Tooltip text -->
        <span class="text-xs">Add assignee</span>
      </v-tooltip>
    </template>
  </div>
</template>
