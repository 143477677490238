<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { extractParseNoteTextParts } from '@/core/shared/pro-notes/note-sanitize';
import DateUtils from '@/utils/date-utils';
import UserAvatar from '@/components/Conversations/Avatar/UserAvatar.vue';
import type { NoteWithJobHeader } from '@/core/shared/pro-notes/types/pro-note.type';
import MeService from '@/core/shared/me/me.service';
import EditNoteMenu from './EditNoteMenu.vue';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import ProNotesService from '@/core/shared/pro-notes/pro-notes.service';
import { getUserName } from '@/core/shared/user-profile/utils/get-user-name.util';
import { type UserProfile } from '@factoryfixinc/ats-interfaces';
import { ErrorService } from '@/core/shared/errors/error.service';

const props = defineProps<{
  note: NoteWithJobHeader;
}>();

const meService = new MeService();
const proNotesService = new ProNotesService();

const editionMode = ref<boolean>(false);
const noteAreaRef = ref<HTMLDivElement | null>(null);
const noteAreaHeight = ref<number>(0);
const currentNoteDescription = ref<string>(props.note.description);

const userName = computed<string>(() => {
  return getUserName(props.note.userProfile as UserProfile);
});
const noteBelongsToCurrentUser = computed<boolean>(() => {
  return props.note.userProfile?.id === meService.userProfile?.id;
});

const deleteNote = async () => {
  try {
    await proNotesService.deleteProNote(props.note.id);
    SnackbarService.showSnackbar({
      text: 'Note deleted',
      horizontalPosition: 'center',
      appendIcon: 'close',
    });
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to delete note');
  }
};

const editNote = () => {
  editionMode.value = true;
};

const cancelEditMode = () => {
  editionMode.value = false;
  currentNoteDescription.value = props.note.description;
};

const updateNote = async () => {
  try {
    await proNotesService.updateProNote(props.note.id, currentNoteDescription.value);
    editionMode.value = false;
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to update note');
  }
};

onMounted(() => {
  if (noteAreaRef.value) {
    noteAreaHeight.value = noteAreaRef.value.clientHeight;
  }
});
</script>

<template>
  <div class="flex">
    <div class="flex h-8 w-8 items-center justify-center rounded-full bg-tint-40 text-center">
      <UserAvatar
        :is-pro-message="false"
        :is-bot-message="!note.userProfile"
        :is-employer-message="!!note.userProfile?.id"
        :name="userName"
        :current-user="meService.userProfile"
      />
    </div>
    <div class="ml-4 flex flex-1 flex-col">
      <template v-if="!editionMode">
        <div class="flex flex-row items-center justify-between">
          <div class="flex items-baseline gap-2">
            <span
              class="py-1.5 text-sm font-bold text-shade-900"
              v-if="note.userProfile?.nameFirst"
            >
              {{ userName }}
            </span>
            <span class="text-xs text-shade-800">
              {{ DateUtils.formatTimestamp(note.createTs, 'MMMM DD, YYYY') }}
            </span>
          </div>
          <EditNoteMenu
            :note="note"
            @delete-note="deleteNote"
            @edit-note="editNote"
            v-if="noteBelongsToCurrentUser"
          />
        </div>
        <div class="mt-1 rounded-xl border border-tint-40 bg-tint-20 p-4 text-sm" ref="noteAreaRef">
          <div v-if="note.jobHeader?.jobTitle" class="mb-1">
            <b>In reference to</b> {{ note.jobHeader?.jobTitle }}
          </div>
          <span v-html="extractParseNoteTextParts(note.description).htmlWithoutTags"></span>
          <span class="mt-1 flex flex-row gap-2">
            <span
              class="rounded-lg bg-tint-60 px-2 py-1 text-xs"
              v-for="tag in extractParseNoteTextParts(note.description).tags"
              :key="tag"
              >{{ tag }}
            </span>
          </span>
        </div>
      </template>
      <template v-else>
        <textarea
          class="box-border resize-none rounded-md border border-tint-80 p-4 text-sm outline-none"
          :style="[noteAreaHeight ? { height: `${noteAreaHeight}px` } : '']"
          v-html="extractParseNoteTextParts(note.description).htmlWithoutTags"
          v-model="currentNoteDescription"
        ></textarea>
        <div class="mt-2 flex justify-end gap-2">
          <button
            @click="cancelEditMode"
            class="grid h-8 w-16 place-content-center rounded-md border-2 border-shade-900 bg-tint-0 text-xs font-semibold text-shade-900"
          >
            Cancel
          </button>
          <button
            @click="updateNote"
            class="grid h-8 w-16 place-content-center rounded-md border bg-shade-900 text-xs font-semibold text-tint-0"
          >
            Save
          </button>
        </div>
      </template>
    </div>
  </div>
</template>
