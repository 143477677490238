<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import dayjs from 'dayjs';

import { SearchService } from '@/core/sourcing/search/search.service';
import { ProPreviewStatsService } from '@/core/sourcing/pro-preview-stats/pro-preview-stats.service';
import { ViewedCandidateService } from '@/core/sourcing/viewed-candidates/viewed-candidate.service';

import ProjectHeader from '@/components/Sourcing/ProjectHeader/ProjectHeader.vue';
import ProSearchResults from '@/components/Sourcing/ProSearchResults/ProSearchResults.vue';
import ProPreviewPanel from '@/components/Sourcing/ProPreviewPanel.vue';
import FilterChips from '@/components/Sourcing/Filters/FilterChips/FilterChips.vue';
import SelectedCandidateService from '@/core/sourcing/selected-candidate/selected-candidate.service';
import SourcingProjectService from '@/core/sourcing/project/project.service';
import MessagingModal from '@/components/Sourcing/MessagingModal.vue';

import MeService from '@/core/shared/me/me.service';

import { MessagingService } from '@/core/sourcing/messaging/messaging.service';
import ProUnlockService from '@/core/sourcing/pro-unlock/pro-unlock.service';
import { ProUnlockSource } from '@/core/sourcing/pro-unlock/types/pro-unlock-source.type';
import SpinnerLoader from '@/components/Shared/Loaders/SpinnerLoader.vue';
import { ProjectStatus } from '@factoryfixinc/ats-interfaces';

import ArchivedJobImage from '@/assets/png/sourcing/archived-job.png';
import type { VAppBar } from 'vuetify/components';
import { useDisplay } from 'vuetify';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { isProRecentlyActive } from '@/core/sourcing/utils/dates';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import ProjectService from '@/core/shared/project/project.service';

const route = useRoute();
const router = useRouter();
const display = useDisplay();

const proPreviewStatsService = new ProPreviewStatsService();
const searchService = new SearchService();
const meService = new MeService();
const selectedCandidateService = new SelectedCandidateService();
const messagingService = new MessagingService();
const viewedCandidateService = new ViewedCandidateService();
const projectService = new ProjectService();
const sourcingProjectService = new SourcingProjectService();
const proUnlockService = new ProUnlockService();

const DEFAULT_MOBILE_APP_BAR_HEIGHT = 64;
const ALL_MATCHES_HEIGHT = 64;
const isShowingCopilotBanner = ref(false);
const isUnlockingUserProfiles = ref(false);
const openMessagingDialog = ref(false);
const isLoading = ref(false);
const isLoadingCandidates = ref(false);
const appBarWidth = ref(0);
const appBarHeight = ref(0);
const candidatesOffsetHeight = ref(0);
const isSearchingCandidates = ref(false);
const isMobile = computed(() => display.smAndDown.value);

const showBulkMessageButton = computed(() => {
  return messagingService.selectedProUserProfiles.length > 0;
});

const selectedUserProfilesToUnlockCount = computed(() => {
  return messagingService.selectedUserProfilesToUnlock.length;
});

const bulkMessageRecipientsCount = computed(() => {
  return messagingService.selectedPPCUserProfiles.length;
});

const bulkUnlockButtonMessage = computed(() => {
  const isSameCount = selectedUserProfilesToUnlockCount.value === bulkMessageRecipientsCount.value;

  if (isSameCount) {
    return `Unlock & Message ${selectedUserProfilesToUnlockCount.value}`;
  }

  const unlockText = selectedUserProfilesToUnlockCount.value
    ? `Unlock ${selectedUserProfilesToUnlockCount.value}`
    : '';
  const separator = unlockText ? ' & ' : '';
  const recipientsText = `Message ${bulkMessageRecipientsCount.value}`;

  return `${unlockText}${separator}${recipientsText}`;
});

const projectId = computed(() => {
  const id = Number(route.params.id);

  if (isNaN(id)) {
    return undefined;
  }

  return id;
});

const currentProject = computed(() => {
  return projectService.currentProject;
});

const projectStatus = computed(() => {
  return currentProject.value?.status;
});

const isArchived = computed(() => {
  return projectStatus.value === ProjectStatus.ARCHIVED;
});

const hasExpandedHeightAppBarProjectHeader = computed(() => {
  return appBarWidth.value > 0 && appBarWidth.value < 888;
});

const candidateListStyle = computed<Record<string, string>>(() => {
  return {
    height: `calc(100vh - ${appBarHeight.value + ALL_MATCHES_HEIGHT}px)`,
  };
});

const allMatchesStyle = computed<Record<string, string>>(() => {
  return {
    top: `${appBarHeight.value}px`,
    height: `${ALL_MATCHES_HEIGHT}px`,
  };
});

const showPPP = computed(() => {
  return searchService.candidates.length > 0 || isLoadingCandidates.value;
});

async function openMessaging({ source }: { source: ProUnlockSource }) {
  try {
    isUnlockingUserProfiles.value = true;
    const userProfilesToUnlock = messagingService.selectedUserProfilesToUnlock.map(
      (userProfile) => userProfile.id,
    );
    await proUnlockService.unlockProUserProfiles(userProfilesToUnlock, source);
    messagingService.markSelectedUserProfilesAsUnlocked(userProfilesToUnlock);
    openMessagingDialog.value = true;

    const event =
      messagingService.selectedProUserProfiles?.length > 1
        ? TrackingActionName.BULK_ACTION_START
        : TrackingActionName.MESSAGE_START;

    TrackingService.trackAction(event, {
      num_unlocks: userProfilesToUnlock?.length,
      num_pros: messagingService.selectedProUserProfiles?.length,
      num_recently_active:
        messagingService.selectedProUserProfiles?.filter((pro) =>
          isProRecentlyActive(pro.lastActiveTs),
        )?.length ?? 0,
    });
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to unlock candidates. Please, try again later.');
  } finally {
    isUnlockingUserProfiles.value = false;
  }
}

async function handleResultsPageChange(page: number) {
  try {
    isLoadingCandidates.value = true;
    searchService.pagination.page = page;
    searchService.candidates = [];
    viewedCandidateService.viewedCandidates = [];
    await searchService.searchCandidates();
    await viewedCandidateService.fetchViewedCandidates();
  } catch (error) {
    ErrorService.captureException(error);
  } finally {
    isLoadingCandidates.value = false;
  }
}

async function handleResultsItemsPerPageChange(itemsPerPage: number) {
  try {
    isLoadingCandidates.value = true;
    searchService.pagination.itemsPerPage = itemsPerPage;
    await searchService.searchCandidates();
    await viewedCandidateService.fetchViewedCandidates();
  } catch (error) {
    ErrorService.captureException(error);
  } finally {
    isLoadingCandidates.value = false;
  }
}

async function handleFilterChange(shouldSave: boolean = true) {
  if (isSearchingCandidates.value) {
    return;
  }
  try {
    isSearchingCandidates.value = true;
    await searchService.searchCandidates();
  } catch (error) {
    ErrorService.captureException(error);
  } finally {
    isSearchingCandidates.value = false;
  }
  if (projectId.value && shouldSave) {
    await sourcingProjectService.saveProjectSavedSearch(projectId.value);
  }
}

function checkCopilotBannerStatus() {
  if (!currentProject.value || currentProject.value.copilot || isArchived.value) {
    isShowingCopilotBanner.value = false;
  } else {
    if (Object.hasOwnProperty.call(window, 'localStorage')) {
      const data = localStorage.getItem(`sourcing-copilot-${projectId.value}`);

      if (data) {
        const parsedData = JSON.parse(data);

        if (
          parsedData.projectId === projectId.value &&
          parsedData.userId === meService.userProfile?.id
        ) {
          // If banner was hidden more than 17 days ago, show it again
          const date = dayjs(parsedData.date);
          const currentDate = dayjs();

          if (currentDate.diff(date, 'day') > 17) {
            isShowingCopilotBanner.value = true;
            // remove item from local storage
            localStorage.removeItem(`sourcing-copilot-${projectId.value}`);
          } else {
            isShowingCopilotBanner.value = false;
          }
        }
      } else {
        isShowingCopilotBanner.value = true;
      }
    }
  }
}

function scrollSelectedCandidateIntoView() {
  const selectedCandidateId = selectedCandidateService.candidate?.id;

  if (!selectedCandidateId) {
    return;
  }

  const selectedCandidateElement = document.getElementById(
    `pro-preview-card-${selectedCandidateId}`,
  );

  selectedCandidateElement?.scrollIntoView({
    behavior: 'instant',
    block: 'center',
    inline: 'nearest',
  });
}

async function setAppBarLayout() {
  checkCopilotBannerStatus();
  await setupAppBarDimensions();
}

watch(
  projectId,
  async (newProjectId) => {
    if (projectService.lastSelectedSourcingProjectId === newProjectId) {
      await setAppBarLayout();
      scrollSelectedCandidateIntoView();
      return;
    }
    if (newProjectId) {
      await initializeProjectData(newProjectId);
    }
  },
  { immediate: true },
);

watch(projectStatus, setAppBarLayout);

async function setupAppBarDimensions() {
  await nextTick();
  const projectHeader = document.querySelector<HTMLElement>(
    '[data-test-id=sourcing-project-header]',
  );
  const projectFilters = document.querySelector<HTMLElement>('[data-test-id=project-filters]');

  appBarWidth.value = projectHeader?.offsetWidth ?? 0;
  appBarHeight.value = projectHeader?.offsetHeight ?? 0;

  let offsetHeight = (projectHeader?.offsetHeight ?? 0) + (projectFilters?.offsetHeight ?? 0);

  if (isMobile.value) {
    offsetHeight += DEFAULT_MOBILE_APP_BAR_HEIGHT;
  }

  candidatesOffsetHeight.value = offsetHeight;
}

watch([display.width, display.height], setupAppBarDimensions);

async function initializeProjectData(newProjectId: number) {
  if (isLoading.value) {
    return;
  }

  isLoading.value = true;
  // If we do not reset the page number will be preserved even if the project changes
  searchService.pagination.page = 1;

  if (!newProjectId) {
    return;
  }

  // record the last selected valid project id
  projectService.lastSelectedSourcingProjectId = newProjectId;

  // Should we activate copilot?
  const shouldEnableCopilot = route.query.enableCopilot === 'true';

  if (shouldEnableCopilot) {
    try {
      await projectService.updateProjectCopilotStatus(newProjectId, true);
      const project = projectService.projects.find((project) => project.id === projectId.value);

      if (project) {
        project.copilot = true;
      }
    } catch (error) {
      ErrorService.captureException(error);
      SnackbarService.critical('Could not enable Copilot. Please, try again later.');
    } finally {
      // remove enableCopilot query param
      await router.replace({ query: { ...route.query, enableCopilot: undefined } });
    }
  }
  try {
    selectedCandidateService.clearSelectedCandidate();
    messagingService.removeSelectedProUserProfiles();
    await projectService.getProjectById(newProjectId);
    searchService.setSelectedFiltersFromProject(currentProject.value);
    await searchService.searchCandidates();

    await Promise.all([
      proPreviewStatsService.fetchPreviewStats(),
      viewedCandidateService.fetchViewedCandidates(),
    ]);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Could not load job. Please, try again later.');
  } finally {
    isLoading.value = false;
    await setAppBarLayout(); // called here after the elements are visible
  }
}

/**
 * Previously we were calling handleFilterChange() in the onMounted hook, but
 * that was creating a race condition with the watcher for projectId and sending
 * an empty filter request to the backend.
 *
 * The initializeProjectData should do the same candidate search, but it will
 * also stop if any other search is already in place and will also get all the
 * needed data in place before doing the actual search.
 */
onMounted(async () => {
  if (projectId.value) {
    await initializeProjectData(projectId.value);
  }
});
</script>

<template>
  <div v-show="!isLoading" class="min-h-screen bg-tint-20">
    <!-- Project Header -->
    <v-app-bar border flat color="white" :height="appBarHeight" extension-height="65px">
      <ProjectHeader
        v-if="currentProject && !isArchived"
        :project="currentProject"
        :show-copilot-banner="isShowingCopilotBanner"
        :expanded-height="hasExpandedHeightAppBarProjectHeader"
        data-test-id="sourcing-project-header"
        ref="projectHeaderRef"
        @hide-copilot-banner="isShowingCopilotBanner = false"
      />
      <!-- Filters -->
      <template #extension>
        <div v-if="!isArchived" class="filter-chips-wrapper" data-test-id="project-filters">
          <span class="mr-2 text-xs font-bold text-shade-900">Filters:</span>
          <FilterChips @change:filter="handleFilterChange" />
        </div>
      </template>
    </v-app-bar>

    <template v-if="!isArchived">
      <div :style="`height: ${candidatesOffsetHeight}px`" />
      <div
        v-if="searchService.serverItems"
        class="sticky flex items-center justify-between overflow-auto bg-tint-20 px-8 py-4"
        :style="allMatchesStyle"
      >
        <!-- Candidates Count -->
        <span class="text-xs leading-[18px]">
          Search results ({{ searchService.serverItems }})
        </span>

        <!-- Bulk Messaging Button -->
        <button
          v-if="showBulkMessageButton"
          class="max-h-[32px] items-center rounded-[6px] bg-highlight-600 px-3 py-2"
          :disabled="isUnlockingUserProfiles"
          @click="openMessaging({ source: ProUnlockSource.BULK_MESSAGING })"
        >
          <template v-if="isUnlockingUserProfiles">
            <SpinnerLoader class="mx-4 h-[18px] w-[18px]" dark></SpinnerLoader>
          </template>
          <template v-else>
            {{ bulkUnlockButtonMessage }}
          </template>
        </button>
      </div>

      <!-- Candidates List -->
      <div :style="candidateListStyle" class="overflow-y-auto px-8 pb-4 pt-0.5">
        <ProSearchResults
          :is-loading="isLoadingCandidates"
          @change:page="handleResultsPageChange"
          @change:items-per-page="handleResultsItemsPerPageChange"
        />
      </div>

      <!-- Profile Panel -->
      <v-navigation-drawer location="right" width="355" color="white" v-if="showPPP">
        <div :style="`height: ${candidatesOffsetHeight}px`"></div>
        <div
          v-if="isLoadingCandidates"
          class="flex h-full flex-col items-center justify-center overflow-auto px-3 py-5"
        >
          <SpinnerLoader class="h-8 w-8" />
        </div>
        <ProPreviewPanel
          v-else
          :project="currentProject"
          :is-fetching-candidate="selectedCandidateService.isFetchingCandidate"
          @open:messaging="openMessaging({ source: ProUnlockSource.PPP })"
        />
      </v-navigation-drawer>

      <MessagingModal :open="openMessagingDialog" @close="openMessagingDialog = false" />
    </template>

    <!-- Archived View -->
    <template v-else>
      <div :style="`height: ${candidatesOffsetHeight}px`" />
      <div
        class="flex w-full flex-col items-center justify-center"
        :style="`height: calc(100vh - ${candidatesOffsetHeight}px)`"
      >
        <img :src="ArchivedJobImage" alt="Archived Job" class="mb-[29px] block w-[450px]" />
        <p class="text-shade-800">Make this job active again to see matches</p>
      </div>
    </template>
  </div>

  <div v-show="isLoading" class="flex min-h-screen items-center justify-center">
    <SpinnerLoader class="h-8 w-8" />
  </div>
</template>

<style scoped>
.filter-chips-wrapper {
  /** We need to match default border color from the template */
  @apply flex w-full items-center border-t px-8 py-3;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity));
}
</style>
