import type { MessageSuggestion } from './types/message-suggestion.interface';
import { useMessageSuggestionStore } from './message-suggestion.store';
import { MessageSuggestionPersistence } from './message-suggestion.persistence';

export default class MessageSuggestionService {
  private readonly messageSuggestionStore = useMessageSuggestionStore();
  private readonly messageSuggestionPersistence = new MessageSuggestionPersistence();

  public get suggestedMessages(): Array<MessageSuggestion> {
    return this.messageSuggestionStore.messages;
  }

  public set suggestedMessages(messages: Array<MessageSuggestion>) {
    this.messageSuggestionStore.setMessages(messages);
  }

  public clearMessages(): void {
    this.messageSuggestionStore.clearMessageList();
  }

  public get shouldDisplayMessageSuggestionsCarrouselImmediately(): boolean {
    return this.messageSuggestionStore.shouldDisplayMessageSuggestionsCarrouselImmediately;
  }

  public set shouldDisplayMessageSuggestionsCarrouselImmediately(value: boolean) {
    this.messageSuggestionStore.shouldDisplayMessageSuggestionsCarrouselImmediately = value;
  }

  public async fetchSuggestedMessages(
    employerId: number,
    proId: number,
  ): Promise<Array<MessageSuggestion>> {
    const messages = await this.messageSuggestionPersistence.fetchMessageaSuggestions(
      employerId,
      proId,
    );

    this.messageSuggestionStore.setMessages(messages);

    return messages;
  }
}
