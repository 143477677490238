<script setup lang="ts">
import TemplateService from '@/core/conversations/template/template.service';
import TrashBigIcon from '@/assets/svg/conversations/TrashBigIcon.svg?component';
import PenIcon from '@/assets/svg/conversations/PenIcon.svg?component';

const templateService = new TemplateService();

const props = defineProps<{
  id: number;
  title: string;
  templateText: string;
  custom?: boolean;
}>();

const emits = defineEmits<{
  (e: 'insertTemplate', templateId: number): void;
  (e: 'sendTemplate', templateId: number): void;
  (e: 'deleteTemplate', templateId: number): void;
  (e: 'editTemplate', templateId: number): void;
}>();

const insertTemplate = () => {
  emits('insertTemplate', props.id);
};

const sendTemplate = () => {
  emits('sendTemplate', props.id);
};

const deleteTemplate = () => {
  emits('deleteTemplate', props.id);
};

const editTemplate = () => {
  emits('editTemplate', props.id);
};
</script>

<template>
  <div class="flex h-full flex-col space-y-2 rounded-lg border border-tint-80 p-4">
    <div class="flex justify-between">
      <h3 class="text-sm font-bold text-shade-900">{{ title }}</h3>
      <img
        v-if="!custom"
        src="@/assets/svg/ff-logo-white.svg"
        alt="FactoryFix"
        class="h-4 w-[18px]"
      />
    </div>
    <p
      class="text-sm text-black"
      v-html="templateService.mapTemplatePlaceholders(templateText, true)"
    ></p>
    <div class="flex flex-1 items-end">
      <div class="flex w-full items-center justify-between">
        <v-tooltip location="top" content-class="project-card-tooltip tooltip-bottom-arrow">
          <template #activator="{ props }">
            <PenIcon
              class="mr-2 h-6 w-6 cursor-pointer text-shade-900"
              v-if="custom"
              v-bind="props"
              @click="editTemplate"
            />
          </template>
          <span class="text-xs text-white">Edit</span>
        </v-tooltip>
        <v-tooltip location="top" content-class="project-card-tooltip tooltip-bottom-arrow">
          <template #activator="{ props }">
            <TrashBigIcon
              class="h-6 w-6 cursor-pointer text-shade-900"
              v-if="custom"
              v-bind="props"
              @click="deleteTemplate"
            />
          </template>
          <span class="text-xs text-white">Delete</span>
        </v-tooltip>
        <div class="ml-auto flex gap-2 font-sans">
          <button
            class="cursor-pointer rounded-md border-2 border-shade-880 px-3 py-1.5 text-sm font-semibold text-shade-880 text-tint-0 transition-colors hover:bg-tint-40"
            @click="insertTemplate"
          >
            Insert
          </button>
          <button
            class="cursor-pointer rounded-md bg-shade-900 px-3 py-1.5 text-sm font-semibold text-tint-0 transition-colors hover:bg-shade-860"
            @click="sendTemplate"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
/*
 * Must not be scoped since the component is moved to the beginning of the v-app
 * https://vuetifyjs.com/en/api/v-tooltip/#props-content-class
 */
.project-card-tooltip {
  border-radius: 6px !important;
  padding: 3px 8px !important;
  background-color: #0f172a !important;
}
</style>
