<script setup lang="ts">
import ProPreviewCard from '@/components/Sourcing/ProPreviewCard/ProPreviewCard.vue';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { SearchService } from '@/core/sourcing/search/search.service';
import type { SearchGatedUserProfile } from '@/core/sourcing/search/types/search-gated-user-profile.type';
import SelectedCandidateService from '@/core/sourcing/selected-candidate/selected-candidate.service';
import { isProRecentlyActive } from '@/core/sourcing/utils/dates';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import ChevronLeftIcon from '@/assets/svg/chevron-left.svg?component';
import ChevronRightIcon from '@/assets/svg/chevron-right.svg?component';
import ProSearchResultsLoader from '@/components/Sourcing/Loader/ProSearchResultsLoader.vue';

defineProps<{
  isLoading: boolean;
}>();

const emit = defineEmits<{
  (e: 'change:page', value: number): void;
  (e: 'change:itemsPerPage', value: number): void;
}>();

const searchService = new SearchService();
const selectedCandidateService = new SelectedCandidateService();

const isTotalVisible = computed(() => (isSearchResultListNarrow.value ? 5 : undefined));

function handlePaginationUpdate(page: number) {
  const changedPage = page !== searchService.pagination.page;

  if (changedPage) {
    emit('change:page', page);
  }
}

function handleItemsPerPageUpdate(itemsPerPage: number) {
  const changedItemsPerPage = searchService.pagination.itemsPerPage !== itemsPerPage;

  if (changedItemsPerPage) {
    emit('change:itemsPerPage', itemsPerPage);
  }
}

async function getProPreviewPanelData(profileId: string) {
  const userProfileId = Number(profileId);

  try {
    selectedCandidateService.isFetchingCandidate = true;
    await selectedCandidateService.fetchCandidate(userProfileId);
    // await viewedCandidateService.markCandidateAsViewed(proPreferenceId);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to fetch candidate data');
  } finally {
    selectedCandidateService.isFetchingCandidate = false;
  }
}

function trackPPCClick(pro: SearchGatedUserProfile) {
  const lastActiveDate = pro?.lastActiveTs;
  TrackingService.trackAction(TrackingActionName.PPC_CLICK, {
    recently_active: (lastActiveDate && isProRecentlyActive(lastActiveDate)) || 'No',
    unlocked: pro?.unlocked,
  });
}

function handleProCardClick(pro: SearchGatedUserProfile) {
  getProPreviewPanelData(pro.profileId);
  trackPPCClick(pro);
}

const searchResultListEl = ref<HTMLElement | null>(null);
const isSearchResultListNarrow = ref(false);

const handleWindowSizeChange = () => {
  if (searchResultListEl.value) {
    isSearchResultListNarrow.value = searchResultListEl.value.offsetWidth < 450;
  }
};

onMounted(() => {
  searchResultListEl.value = document.getElementById('search-result-list');
  window.addEventListener('resize', handleWindowSizeChange);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleWindowSizeChange);
});
</script>

<template>
  <div id="search-result-list">
    <v-data-iterator
      :items="searchService.candidates"
      :options="searchService.pagination"
      :server-items-length="searchService.serverItems"
      :items-per-page="searchService.pagination.itemsPerPage"
    >
      <!-- No Data -->
      <template #no-data>
        <section v-if="isLoading" class="mt-10">
          <ProSearchResultsLoader v-for="i in 7" :key="i" class="mb-4" />
        </section>
        <section v-else class="mt-10 flex flex-col items-center text-sm text-shade-800">
          <img src="@/assets/svg/no-matches.svg" width="128" height="128" alt="No matches" />
          <p class="mt-4 font-bold">No matches... yet!</p>
          <p class="mt-4">Try changing your filter</p>
          <p>selections.</p>
        </section>
      </template>

      <!-- Items-->
      <template #default="{ items }">
        <ProPreviewCard
          v-for="(pro, index) in items"
          class="mb-4"
          :key="index"
          :pro="pro.raw as unknown as SearchGatedUserProfile"
          :selected-pro-id="selectedCandidateService.candidate?.id"
          @click="() => handleProCardClick(pro.raw)"
        />
      </template>
    </v-data-iterator>

    <div class="grid grid-cols-12" v-if="searchService.serverItems">
      <div class="col-span-12 lg:col-span-10">
        <v-pagination
          color="black"
          class="text-black"
          :prev-icon="ChevronLeftIcon"
          :next-icon="ChevronRightIcon"
          :model-value="searchService.pagination.page"
          :length="searchService.totalPages"
          @update:model-value="handlePaginationUpdate"
          :density="isSearchResultListNarrow ? 'compact' : 'default'"
          :total-visible="isTotalVisible"
        />
      </div>
      <div v-if="!isSearchResultListNarrow" class="col-span-12 lg:col-span-2">
        <v-select
          label="Items per page"
          variant="outlined"
          :model-value="searchService.pagination.itemsPerPage"
          :items="searchService.pagination.itemsPerPageOptions"
          @update:model-value="handleItemsPerPageUpdate"
        />
      </div>
    </div>
  </div>
</template>
