<script setup lang="ts">
import { computed, withDefaults } from 'vue';

import PlusIcon from '@/assets/svg/conversations/PlusIcon.svg?component';
import CloseIcon from '@/assets/svg/conversations/CloseIcon.svg?component';

const props = withDefaults(
  defineProps<{
    editMode?: boolean;
    text: string;
  }>(),
  {
    editMode: false,
    text: '',
  },
);

const emits = defineEmits<{
  (e: 'insertChip', chip: string): void;
  (e: 'removeChip', chip: string): void;
}>();

const chipText = computed(() => {
  if (props.editMode) {
    const removeBracketsRegex = /^\[|\]$/g;
    return props.text.replace(removeBracketsRegex, '');
  }
  return props.text;
});

const insertChip = () => {
  emits('insertChip', props.text);
};

const removeChip = () => {
  emits('removeChip', props.text);
};

const chipAction = () => {
  if (props.editMode) {
    removeChip();
  } else {
    insertChip();
  }
};
</script>

<template>
  <div
    class="flex h-[29px] cursor-pointer items-center gap-1 rounded-3xl border px-2 text-sm"
    :class="[
      props.editMode
        ? 'border-inform-200 bg-inform-50 text-inform-700'
        : 'border-tint-100 bg-tint-60 text-shade-820',
    ]"
    @click="chipAction"
  >
    <PlusIcon class="h-4 w-4 text-shade-820" v-if="!props.editMode" />
    <span>{{ chipText }}</span>
    <CloseIcon class="h-4 w-4 text-inform-700" v-if="props.editMode" />
  </div>
</template>
