import { defineStore } from 'pinia';
import type { GatedUserProfile } from './types/gated-user-profile.type';
import { ref } from 'vue';

export const useSelectedCandidateStore = defineStore('selected-candidate', () => {
  const candidate = ref<GatedUserProfile>();
  const isFetchingCandidate = ref(false);

  return {
    candidate,
    isFetchingCandidate,
  };
});
