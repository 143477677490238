<script setup lang="ts">
import atsSyncSvg from '@/assets/svg/ats-sync.svg';
import { computed } from 'vue';
import dayjs from 'dayjs';

const props = defineProps({
  mini: {
    type: Boolean,
    default: false,
  },
  lastSynced: {
    type: String,
    default: '',
  },
});

const isMiniVersion = computed(() => props.mini);
const lastSyncedTs = computed(() => {
  let date = '';
  if (props.lastSynced.trim()) {
    date = dayjs(props.lastSynced).format('MMM DD [at] hh:mmA');
  }

  return date;
});
const hasLastSynced = computed(() => lastSyncedTs.value !== null);
</script>

<template>
  <div
    :class="`flex items-center justify-center rounded bg-primary-300 p-1 text-[10px] font-bold leading-[10px] text-primary-1000`"
  >
    <span v-show="!isMiniVersion" v-bind="props" class="font-sans"> ATS SYNC </span>
    <v-tooltip
      v-show="isMiniVersion"
      :disabled="!hasLastSynced"
      location="top"
      content-class="bg-shade-880 tooltip-bottom-arrow"
    >
      <template v-slot:activator="{ props }">
        <img
          v-show="isMiniVersion"
          v-bind="props"
          :src="atsSyncSvg"
          class="inline-block"
          :class="{
            'ml-1': !isMiniVersion,
          }"
          height="16"
          width="16"
        />
      </template>

      <!-- Tooltip text -->
      <span>Last Synced: {{ lastSyncedTs }}</span>
    </v-tooltip>
  </div>
</template>
