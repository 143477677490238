import type { SearchGatedUserProfile } from '../types/search-gated-user-profile.type';

export function getNameForCandidate(candidate?: Partial<SearchGatedUserProfile>): string {
  const unknownString = 'Unknown';
  if (candidate) {
    if (!candidate.nameFirst) {
      // Don't have first name
      if (!candidate.nameLast) {
        if (!candidate.id) {
          return unknownString;
        } else {
          return `${unknownString} (${candidate.id})`;
        }
      } else {
        return candidate.nameLast;
      }
    } else {
      // Have a first name
      // eslint-disable-next-line no-lonely-if
      if (!candidate.nameLast) {
        return candidate.nameFirst;
      } else {
        // Has everything, add a space!
        return `${candidate.nameFirst} ${candidate.nameLast}`;
      }
    }
  } else {
    return unknownString;
  }
}
