<script setup lang="ts">
import { ref } from 'vue';
import ProNotesService from '@/core/shared/pro-notes/pro-notes.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { ErrorService } from '@/core/shared/errors/error.service';

const props = defineProps({
  proId: {
    type: Number,
  },
});

const proNoteService = new ProNotesService();

const note = ref<string>('');

const createNote = async () => {
  if (note.value && props.proId) {
    try {
      await proNoteService.createProNote(props.proId, note.value);
      await proNoteService.fetchProNotes(props.proId);
      note.value = '';
    } catch (error) {
      ErrorService.captureException(error);
      SnackbarService.critical('Failed to create note');
    }
  }
};
</script>

<template>
  <form class="relative w-full" @submit.prevent="createNote">
    <textarea
      id="create-note"
      type="text"
      name="create-note"
      class="h-10 w-full resize-none rounded-md border border-tint-80 py-2 pl-4 pr-20 font-sans text-sm text-shade-900 transition-colors placeholder:text-shade-800 focus:border-highlight-500 focus:outline-none"
      placeholder="Create a new note..."
      v-model="note"
      @keydown.enter.stop
    ></textarea>
    <button
      class="absolute right-0 top-0 mr-1 mt-1 flex h-8 w-16 items-center justify-center rounded bg-shade-900 font-sans text-xs font-semibold text-tint-0"
      type="submit"
    >
      Save
    </button>
  </form>
</template>
