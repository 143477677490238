<template>
  <div class="px-2">
    <p class="text-sm font-bold leading-5">{{ candidateFullName }}</p>

    <p class="mt-1 truncate text-xs">
      <span v-if="phoneNumber">
        {{ phoneNumber }}
        <span class="px-2 text-tint-100">|</span>
      </span>

      <a :href="'mailto:' + email">{{ email }}</a>
    </p>

    <Btn v-if="isViewResumeVisible" accent size="small" class="mt-1" @click="handleViewResume">
      View resume
    </Btn>
  </div>
</template>

<script setup lang="ts">
import Btn from '@/components/Sourcing/Btn/Btn.vue';
import type { GatedUserProfile } from '@/core/sourcing/selected-candidate/types/gated-user-profile.type';
import { computed } from 'vue';
import { getNameFor } from '@/core/sourcing/utils/userProfile';
import { formatPhoneNumber } from '@/utils/strings/phone-number.util';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';

const props = defineProps<{
  candidate?: GatedUserProfile;
  isUnlocked?: boolean;
}>();

const emit = defineEmits<{
  (e: 'click:view-resume'): void;
}>();

function handleViewResume() {
  emit('click:view-resume');
  TrackingService.trackAction(TrackingActionName.RESUME_VIEWED_IN_SOURCING);
}

const candidateFullName = computed(() => {
  if (!props.candidate) {
    return '';
  }

  return getNameFor(props.candidate);
});

const phoneNumber = computed(() => {
  if (props.isUnlocked) {
    return formatPhoneNumber(props.candidate?.phone);
  }

  return props.candidate?.phone; // show masked phone number as is
});

const email = computed(() => props.candidate?.email);
const isViewResumeVisible = computed(() => props.isUnlocked && !!props.candidate?.pro?.resumeUrl);
</script>
