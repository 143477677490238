import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { MessageSuggestion } from '@/core/conversations/message-suggestion/types/message-suggestion.interface';

export const useMessageSuggestionStore = defineStore(
  'messageSuggestion',
  () => {
    const messages = ref<MessageSuggestion[]>([]);
    const shouldDisplayMessageSuggestionsCarrouselImmediately = ref(true);

    function setMessages(newMessages: MessageSuggestion[]) {
      messages.value = newMessages;
    }

    function clearMessageList() {
      messages.value = [];
      return;
    }

    return {
      messages,
      shouldDisplayMessageSuggestionsCarrouselImmediately,
      setMessages,
      clearMessageList,
    };
  },
  {
    persist: false,
  },
);
