<script lang="ts" setup>
withDefaults(
  defineProps<{
    modelValue: boolean;
    title?: string;
    subtitle?: string;
    showCloseButton?: boolean;
    showClearButton?: boolean;
    isLoading?: boolean;
    isSubmitDisabled?: boolean;
    cancelButtonText?: string;
    submitButtonText?: string;
  }>(),
  {
    showCloseButton: false,
    showClearButton: false,
    isLoading: false,
    isSubmitDisabled: false,
    cancelButtonText: 'Cancel',
    submitButtonText: 'Submit',
  },
);

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'submit'): void;
  (e: 'clear'): void;
}>();

function cancel() {
  emit('cancel');
}

function submit() {
  emit('submit');
}

function clear() {
  emit('clear');
}
</script>

<template>
  <v-dialog :model-value="modelValue" max-width="600px" height="550px" persistent>
    <div class="rounded-2xl bg-white p-8">
      <slot name="header">
        <!-- Close button -->
        <img
          v-if="showCloseButton"
          class="float-right mt-1 cursor-pointer"
          src="@/assets/svg/close-black.svg"
          alt="close"
          width="20"
          height="20"
          @click="cancel"
        />

        <!-- Title -->
        <div v-if="title" class="font-serif text-2xl font-black text-copilot">{{ title }}</div>

        <!-- Subtitle -->
        <div v-if="subtitle" class="text-base text-shade-800">{{ subtitle }}</div>
      </slot>

      <!-- Default Slot -->
      <slot />

      <slot name="actions">
        <div class="flex items-center justify-end">
          <!-- Clear button -->
          <v-btn
            v-if="showClearButton"
            :ripple="false"
            class="modal-button-text mr-4"
            variant="flat"
            @click="clear"
            >Clear</v-btn
          >

          <!-- Cancel button -->
          <v-btn :ripple="false" class="modal-button-secondary" variant="flat" @click="cancel">{{
            cancelButtonText
          }}</v-btn>

          <!-- Submit button -->
          <v-btn
            :ripple="false"
            :loading="isLoading"
            :disabled="isSubmitDisabled"
            class="modal-button-primary ml-4"
            variant="flat"
            @click="submit"
            >{{ submitButtonText }}</v-btn
          >
        </div>
      </slot>
    </div>
  </v-dialog>
</template>
