<script setup lang="ts">
import SearchableListChip from '@/components/Shared/Input/SearchableListChip/SearchableListChip.vue';
import JobTitleService from '@/core/shared/job-title/job-title.service';
import { computed } from 'vue';

defineProps<{
  modelValue?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value?: number | string): void;
}>();

const jobTitleService = new JobTitleService();

const items = computed(() =>
  jobTitleService.jobTitles.map((jobTitle) => ({
    title: jobTitle.title ?? '',
    id: jobTitle.id,
  })),
);
</script>

<template>
  <SearchableListChip
    label="Job Title"
    placeholder="Search for job title.."
    item-id-key="title"
    item-title-key="title"
    :model-value="modelValue"
    :items="items"
    @update:model-value="emit('update:modelValue', $event)"
    @click:delete="emit('update:modelValue', undefined)"
  />
</template>
