<script setup lang="ts">
import Btn from '@/components/Sourcing/Btn/Btn.vue';
import { SearchService } from '@/core/sourcing/search/search.service';
import { SelectedFilterType } from '@/core/sourcing/search/types/selected-filter-type.type';
import { computed } from 'vue';

const searchService = new SearchService();
const selectedFilters = computed(() => searchService.selectedFilters);

const hasLocationFilter = computed(() =>
  selectedFilters.value.some((filter) => filter.type === SelectedFilterType.LOCATION),
);

const hasJobTitleFilter = computed(() =>
  selectedFilters.value.some((filter) => filter.type === SelectedFilterType.JOB_TITLE),
);

const allFilters = computed<{ enabled: boolean; label: string; type: SelectedFilterType }[]>(() => [
  { enabled: true, label: 'Keyword', type: SelectedFilterType.KEYWORD },
  { enabled: true, label: 'Location', type: SelectedFilterType.LOCATION },
  { enabled: hasLocationFilter.value, label: 'Distance', type: SelectedFilterType.AROUND_RADIUS },
  { enabled: true, label: 'Job Title', type: SelectedFilterType.JOB_TITLE },
  {
    enabled: hasJobTitleFilter.value,
    label: 'Years of experience',
    type: SelectedFilterType.YEARS_OF_EXPERIENCE,
  },
  { enabled: true, label: 'Skills', type: SelectedFilterType.KNOWLEDGE_DISCIPLINE },
  { enabled: true, label: 'Machines & technologies', type: SelectedFilterType.TECHNOLOGY },
  { enabled: true, label: 'Brands', type: SelectedFilterType.BRAND },
  { enabled: true, label: 'Last active', type: SelectedFilterType.LAST_ACTIVE },
]);

const availableFilters = computed(() => {
  return allFilters.value.filter((filter) => {
    const isFilterSelected = selectedFilters.value.some(
      (selectedFilter) => selectedFilter.type === filter.type,
    );

    return !isFilterSelected;
  });
});

const noFiltersAvailable = computed(() => availableFilters.value.length === 0);

function handleAddFilter(type: SelectedFilterType) {
  switch (type) {
    case SelectedFilterType.AROUND_RADIUS:
    case SelectedFilterType.LAST_ACTIVE:
    case SelectedFilterType.OBJECT_ID:
      searchService.selectedFilters.push({
        type,
        value: 0,
      });
      break;

    case SelectedFilterType.YEARS_OF_EXPERIENCE:
      searchService.selectedFilters.push({
        type,
        value: [],
      });
      break;

    case SelectedFilterType.KEYWORD:
    case SelectedFilterType.LOCATION:
    case SelectedFilterType.BRAND:
    case SelectedFilterType.JOB_TITLE:
    case SelectedFilterType.KNOWLEDGE_DISCIPLINE:
    case SelectedFilterType.TECHNOLOGY:
      searchService.selectedFilters.push({
        type,
        value: '',
      });
      break;
    default:
      break;
  }
}
</script>

<template>
  <v-menu location="bottom" transition="slide-y-transition">
    <template #activator="{ props }">
      <Btn v-bind="props" prepend-icon="plus" accent size="small" :disabled="noFiltersAvailable">
        Add filter
      </Btn>
    </template>

    <div
      :class="[
        'border-1',
        'flex',
        'flex-col',
        'rounded-md',
        'bg-white',
        'w-[180px]',
        'max-h-[320px]',
        'overflow-y-auto',
        'shadow-menu',
      ]"
    >
      <div class="py-2">
        <div
          v-for="filter in availableFilters"
          :key="filter.type"
          :class="{
            'flex h-8 items-center px-3 hover:bg-tint-40': true,
            'cursor-pointer': filter.enabled,
          }"
          @click="filter.enabled ? handleAddFilter(filter.type) : undefined"
        >
          <span
            :class="{
              'text-xs leading-4': true,
              'text-shade-600': !filter.enabled,
            }"
            >{{ filter.label }}</span
          >
        </div>
      </div>
    </div>
  </v-menu>
</template>
