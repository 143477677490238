import { useAtsCandidateDiscoveryApi } from '@/composables/useApi';
import type { ProPreviewStats } from './types/pro-preview-stats.type';
import { useMeStore } from '@/core/shared/me/me.store';
import { useSearchStore } from '../search/search.store';

export class ProPreviewStatsPersistence {
  private meStore = useMeStore();
  private searchStore = useSearchStore();

  public async fetchPreviewStats(): Promise<Record<string, ProPreviewStats>> {
    const employer = this.meStore.employer;
    const candidates = this.searchStore.candidates;

    if (!candidates.length) {
      return {};
    }

    const params = {
      employerId: employer?.id,
      pros: candidates.map((candidate) => ({
        userProfileId: candidate.profileId,
        proId: candidate.id,
      })),
    };

    const { data, error } = await useAtsCandidateDiscoveryApi<Record<string, ProPreviewStats>>(
      'pro-preview-stats',
    )
      .post(params)
      .json();

    if (error.value || !data.value) {
      throw new Error(error.value);
    }

    return data.value;
  }
}
