<script setup lang="ts">
import { computed, ref } from 'vue';

import EyeIcon from '@/assets/svg/conversations/eye.svg?component';

const props = defineProps({
  text: String,
  collapseText: Boolean,
  truncateText: Boolean,
});

const showTooltip = ref(false);
const tooltipActive = ref(false);

const iconImg = computed(() => EyeIcon);

const openTooltip = () => {
  if (!props.collapseText) return;
  tooltipActive.value = true;
  showTooltip.value = true;
};

const closeTooltip = () => {
  tooltipActive.value = false;
  setTimeout(() => {
    if (!tooltipActive.value) showTooltip.value = false;
  }, 300);
};
</script>

<template>
  <div @mouseleave="closeTooltip" class="relative">
    <div
      @mouseenter="openTooltip"
      class="grid space-x-1 text-shade-800"
      style="grid-template-columns: auto minmax(auto, 1fr)"
    >
      <component
        :class="collapseText ? 'cursor-pointer' : ''"
        v-if="iconImg"
        :is="iconImg"
        class="h-4 w-4"
      />
      <div v-show="!collapseText" class="truncate text-xs font-normal italic text-shade-800">
        {{ text }}
      </div>
    </div>
    <transition name="fade">
      <div class="message-visible-tooltip" v-show="showTooltip" @mouseenter="openTooltip">
        <span class="max-w-32 whitespace-nowrap text-xs font-normal">
          {{ text }}
        </span>
      </div>
    </transition>
  </div>
</template>

<style lang="postcss" scoped>
.message-visible-tooltip {
  @apply absolute left-1/2 z-50 -translate-x-1/2;
  @apply mt-1.5 flex max-w-64 items-center space-x-2 rounded-md bg-shade-880 px-2 py-0.5 text-sm text-tint-0;
  &::before {
    content: '';
    @apply absolute -top-4 left-1/2 -translate-x-1/2 border-8 border-solid border-transparent border-b-shade-880;
  }
}
</style>
