<script setup lang="ts">
import { ref } from 'vue';
import ExpansionPanel from '@/components/Shared/ExpansionPanel/ExpansionPanel.vue';
import NotePanelInput from './NotePanelInput.vue';
import NotesList from './NotesList.vue';
import type { NoteWithJobHeader } from '@/core/shared/pro-notes/types/pro-note.type';
import ProNotesService from '@/core/shared/pro-notes/pro-notes.service';
import SelectedCandidateService from '@/core/sourcing/selected-candidate/selected-candidate.service';
import type { Vue } from '@sentry/vue/types/types';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';

defineProps<{
  notes: NoteWithJobHeader[];
}>();

const expanded = ref(true);
const noteText = ref('');
const creatingNote = ref(false);
const notePanelInputRef = ref<
  | (Vue & {
      clearInput: () => void;
    })
  | null
>(null);

const createNote = async () => {
  try {
    creatingNote.value = true;
    const selectedCandidateService = new SelectedCandidateService();
    const proId = selectedCandidateService.pro?.id;
    if (!proId) return;
    const proNotesService = new ProNotesService();
    await proNotesService.createProNote(proId, noteText.value);
    clearNoteInput();
    // this.$mixpanelAnalytics.leftNoteOnPro({ source: 'PPP' })
    await proNotesService.fetchProNotes(proId);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to create note. Please try again later.');
  } finally {
    creatingNote.value = false;
    TrackingService.trackAction(TrackingActionName.NOTE_ADDED, { source: 'Sourcing' });
  }
};
const clearNoteInput = () => {
  noteText.value = '';
  if (notePanelInputRef.value) {
    notePanelInputRef.value.clearInput();
  }
};
</script>
<template>
  <ExpansionPanel
    header-title="Notes"
    :header-items-count="notes.length"
    :expanded="expanded"
    :bg-color="false"
    @header:click="expanded = !expanded"
  >
    <template #content>
      <NotePanelInput ref="notePanelInputRef" v-model="noteText" :tagging-array="[]" />
      <button
        :class="[
          'my-3 h-8 rounded-md border px-3 text-sm font-semibold',
          'enabled:border-black enabled:text-black',
          'disabled:border-tint-80 disabled:bg-tint-80 disabled:text-tint-400',
        ]"
        :disabled="!noteText || creatingNote"
        @click.prevent="createNote"
      >
        Save note
      </button>
      <NotesList :notes="notes" />
    </template>
  </ExpansionPanel>
</template>
