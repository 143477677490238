import { useAtsConversationApi, useAtsConversationApiWithOptions } from '@/composables/useApi';
import type { CreatedMessage } from '@/core/conversations/message/conversation-message.type';
import type { Employer, Job, UserProfile } from '@factoryfixinc/ats-interfaces';
import type { SelectedGatedUserProfile } from './types/selected-gated-user-profile.type';
import { HttpStatus } from '@/core/shared/errors/enums/http-status.enum';
import {
  USER_OPTED_OUT_ERROR_MESSAGE,
  USER_OPTED_OUT_ERROR_MESSAGE_KEYWORD,
} from './shared/constants/message-constants';

export default class MessagingPersistence {
  public async createMessage(
    employerId: Employer['id'],
    userProfile: SelectedGatedUserProfile,
    jobId: Job['id'],
    message: string,
  ): Promise<CreatedMessage> {
    const url = `message/employer/${employerId}/pro/${userProfile.id}/job/${jobId}?source=talent_search`;
    const { data, error } = await useAtsConversationApiWithOptions({ parseError: true })(url)
      .post({
        message,
      })
      .json<CreatedMessage>();
    if (error.value) {
      const errorForUser = `${USER_OPTED_OUT_ERROR_MESSAGE_KEYWORD}${userProfile.fullName}`;

      let displayMessage = `Couldn't send message. Please try again. ${errorForUser}`;
      if (error.value.includes('User cannot receive messages')) {
        displayMessage = `${USER_OPTED_OUT_ERROR_MESSAGE} ${errorForUser}`;
      }
      throw new Error(displayMessage);
    }
    if (!data.value) throw new Error('Message not sent. Please try again.');
    return data.value;
  }

  public async getConversation(
    employerId: Employer['id'],
    proUserProfileId: UserProfile['id'],
    jobId: Job['id'],
  ): Promise<{ conversationId: number } | undefined> {
    const url = `conversation/employer/${employerId}/pro/${proUserProfileId}/job/${jobId}`;
    const { data, error, statusCode } = await useAtsConversationApi(url).get().json();

    if (statusCode.value === HttpStatus.NOT_FOUND) {
      return;
    }

    if (error.value) {
      throw new Error(error.value);
    }

    return data.value;
  }
}
