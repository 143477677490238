<script setup lang="ts">
import type { Project } from '@factoryfixinc/ats-interfaces';
import MeService from '@/core/shared/me/me.service';

import copilotBannerBackground from '@/assets/png/copilot-banner.png';
import copilotLightning from '@/assets/svg/sourcing/copilot_lightning.svg';
import ProjectPremiumTag from '@/components/Shared/Projects/ProjectPremiumTag.vue';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName, TrackingCopilotSource } from '@/core/shared/tracking/tracking-actions';
import router from '@/router';

const props = defineProps<{
  project: Project;
}>();

const emit = defineEmits(['hide']);
const meService = new MeService();

function hideBannerForLater() {
  if (Object.hasOwnProperty.call(window, 'localStorage')) {
    const data = {
      projectId: props.project.id,
      date: new Date(),
      userId: meService.userProfile?.id,
    };

    localStorage.setItem(`sourcing-copilot-${props.project.id}`, JSON.stringify(data));
  }

  emit('hide');
}

// job flow redirects
async function activateCopilot() {
  await TrackingService.trackAction(TrackingActionName.COPILOT_ENABLE_STARTED, {
    source: TrackingCopilotSource.PROMO_BANNER,
    project_id: props.project.id,
    job_id: props.project.id,
  });
  await router.push(`/jobs/${props.project.id}/copilot-activation`);
}
</script>

<template>
  <div
    class="flex h-[178px] flex-col justify-between rounded-xl bg-highlight-500 bg-cover px-2 py-3 lg:py-6 xl:h-[80px] xl:flex-row xl:py-2 xl:pr-8"
    :style="{
      backgroundImage: `url(${copilotBannerBackground})`,
    }"
  >
    <!-- Text -->
    <div class="flex max-w-[calc(100%-16px)] xl:max-w-[calc(100%-257px)]">
      <img :src="copilotLightning" alt="Copilot" />

      <div class="ml-4">
        <p class="flex items-center font-serif text-base font-black">
          Need to supercharge your hiring?
          <ProjectPremiumTag class="ml-3" />
        </p>
        <p class="max-w-lg font-sans text-[13px] font-normal 2xl:max-w-none">
          Use FactoryFix Copilot to help you source, score and screen your candidates.
          <a href="https://www.factoryfix.com/copilot" target="_blank" class="underline">
            Learn More
          </a>
        </p>
      </div>
    </div>

    <!-- Actions -->
    <div class="ml-auto flex !w-[177px] items-center justify-center">
      <button
        class="box-border h-8 rounded-md border-2 border-tint-0 px-2 py-[6px] text-sm font-semibold leading-4"
        @click="hideBannerForLater"
      >
        Try later
      </button>
      <button
        class="ml-2 box-border h-8 rounded-md bg-white px-2 py-[6px] text-sm font-semibold leading-4 text-highlight-500"
        @click="activateCopilot"
      >
        Try Copilot
      </button>
    </div>
  </div>
</template>
