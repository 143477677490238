<script setup lang="ts">
import { computed } from 'vue';
import type { Project, UserProfile } from '@factoryfixinc/ats-interfaces';

import MeService from '@/core/shared/me/me.service';

import { getUserName } from '@/core/shared/user-profile/utils/get-user-name.util';

import EditIcon from '@/assets/svg/edit.svg?component';
import Checkbox from '@/components/Shared/Input/Checkbox/Checkbox.vue';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import ProjectService from '@/core/shared/project/project.service';

const projectService = new ProjectService();
const meService = new MeService();

interface SelectedTeamIds {
  watcherIds: number[];
  assigneeId: number | null;
}

interface TeamMember extends UserProfile {
  userName: string;
  isSelected: boolean;
  isOwner: boolean;
}

const props = withDefaults(
  defineProps<{
    project: Project;
    displayActivator: boolean;
    modelValue: boolean;
    isEmptyAvatar: boolean;
  }>(),
  {
    isEmptyAvatar: false,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', payload: boolean): void;
}>();

const myUser = computed(() => meService.userProfile);

const isDisplayingActivator = computed(() => props.displayActivator);

const isOpen = computed<boolean>(() => {
  return props.modelValue;
});

// Teams
const selectedTeamIds = computed<SelectedTeamIds>(() => {
  const watcherIds = props.project.watchers?.map((watcher) => watcher.id) ?? [];
  const assigneeId = props.project.assignee?.id ?? null;

  return {
    watcherIds,
    assigneeId,
  };
});

const employerUsers = computed<TeamMember[]>((): TeamMember[] => {
  const teamMemberList =
    meService.employerUsers?.map((user) => {
      const isMe = user.id === myUser.value?.id;
      let userName = getUserName(user);
      let isSelected = false;
      let isOwner = false;

      if (isMe) {
        userName += ' (Me)';
      }

      if (selectedTeamIds.value.assigneeId === user.id) {
        isSelected = true;
        isOwner = true;
      } else if (selectedTeamIds.value.watcherIds.includes(user.id)) {
        isSelected = true;
      }

      return {
        ...user,
        userName,
        isSelected,
        isOwner,
      };
    }) ?? [];

  teamMemberList.sort((a, b) => {
    if (a.userName?.toLowerCase() < b.userName?.toLowerCase()) {
      return -1;
    }
    return 1;
  });

  return teamMemberList;
});

function handleUpdateModelValue(value: boolean) {
  emit('update:modelValue', value);
}

async function handleTeamMemberClick(employerUser: TeamMember) {
  // Can't remove an owner
  if (employerUser.isOwner) {
    return;
  }

  const hasOwner = employerUsers.value.some((user) => user.isOwner);
  const hasWatchers = employerUsers.value.some((user) => user.isSelected);

  try {
    // If there is no owner and there are no watchers, set the user as the owner
    if (!hasOwner && !hasWatchers) {
      await projectService.updateAssignee(props.project.id, employerUser.id, { ...employerUser });
    } else if (!hasOwner && hasWatchers) {
      // If there is no owner and there are watchers, set the first watcher as the owner
      const firstWatcher = employerUsers.value.find((user) => user.isSelected);
      if (firstWatcher) {
        await projectService.updateAssignee(props.project.id, firstWatcher.id, {
          ...firstWatcher,
        });
      }
      // Also add the user as a watcher
      await projectService.addWatcher(props.project.id, employerUser.id, {
        nameFirst: employerUser.nameFirst,
        nameLast: employerUser.nameLast,
        id: employerUser.id,
      });
    } else if (employerUser.isSelected) {
      await projectService.removeWatcher(props.project.id, employerUser.id);
    } else {
      // Add watcher and update selected project with new team members
      await projectService.addWatcher(props.project.id, employerUser.id, {
        nameFirst: employerUser.nameFirst,
        nameLast: employerUser.nameLast,
        id: employerUser.id,
      });
    }

    // Update selected project with new team members
    await projectService.getProjectById(props.project.id);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to update team members');
  }
}

async function handleOwnerChange(employerUser: TeamMember) {
  try {
    const currentOwner = employerUsers.value.find((user) => user.isOwner);

    if (currentOwner && !selectedTeamIds.value.watcherIds.includes(currentOwner.id)) {
      await projectService.addWatcher(props.project.id, currentOwner.id, {
        nameFirst: currentOwner.nameFirst,
        nameLast: currentOwner.nameLast,
        id: currentOwner.id,
      });
    }

    await projectService.updateAssignee(props.project.id, employerUser.id, { ...employerUser });

    // Update selected project with new team members
    await projectService.getProjectById(props.project.id);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to update owner');
  }
}
</script>

<template>
  <v-menu
    :model-value="isOpen"
    :close-on-content-click="false"
    location="bottom"
    max-width="350"
    min-width="256"
    max-height="350"
    @update:model-value="handleUpdateModelValue"
  >
    <template #activator="{ props }">
      <div
        v-bind="props"
        class="absolute left-1 top-1 flex cursor-pointer items-center justify-center rounded-md"
        :class="{
          'opacity-0': !isDisplayingActivator,
          'opacity-1': isDisplayingActivator,
        }"
      >
        <EditIcon
          class="inline-block cursor-pointer"
          :class="{ 'stroke-tint-100': isEmptyAvatar }"
          height="16"
          width="16"
        />
      </div>
    </template>

    <v-card class="w-[256px] !px-0 !py-2 md:w-[350px]">
      <div
        v-for="employerUser in employerUsers"
        :key="employerUser.id"
        class="flex h-10 cursor-pointer items-center justify-start px-3 py-1 hover:bg-tint-20"
        :class="{
          'bg-highlight-50': employerUser.isSelected,
        }"
        @click="handleTeamMemberClick(employerUser)"
      >
        <div class="line-clamp-1 !w-[calc(100%-64px)]">
          <Checkbox :model-value="employerUser.isSelected" class="mb-[-2px] mr-1 inline-block" />
          <span>{{ employerUser.userName }}</span>
        </div>
        <div class="w-[64px] text-right text-[10px] font-bold">
          <span v-if="employerUser.isOwner">Owner</span>
          <p
            v-else-if="employerUser.isSelected"
            class="text-highlight-600"
            @click.stop="handleOwnerChange(employerUser)"
          >
            Set as owner
          </p>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>
