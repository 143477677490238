import { useAtsCandidateDiscoveryApi } from '@/composables/useApi';
import type { ProUnlockSource } from './types/pro-unlock-source.type';

export default class ProUnlockPersistence {
  async unlockProUserProfiles(payload: {
    employerId: number;
    userProfileIds: number[];
    source: ProUnlockSource;
  }): Promise<void> {
    const { employerId, userProfileIds, source } = payload;

    const url = `/pro-unlock/employer/${employerId}`;
    const { error } = await useAtsCandidateDiscoveryApi(url).post({
      proUserProfileIdsToUnlock: userProfileIds,
      source,
    });
    if (error.value) throw new Error(error.value);
  }
}
