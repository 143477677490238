<script setup lang="ts">
import { type Project, ProjectStatus } from '@factoryfixinc/ats-interfaces';
import { computed } from 'vue';

import ProjectHeaderCopilotBanner from '@/components/Sourcing/ProjectHeader/ProjectHeaderCopilotBanner.vue';
import ProjectArchivedStatus from '@/components/Sourcing/Project/ProjectArchivedStatus/ProjectArchivedStatus.vue';
import ProjectCopilotStatus from '@/components/Sourcing/Project/ProjectCopilotStatus/ProjectCopilotStatus.vue';
import ProjectAtsSyncStatus from '@/components/Sourcing/Project/ProjectAtsSyncStatus/ProjectAtsSyncStatus.vue';

const props = defineProps<{
  project: Project;
  showCopilotBanner?: boolean;
}>();

const emit = defineEmits(['hideCopilotBanner']);

const title = computed(() => props.project.title);
const isShowingCopilotBanner = computed(() => props.showCopilotBanner);

const projectStatus = computed(() => props.project.status);
const isCopilotActivated = computed(() => props.project.copilot);
const isArchived = computed(() => projectStatus.value === ProjectStatus.ARCHIVED);
const isAtsSynced = computed(() => props.project.remoteJobId !== null);

function hideCopilotBanner() {
  emit('hideCopilotBanner');
}
</script>

<template>
  <div class="w-full">
    <!-- Copilot Banner -->
    <div v-if="isShowingCopilotBanner" class="mb-2 px-8 pt-4">
      <ProjectHeaderCopilotBanner :project="project" @hide="hideCopilotBanner" />
    </div>

    <div class="flex flex-wrap items-center justify-between px-8 py-4">
      <!-- Title / Actions -->
      <div class="order-2 flex items-center md:order-1">
        <span class="mr-2 text-shade-800">Search results for:</span>
        <span class="font-serif font-black">
          {{ title }}
        </span>

        <!-- Desktop Archived Status -->
        <ProjectArchivedStatus v-if="isArchived" light class="ml-4 hidden md:inline-block" />
        <!-- Desktop Copilot Status -->
        <ProjectCopilotStatus v-if="isCopilotActivated" class="ml-4 hidden md:inline-flex" />
        <!-- Desktop ATS Sync Status -->
        <ProjectAtsSyncStatus v-if="isAtsSynced" class="ml-1 hidden md:inline-flex" />
      </div>

      <div class="order-1 flex w-full items-center md:order-2 md:w-[unset]">
        <!-- Mobile Archived Status -->
        <ProjectArchivedStatus
          v-if="isArchived"
          light
          class="mr-4 inline-block h-[18px] md:hidden"
        />

        <!-- Mobile Copilot Status -->
        <ProjectCopilotStatus
          v-if="isCopilotActivated"
          class="mr-4 inline-flex h-[18px] md:hidden"
        />

        <!-- Mobile ATS Sync Status -->
        <ProjectAtsSyncStatus v-if="isAtsSynced" class="inline-flex h-[18px] md:hidden" />
      </div>
    </div>
  </div>
</template>
