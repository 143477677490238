<template>
  <div class="flex w-8 flex-col items-center align-middle" :class="verticalPositionClass">
    <div
      :class="{
        'text-uppercase h-8 w-8 rounded-full text-center text-xs font-bold uppercase leading-8': true,
        'bg-tint-40 text-shade-820': isProMessage,
        'bg-primary-50 text-primary-800': isEmployerMessage,
        'bg-tint-0': isBotMessage,
      }"
    >
      <template v-if="isBotMessage">
        <img src="@/assets/svg/conversations/factoryfix_message_avatar.svg" alt="FactoryFix" />
      </template>
      <template v-else-if="isStatusMessage">
        <img src="@/assets/svg/ff-logo.svg" alt="FactoryFix" />
      </template>
      <template v-else>
        <img
          v-if="isCurrentUserName"
          :src="currentUser?.avatarUrl"
          alt="User Avatar"
          class="h-8 w-8 rounded-full"
        />
        <span v-else>
          {{ getAvatarInitials() }}
        </span>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { UserProfile } from '@factoryfixinc/ats-interfaces';
import { getUserName } from '@/core/shared/user-profile/utils/get-user-name.util';

const props = withDefaults(
  defineProps<{
    isProMessage: boolean;
    isEmployerMessage: boolean;
    isBotMessage: boolean;
    isStatusMessage?: boolean;
    name?: string;
    currentUser?: Partial<UserProfile>;
    verticalPosition: 'end' | 'center';
  }>(),
  {
    isProMessage: false,
    isEmployerMessage: false,
    isBotMessage: false,
    isStatusMessage: false,
    verticalPosition: 'end',
  },
);

const isCurrentUserName = computed(
  () => `${getUserName(props.currentUser as UserProfile)}` === `${props.name}`,
);

const verticalPositionClass = computed(() => {
  return {
    'justify-end': props.verticalPosition === 'end',
    'justify-center': props.verticalPosition === 'center',
  };
});

function getAvatarInitials(): string {
  if (props.isBotMessage) {
    return 'FF'; // FactoryFix
  }

  const nameParts = props.name?.split(/\s/)?.filter(Boolean) ?? [];

  if (nameParts.length === 0) {
    return 'UK'; // unknown
  }

  if (nameParts.length === 1) {
    return nameParts[0].slice(0, 2).toUpperCase();
  }

  const [firstName, lastName] = nameParts;

  return `${firstName.at(0)}${lastName.at(0)}`.toUpperCase();
}
</script>
