<template>
  <ExpansionPanel
    header-title="Work Experience"
    :header-items-count="workExperience?.length"
    :expanded="expanded"
    @header:click="expanded = !expanded"
  >
    <template #content>
      <div v-if="!workExperience?.length" class="text-shade-600" data-test-id="no-experience">
        No Work Experience Added
      </div>
      <template v-else>
        <div
          v-for="(experience, i) in workExperience.slice(0, experienceToShow)"
          :key="i"
          data-test-id="experience"
          class="leading-[18px]"
          :class="{ 'mb-2.5': i !== workExperience.length - 1 }"
        >
          <div>
            {{ experience.jobTitle }}
          </div>
          <div class="text-shade-600">
            {{ experience.name }}
          </div>
          <div v-if="experience.startYYYYMM" class="text-shade-600">
            {{ _formatDate(experience) }}
          </div>
        </div>
        <div
          v-if="hasMultipleExperienceEntries"
          class="mt-2.5 cursor-pointer underline"
          data-test-id="show-more-button"
          @click="toggleExperienceToShow"
          @keypress="toggleExperienceToShow"
        >
          {{ showMoreExperience ? 'Show More' : 'Show Less' }}
        </div>
      </template>
    </template>
  </ExpansionPanel>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { computed, ref } from 'vue';
import ExpansionPanel from '@/components/Shared/ExpansionPanel/ExpansionPanel.vue';
import type { ProHistoryEmployer } from '@factoryfixinc/ats-interfaces';
const expanded = ref(true);
const props = defineProps<{
  workExperience?: ProHistoryEmployer[];
}>();
const DEFAULT_ITEMS_TWO = 2;
const experienceToShow = ref(DEFAULT_ITEMS_TWO);
function _formatDate(exp: ProHistoryEmployer): string {
  return (
    dayjs(exp.startYYYYMM + '-01').format('MMMM YYYY') +
    ' - ' +
    (exp.endYYYYMM ? dayjs(exp.endYYYYMM + '-01').format('MMMM YYYY') : 'Present')
  );
}
const hasMultipleExperienceEntries = computed(
  () => props.workExperience?.length && props.workExperience?.length > DEFAULT_ITEMS_TWO,
);
const showMoreExperience = computed(() => {
  if (!experienceToShow.value || !props.workExperience) return false;
  return props.workExperience.length > experienceToShow.value;
});

function toggleExperienceToShow(): void {
  if (experienceToShow.value === DEFAULT_ITEMS_TWO) {
    experienceToShow.value = props.workExperience?.length || DEFAULT_ITEMS_TWO;
    // this.$mixpanelAnalytics.pppSeeMoreClicked({
    //   source: 'Work Experience'
    // })
  } else {
    experienceToShow.value = DEFAULT_ITEMS_TWO;
  }
}
</script>

<style scoped lang="scss"></style>
