export default class ScrollUtils {
  public static isEndOfScroll(targetElement?: EventTarget | HTMLElement | null): boolean {
    if (targetElement === null) return false;

    const { scrollTop, scrollHeight, clientHeight } = targetElement as HTMLElement;

    return scrollTop + clientHeight >= scrollHeight;
  }

  public static isStartOfScroll(targetElement: EventTarget | HTMLElement | null): boolean {
    if (targetElement === null) return false;
    const { scrollTop } = targetElement as HTMLElement;
    return scrollTop === 0;
  }

  public static scrollToBottom(
    targetElement?: EventTarget | HTMLElement,
    behavior?: 'smooth' | 'auto' | 'instant',
  ): void {
    if (!targetElement) return;

    const element = targetElement as HTMLElement;
    element.scrollTo({ top: element.scrollHeight, behavior: behavior || 'auto' });
  }
}
