import { ProPreviewStatsPersistence } from './pro-preview-stats.persistence';
import { useProPreviewStatsStore } from './pro-preview-stats.store';
import type { ProPreviewStats } from './types/pro-preview-stats.type';

export class ProPreviewStatsService {
  private store = useProPreviewStatsStore();
  private persistence = new ProPreviewStatsPersistence();

  public get statsByProId(): Record<string, ProPreviewStats> {
    return this.store.statsByProId;
  }

  public get statsRequestStatus(): string {
    return this.store.statsRequestStatus;
  }

  public async fetchPreviewStats(): Promise<Record<string, ProPreviewStats>> {
    this.store.statsRequestStatus = 'loading';
    const proPreviewStats = await this.persistence.fetchPreviewStats();
    this.store.statsRequestStatus = 'loaded';
    this.store.statsByProId = proPreviewStats;

    return proPreviewStats;
  }
}
