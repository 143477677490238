import type { ProHistoryEmployer } from '@factoryfixinc/ats-interfaces';

export function getUserProfileSortedWorkExperiences(
  experiences?: ProHistoryEmployer[],
): ProHistoryEmployer[] {
  if (!Array.isArray(experiences)) {
    return [];
  }

  const unsortedExperiences = experiences.map((experience) => {
    const newExp = JSON.parse(JSON.stringify(experience));
    const sortDate = newExp.endYYYYMM ? newExp.endYYYYMM : `9${newExp.startYYYYMM}`; // no end date displays as 'to present'; sort these high, then by start date (example: 92012 > 92010 > 2012)
    return {
      ...newExp,
      sortVal: sortDate ? parseInt(sortDate.replace('-', '')) : 0,
    };
  });

  // Array.prototype.sort sorts in place
  unsortedExperiences.sort((a, b) => b.sortVal - a.sortVal);

  return unsortedExperiences.map((exp) => {
    delete exp.sortVal;
    return exp;
  });
}
